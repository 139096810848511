import { Button, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Risks from "../../../service/RiskAssessment";

const Index = ({ setedit }) => {
  const [riskAssessment, setriskAssessment] = useState([]);
  const { clientId } = useParams();

  const scoreload = async () => {
    const { data } = await Risks.getRiskAssessments({ clientId });
    setriskAssessment(data.map((e) => e.assessment));
    console.log(data);
  };

  useEffect(() => {
    scoreload();
  }, []);
  const [period, setperiod] = useState(new Date().toISOString().split("T")[0]);
  const [selectperiod, setselectperiod] = useState("");
  const handlePeriod = (e) => {
    if (e === "monthly") {
      setperiod(
        new Date(new Date().setDate(new Date().getDate() + 32))
          .toISOString()
          .split("T")[0]
      );
    } else if (e === "yearly") {
      setperiod(
        new Date(new Date().setFullYear(new Date().getFullYear() + 1))
          .toISOString()
          .split("T")[0]
      );
    } else if (e === "weekly") {
      setperiod(
        new Date(Date.now() + 7 * 24 * 3600000).toISOString().split("T")[0]
      );
    }
    setselectperiod(e);
  };

  const [editRiskAssessment, seteditRiskAssessment] = useState({
    isEdit: false,
    id: "",
  });
  const RiskAssessmentColumn = [
    {
      title: "Assessment Date",
      dataIndex: "assessmentDate",
      key: "assessmentDate",
    },

    {
      title: "Client",
      dataIndex: "client",
      render: (data) => (
        <Button type="primary">
          <Link to={`/app/clients/${data}`}>View</Link>
        </Button>
      ),
    },

    {
      title: "Expected Transaction Value",
      dataIndex: "expectedTransactionValue",
      key: "expectedTransactionValue",
    },

    {
      title: "Expected Transaction Volumne",
      dataIndex: "expectedTransactionVolume",
      key: "expectedTransactionVolume",
    },

    {
      title: "Period",
      dataIndex: "period",
      key: "period",
    },

    {
      title: "Periodic Review Date",
      dataIndex: "periodicReviewDate",
      key: "periodicReviewDate",
      render: (a) => (
        <div className="whitespace-nowrap">
          {moment.utc(a).format("YYYY-MM-DD")}
        </div>
      ),
    },

    {
      title: "Sanctions",
      dataIndex: "sanctions",
      key: "sanctions",
      render: (data) => <>{data ? "Yes" : "No"}</>,
    },
    {
      title: "Actions",

      key: "actions",
      render: (data) => (
        <Button
          type="primary"
          onClick={() => setedit({ isEdit: true, assessment: data })}
        >
          Edit
        </Button>
      ),
    },
  ];
  return (
    <>
      <h3 className="my-10">Risk Assessments </h3>
      <Table columns={RiskAssessmentColumn} dataSource={riskAssessment} />
    </>
  );
};

export default Index;
