import Cookies from "js-cookie";
import React, { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { GrLogout } from "react-icons/gr";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Plan, Profile, Setting } from "../../assets/svgs";
import { logout } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
const DropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    store.dispatch(logout());
    navigate("/");
  };
  const user = useSelector(selectUser);
  return (
    <div className="relative">
      <button
        onClick={handleToggleDropdown}
        className="profile-link w-inline-block noselect flex items-center justify-center w-10 h-10 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
      >
        <AiOutlineUser size={25} />
      </button>
      {isOpen && (
        <>
          <div
            className="fixed inset-0  z-40"
            onClick={() => setIsOpen(false)}
          ></div>

          <div className="absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
            <Link
              to="/app/profile"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none w-full flex items-center justify-between"
            >
              <div>Profile</div>
              <Profile />
            </Link>
            <Link
              to="/app/settings"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none w-full flex items-center justify-between"
            >
              <div>Settings</div>
              <Setting />
            </Link>
            {user?.role !== "team_member" && (
              <Link
                to="/app/plan"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none w-full flex items-center justify-between"
              >
                <div>Plan</div>
                <Plan />
              </Link>
            )}
            <button
              onClick={handleLogout}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none w-full flex items-center justify-between"
            >
              <div>Logout</div>
              <GrLogout />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default DropdownMenu;
