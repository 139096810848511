import {
  Alert,
  Button,
  InputNumber,
  Popconfirm,
  Table,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import RiskAssessment from "../../../service/RiskAssessment";
import User from "../../../service/User";
import ExportButton from "../RiskAssessment/ExportButton";
import { WeighingtableData } from "./data";
const WeightingTable = () => {
  const [data, setData] = useState({});
  const [me, setMe] = useState(null);
  useEffect(() => {
    User.me().then((data) => setMe(data.data));
  }, []);

  const [shouldsave, setshouldsave] = useState(false);
  const loadWeighingScore = async () => {
    const { data } = await RiskAssessment.getWeightingTable();
    delete data._id;
    delete data.__v;
    setData(data);
  };

  useEffect(() => {
    loadWeighingScore();
  }, []);
  const Save = async () => {
    const total = Object.values(data)
      .filter((a) => typeof a === "number")
      .reduce((a, b) => a + b);

    if (Math.ceil(total * 100) === 100) {
      await RiskAssessment.changeWeightingTable(data);
      message.success("Weighting Table Updated");
    } else {
      message.error("Total sum of weights must be equal to 100");
    }
  };
  const handleWeightChange = (value, key) => {
    setData((prevData) => ({ ...prevData, [key]: value }));
    setshouldsave(true);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Weight (%)",
      dataIndex: "key",
      key: "key",
      render: (text, record) => (
        <InputNumber
          min={0}
          max={100}
          step={1}
          value={(data[text] * 100).toFixed(0)}
          onChange={(value) => handleWeightChange(value / 100, text)}
        />
      ),
    },
    {
      title: "Comments",
      dataIndex: "comment",
      key: "comment",
      render: (text, record) => (
        <Typography.Paragraph
          editable={{
            onChange: (e) => {
              handleWeightChange(e, `${text}Data`);
            },
          }}
        >
          {data[`${text}Data`] ?? ""}
        </Typography.Paragraph>
      ),
    },
  ];

  return (
    <>
      <ExportButton />

      <h3>Weighting Table</h3>
      <Alert
        type="info"
        message="Set the scores for your organization below."
      />

      {me && me?.role !== "team_member" && (
        <div className="mt-2">
          <Popconfirm
            title="All scores will be reset to system default. Are you sure?"
            onConfirm={async () => {
              await RiskAssessment.resetWeightingTable();
              await loadWeighingScore();
            }}
          >
            <Button type="primary">
              Click here to reset to system default
            </Button>
          </Popconfirm>
        </div>
      )}

      <Table
        columns={columns}
        dataSource={Object.keys(data)
          .filter((k) => !k.includes("Data"))
          .map((e) => {
            return {
              category: WeighingtableData[e],
              key: e,
              comment: e,
            };
          })}
        pagination={false}
        footer={() =>
          shouldsave && (
            <Button type="primary" onClick={Save}>
              Save
            </Button>
          )
        }
      />
    </>
  );
};

export default WeightingTable;
