import {
  Alert,
  Button,
  FloatButton,
  Input,
  Popconfirm,
  Select,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { AiFillPlusCircle, AiOutlineSave } from "react-icons/ai";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { selectLoading } from "../../../redux/auth/selectors";
import RiskAssessment from "../../../service/RiskAssessment";
import User from "../../../service/User";
import ExportButton from "../RiskAssessment/ExportButton";
import { countries } from "./countries";
import { handleXLSXTOJSON } from "../../../utils/bulkUpload";
import SampleBulkAddClients from "../../../assets/Samples/SampleBulkUploadCountryRiskScore.xlsx";
const { Option } = Select;

const scoreOptions = [1, 3, 5, 10];

export const getScoreText = (score) => {
  if (score < 2) {
    return <p className="text-green-700 font-bold">Low</p>;
  } else if (score < 3) {
    return <p className="text-yellow-500 font-bold">Medium</p>;
  } else if (score < 4) {
    return <p className="text-red-800 font-bold">High</p>;
  } else {
    return <p className="font-bold">Refer to MLRO</p>;
  }
};

export const getScoreTextSelected = (score) => {
  if (score < 3) {
    return <p className="text-green-700 font-bold">Low</p>;
  } else if (score < 5) {
    return <p className="text-yellow-500 font-bold">Medium</p>;
  } else if (score < 10) {
    return <p className="text-red-800 font-bold">High</p>;
  } else {
    return <p className="font-bold">Risk Tolerance</p>;
  }
};

const App = () => {
  const [scores, setScores] = useState([]);
  const [shouldsave, setshouldsave] = useState(false);
  const loading = useSelector(selectLoading);

  const [me, setMe] = useState(null);
  useEffect(() => {
    User.me().then((data) => setMe(data.data));
  }, []);

  const scoreload = async () => {
    const { data } = await RiskAssessment.getCountryRiskScores();

    delete data?._id;
    delete data?.__v;
    setScores(data);
  };

  const handleScoreChange = (key, value, setTableData) => {
    setTableData((prev) => {
      return { ...prev, [key]: { ...prev[key], score: value } };
    });
    setshouldsave(true);
  };

  const handleScoreAdd = () => {
    setScores((prev) => {
      return { ...prev, [uuid()]: { title: "", comment: "", score: 1 } };
    });
    setshouldsave(true);
  };

  const handleDelete = (key) => {
    setScores((prev) => {
      delete prev?.[key];
      return { ...prev };
    });
    setshouldsave(true);
  };

  const handleTitleChange = (key, value) => {
    setScores((prev) => {
      return {
        ...prev,
        [key]: {
          title: value,
          comment: prev[key]?.comment ?? "",
          score: prev[key]?.score ?? 1,
        },
      };
    });
    setshouldsave(true);
  };

  const handleFieldChange = (key, value, setTableData) => {
    setTableData((prev) => {
      return { ...prev, [key]: { ...prev[key], comment: value } };
    });
    setshouldsave(true);
  };

  async function Save() {
    if (shouldsave) {
      const { data } = await RiskAssessment.changeCountryRiskScores(scores);
      if (data) {
        message.success("Country Risk Scores Changed Successfully");
      }
    }
  }

  const handleBulkUpload = (e) => {
    const file = e.target.files[0];
    handleXLSXTOJSON({ sheet: file }, async (json) => {
      json.shift(); // Remove header row if necessary
      const newScores = {};
      json.forEach((row) => {
        const id = uuid();
        newScores[id] = {
          title: row.Country,
          score: row.inherentRiskScore,
          comment: row.comment,
        };
      });
      setScores((prevScores) => ({ ...prevScores, ...newScores }));
      setshouldsave(true);
    });
  };

  const columns = (setFunction) => [
    {
      title: "Country",
      dataIndex: "key",
      key: "key",
      render: (e) => (
        <Typography.Paragraph
          editable={{
            onChange: (value) => {
              handleTitleChange(e, value);
            },
          }}
        >
          {scores?.[e]?.title ?? countries?.[e] ?? e}
        </Typography.Paragraph>
      ),
      sorter: (a, b) => a.key.localeCompare(b.key),
    },
    {
      title: "Inherent Risk Scores",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,

      render: (_, record) => (
        <Select
          value={record.score}
          onChange={(value) =>
            handleScoreChange(record.key, value, setFunction)
          }
        >
          {scoreOptions.map((score) => (
            <Option key={score} value={score}>
              {score}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Risk Rating",
      dataIndex: "score",
      key: "score",
      render: (_, record) => getScoreTextSelected(record.score),
    },
    {
      title: "Override Applied",
      dataIndex: "comment",
      key: "score",
      render: (_, record) => (
        <Typography.Paragraph
          editable={{
            onChange: (e) => {
              handleFieldChange(record.key, e, setFunction);
            },
          }}
        >
          {record.comment}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <Button danger onClick={() => handleDelete(record.key)}>
          Delete
        </Button>
      ),
    },
  ];

  useEffect(() => {
    scoreload();
  }, []);

  return (
    <div>
      <div>
        <ExportButton />
        <h3>Country Risk Score</h3>
        <Alert
          type="info"
          message="Set the scores for your organization below."
        />

        {me && me?.role !== "team_member" && (
          <div className="mt-2">
            <Popconfirm
              title="All scores will be reset to system default. Are you sure?"
              onConfirm={async () => {
                await RiskAssessment.resetCountryRiskScores();
                await scoreload();
              }}
            >
              <Button type="primary">
                Click here to reset to system default
              </Button>
            </Popconfirm>
          </div>
        )}

        <div className="my-10">
          <Table
            loading={loading}
            columns={columns(setScores)}
            dataSource={Object.entries(scores).map((e, i) => ({
              ...e[1],
              key: e[0],
            }))}
            pagination={false}
            bordered
            footer={() => (
              <Space>
                <Button
                  type="primary"
                  onClick={handleScoreAdd}
                  icon={
                    <AiFillPlusCircle
                      style={{ position: "relative", top: 3 }}
                    />
                  }
                >
                  Add New Country
                </Button>
                <Button type="primary">
                  <label htmlFor="bulk-upload">Bulk Add</label>
                  <Input
                    className="hidden"
                    type="file"
                    id="bulk-upload"
                    onChange={handleBulkUpload}
                  />
                </Button>

                <a className="mx-3" href={SampleBulkAddClients} download={true}>
                  Sample Excel Sheet
                </a>

                {shouldsave && (
                  <FloatButton
                    shape="square"
                    type="primary"
                    style={{ right: 24 }}
                    icon={
                      <AiOutlineSave style={{ position: "relative", top: 3 }} />
                    }
                    description="Save"
                    onClick={Save}
                  />
                )}
              </Space>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
