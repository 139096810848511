import { Button, Skeleton, Space, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineFieldTime, AiOutlineFileExcel } from "react-icons/ai";
import {
  BsCheck2All,
  BsFillPatchExclamationFill,
  BsPersonFill,
} from "react-icons/bs";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/auth/selectors";
import Client from "../../../service/Client";
import Statistics from "../Statistics";

import moment from "moment";
import { Link } from "react-router-dom";
import User from "../../../service/User";
import { getScoreText } from "../CountryRiskScores";

const getGreeting = () => {
  const currentTime = parseInt(new Date().getHours());

  if (currentTime >= 0 && currentTime < 5) return "Good night";
  if (currentTime >= 5 && currentTime < 11) return "Good morning";
  if (currentTime >= 11 && currentTime < 18) return "Good afternoon";
  if (currentTime >= 18 && currentTime < 21) return "Good evening";
  if (currentTime >= 21 && currentTime < 24) return "Good night";
};

const DashboardHome = () => {
  const user = useSelector(selectUser);
  const [clients, setclients] = useState(null);
  const [assessment, setassessments] = useState(null);
  const [peridicReviewsDue, setPeridicReviewsDue] = useState(null);
  const [peridicReviewsDueCount, setPeridicReviewsDueCount] = useState(null);

  const getDashboardDetails = async () => {
    const data = await User.dashboardDetails();

    setassessments(data?.data?.numberOfRiskAssements);
    setclients(data?.data?.numberClients);
    setPeridicReviewsDueCount(data?.data?.peridicReviewsDueCount);
    setPeridicReviewsDue(data?.data?.peridicReviewsDue);
  };

  useEffect(() => {
    getDashboardDetails();
  }, []);
  if (clients === null && assessment === null) {
    return <Skeleton active />;
  }

  const riskAssessmentColumn = [
    {
      title: "Client Name",
      dataIndex: "client",
      render: (a, record) => (
        <div className="whitespace-nowrap">{record.client?.name ?? ""}</div>
      ),
    },
    {
      title: "Due Date",
      dataIndex: "periodicReviewDate",
      render: (a) => (
        <div className="whitespace-nowrap">
          {moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    {
      title: "Score",
      dataIndex: "riskRating",
      render: (_, record) =>
        record?.riskAssessmentResults?.[
          record?.riskAssessmentResults?.length - 1
        ]?.result?.finalOverallScore ?? 0,
    },
    {
      title: "Risk Rating",
      dataIndex: "rating",
      render: (_, record) =>
        getScoreText(
          record?.riskAssessmentResults?.[
            record?.riskAssessmentResults?.length - 1
          ]?.result?.finalOverallScore ?? 0
        ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, __) => (
        <Space>
          <Link to={`/app/clients/related-parties/${__?.client?._id}`}>
            <Button size="small">Client</Button>
          </Link>
          <Link to={`/app/periodicreview/${__?._id}/${__?.client?._id}`}>
            <Button size="small" type="primary">
              Review
            </Button>
          </Link>
          {__?.pendingApproval && (
            <Tooltip title="MLRO approval required">
              <BsFillPatchExclamationFill color="red" />{" "}
            </Tooltip>
          )}
          {__?.isApproved && (
            <Tooltip title="MLRO Approved">
              <BsCheck2All color="green" />{" "}
            </Tooltip>
          )}
          {__?.isDisapproved && (
            <Tooltip title="MLRO Declined">
              <BsCheck2All color="red" />{" "}
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="page-title-group">
        <h4>
          👋🏽 {getGreeting()} {user?.name ?? ""}!
        </h4>
        <p className="text-color-grey mb-4">Welcome back!</p>

        <div className="">
          <Statistics
            statistics={[
              {
                id: 1,
                title: "Clients", // (status weiß)
                value: clients,
                icon: <BsPersonFill />,
              },
              {
                id: 2,
                title: "Risk Assessments", // (status weiß)
                value: assessment,
                icon: <AiOutlineFileExcel />,
              },
              {
                id: 3,
                title: "Periodic Reviews Due", // (status weiß)
                value: peridicReviewsDueCount,
                icon: <AiOutlineFieldTime />,
              },
            ]}
            hideFilter
          />
        </div>

        <h4 className="!my-5">Periodic Reviews Due</h4>
        <Table
          className="w-full max-w-2xl"
          dataSource={peridicReviewsDue}
          rowKey="_id"
          columns={riskAssessmentColumn}
          pagination={true}
        />
      </div>
    </>
  );
};

export default DashboardHome;
