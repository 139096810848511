import { useEffect, useRef } from "react";
import { Network } from "vis-network";
import { Empty } from "antd";

export const NetworkGraph = ({ nodes, edges }) => {
  const graphRef = useRef(null);

  useEffect(() => {
    if (!graphRef?.current || !nodes?.length || !edges?.length) return;
    new Network(
      graphRef.current,
      { nodes, edges },
      {
        nodes: {
          font: {
            size: 16,
          },
        },
        edges: {
          font: {
            size: 16,
          },
          smooth: {
            enabled: false,
            type: "continuous",
          },
        },
        physics: {
          adaptiveTimestep: true,
          barnesHut: {
            gravitationalConstant: (nodes?.length || 50) * -100,
            springConstant: 0.04,
            springLength: 300,
            damping: 0.5,
          },
          stabilization: {
            iterations: 1000,
            fit: false,
          },
        },
        layout: {
          improvedLayout: false,
        },
      }
    );
  }, [graphRef, nodes, edges]);

  if (!nodes?.length || !edges?.length)
    return <Empty description={"Graph Not Available"} />;

  return <div className="relative w-full h-[700px]" ref={graphRef}></div>;
};
