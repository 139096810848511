import { Button, Dropdown, Skeleton, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";
import RiskAssessment from "../../../service/RiskAssessment";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { exportToExcel } from "../Clients/clientByUser";
import { getScoreText } from "../CountryRiskScores";

const RelatedReport = () => {
  const [Reports, setReports] = useState(null);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    const loadClients = async () => {
      const response = await RiskAssessment.getRelatedReports({});
      setReports(
        response.data["riskReports"].map((t) => ({ ...t, key: t?._id }))
      );
    };
    loadClients();
  }, []);

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
    },
    {
      title: "Related Party Name",
      dataIndex: "relatedPartyName",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Country of Birth/Incorporation",
      dataIndex: "country",
    },
    {
      title: "Relation Type",
      dataIndex: "clientRole",
    },
    {
      title: "% Shareholding",
      dataIndex: "Shareholding",
    },
  ];
  if (!Reports) {
    return <Skeleton active />;
  }
  return (
    <>
      <Table
        className="w-full max-w-2xl"
        dataSource={Reports}
        columns={columns}
        loading={loading}
        pagination={true}
        footer={() => (
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: "export-excel",
                  label: (
                    <Button
                      onClick={() =>
                        exportToExcel(
                          JSON.parse(JSON.stringify(Reports)).map((e) => {
                            delete e?.clientId;
                            delete e?.clientGroupId;
                            delete e?.complianceNotes;
                            delete e?.relatedPartyId;
                            return e;
                          })
                        )
                      }
                    >
                      Excel
                    </Button>
                  ),
                },
                {
                  key: "export-pdf",
                  label: (
                    <Button
                      onClick={() =>
                        handleGeneratePDF({
                          header: "Reports",
                          ContentColumn: columns.filter(
                            (c) => c.dataIndex !== "rating"
                          ),
                          DataSource: Reports,
                        })
                      }
                    >
                      PDF
                    </Button>
                  ),
                },
              ],
            }}
            arrow={{
              pointAtCenter: true,
            }}
          >
            Export
          </Dropdown.Button>
        )}
      />
    </>
  );
};

export default RelatedReport;
