import { Line } from "@ant-design/plots";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const data = [
  {
    name: "Reports Generated",
    year: "Jan",
    gdp: 1,
  },
  {
    name: "Reports Generated",
    year: "Feb",
    gdp: 4,
  },
  {
    name: "Reports Generated",
    year: "Mar",
    gdp: 8,
  },
  {
    name: "Reports Generated",
    year: "Apr",
    gdp: 4,
  },
  {
    name: "Reports Generated",
    year: "May",
    gdp: 8,
  },
  {
    name: "Reports Generated",
    year: "Jun",
    gdp: 3,
  },
  {
    name: "Reports Generated",
    year: "Jul",
    gdp: 2,
  },
  {
    name: "Reports Generated",
    year: "Aug",
    gdp: 5,
  },
  {
    name: "Reports Generated",
    year: "Sep",
    gdp: 7,
  },
  {
    name: "Reports Generated",
    year: "Oct",
    gdp: 10,
  },
  {
    name: "Reports Generated",
    year: "Nov",
    gdp: 4,
  },
  {
    name: "Reports Generated",
    year: "Dec",
    gdp: 2,
  },

  {
    name: "Expenses",
    year: "Jan",
    gdp: 4,
  },
  {
    name: "Expenses",
    year: "Feb",
    gdp: 8,
  },
  {
    name: "Expenses",
    year: "Mar",
    gdp: 4,
  },
  {
    name: "Expenses",
    year: "Apr",
    gdp: 8,
  },
  {
    name: "Expenses",
    year: "May",
    gdp: 3,
  },
  {
    name: "Expenses",
    year: "Jun",
    gdp: 2,
  },
  {
    name: "Expenses",
    year: "Jul",
    gdp: 5,
  },
  {
    name: "Expenses",
    year: "Aug",
    gdp: 7,
  },
  {
    name: "Expenses",
    year: "Sep",
    gdp: 10,
  },
  {
    name: "Expenses",
    year: "Oct",
    gdp: 4,
  },
  {
    name: "Expenses",
    year: "Nov",
    gdp: 2,
  },
  {
    name: "Expenses",
    year: "Dec",
    gdp: 1,
  },

  {
    name: "Profits",
    year: "Jan",
    gdp: 2,
  },
  {
    name: "Profits",
    year: "Feb",
    gdp: 5,
  },
  {
    name: "Profits",
    year: "Mar",
    gdp: 1,
  },
  {
    name: "Profits",
    year: "Apr",
    gdp: 1,
  },
  {
    name: "Profits",
    year: "May",
    gdp: 2,
  },
  {
    name: "Profits",
    year: "Jun",
    gdp: 1,
  },
  {
    name: "Profits",
    year: "Jul",
    gdp: 3,
  },
  {
    name: "Profits",
    year: "Aug",
    gdp: 4,
  },
  {
    name: "Profits",
    year: "Sep",
    gdp: 1,
  },
  {
    name: "Profits",
    year: "Oct",
    gdp: 1,
  },
  {
    name: "Profits",
    year: "Nov",
    gdp: 2,
  },
  {
    name: "Profits",
    year: "Dec",
    gdp: 2,
  },

  {
    name: "Active Users",
    year: "Jan",
    gdp: 1,
  },
  {
    name: "Active Users",
    year: "Feb",
    gdp: 1,
  },
  {
    name: "Active Users",
    year: "Mar",
    gdp: 0,
  },
  {
    name: "Active Users",
    year: "Apr",
    gdp: 0,
  },
  {
    name: "Active Users",
    year: "May",
    gdp: 1,
  },
  {
    name: "Active Users",
    year: "Jun",
    gdp: 0,
  },
  {
    name: "Active Users",
    year: "Jul",
    gdp: 1,
  },
  {
    name: "Active Users",
    year: "Aug",
    gdp: 0,
  },
  {
    name: "Active Users",
    year: "Sep",
    gdp: 0,
  },
  {
    name: "Active Users",
    year: "Oct",
    gdp: 0,
  },
  {
    name: "Active Users",
    year: "Nov",
    gdp: 1,
  },
  {
    name: "Active Users",
    year: "Dec",
    gdp: 1,
  },
];
const MultiLineChart = () => {
  const config = {
    data,
    xField: "year",
    yField: "gdp",
    seriesField: "name",
    yAxis: {
      label: {
        formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
      },
    },
    legend: {
      position: "top",
    },
    smooth: true,
    // @TODO 后续会换一种动画方式
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };
  return <Line {...config} width={10} />;
};

export default MultiLineChart;
