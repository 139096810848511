import {
  CheckOutlined,
  DeleteOutlined,
  HighlightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Modal,
  Popconfirm,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { AiOutlineDownload } from "react-icons/ai";
import { setLoading } from "../../../redux/auth/actions";
import { store } from "../../../redux/store";
import Admin from "../../../service/Admin";
import Public from "../../../service/Public";
import { uploadToCloudinary } from "../ContentManagement/LandingPage";
import FileUploadUpcomming from "./FileUploadUpcomming";

const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const handleOpenUploadModal = () => {
    setUploadModalVisible(true);
  };
  const handleCloseUploadModal = () => {
    setUploadModalVisible(false);
  };

  const getData = useCallback(async () => {
    const res = await Public.getUsefulFiles();
    setFiles(res.data);
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  const handleUpload = useCallback(
    async (file) => {
      store.dispatch(setLoading(true));
      try {
        const cloudinaryResponse = await uploadToCloudinary(file);

        const newFile = {
          title: file.name,
          src: cloudinaryResponse.secure_url,
        };
        await Admin.addFile(newFile);
        await getData();

        message.success("File uploaded");
        handleCloseUploadModal();
      } catch (e) {
      } finally {
        store.dispatch(setLoading(false));
      }
    },
    [getData]
  );

  const handleDeleteFile = async (fileId) => {
    await Admin.deleteFile(fileId);
    await getData();

    message.success("File deleted");
  };

  const handleChangeTitle = async (text, id) => {
    await Admin.updateFile(id, { title: text });
    await getData();
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;

    if (source.index === destination.index) return;

    const newFiles = Array.from(files);
    const [movedFile] = newFiles.splice(source.index, 1);
    newFiles.splice(destination.index, 0, movedFile);

    await Promise.all(
      newFiles.map(async (file, index) => {
        await Admin.updateFile(file._id, { sequence: index });
      })
    );

    await getData();
  };

  return (
    <>
      <FileUploadUpcomming />
      <Divider />
      <h3>General Information</h3>
      <Modal
        title="Upload File"
        open={uploadModalVisible}
        onCancel={handleCloseUploadModal}
        footer={null}
      >
        <Upload.Dragger
          name="file"
          accept="*"
          beforeUpload={(file) => {
            handleUpload(file);
            return false;
          }}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <PlusOutlined />
          </p>
          <p className="ant-upload-text">Click or Drag and Drop</p>
        </Upload.Dragger>
      </Modal>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tutorial-list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2"
            >
              {files
                .sort((a, b) => a.sequence - b.sequence)
                .map((file, i) => (
                  <Draggable key={file._id} draggableId={file._id} index={i}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="border p-4"
                      >
                        <div key={i} className="border p-4">
                          <div className="mt-2 flex gap-3 items-center">
                            <Typography.Paragraph
                              editable={{
                                icon: <HighlightOutlined />,
                                tooltip: "click to edit text",
                                onChange: (e) => handleChangeTitle(e, file._id),
                                enterIcon: <CheckOutlined />,
                              }}
                            >
                              {file.title}
                            </Typography.Paragraph>
                            <div
                              className="cursor-pointer mb-3"
                              onClick={async () => {
                                const response = await fetch(file.src);
                                const blob = await response.blob();

                                const link = document.createElement("a");
                                link.href = URL.createObjectURL(blob);
                                link.download = file.title;

                                link.click();
                              }}
                            >
                              <AiOutlineDownload />
                            </div>
                          </div>
                          <div className="mt-2 flex justify-between items-center">
                            <Popconfirm
                              title="Are you sure to delete?"
                              onConfirm={() => handleDeleteFile(file._id)}
                            >
                              <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        type="primary"
        className="btn-primary mt-4"
        onClick={handleOpenUploadModal}
      >
        New File
      </Button>
    </>
  );
};

export default FileUpload;
