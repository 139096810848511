import {
  Button,
  Descriptions,
  Input,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import SampeBulkAddClients from "../../../assets/Samples/SampeBulkAddClients.xlsx";
import { selectLoading } from "../../../redux/auth/selectors";
import {
  default as Client,
  default as ClientService,
} from "../../../service/Client";
import { handleXLSXTOJSON } from "../../../utils/bulkUpload";
import { getScoreText } from "../CountryRiskScores";

export const exportToExcel = (data) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  XLSX.writeFile(workbook, "data.xlsx");
};
const EditGroupPage = () => {
  const canEdit = (() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken);
    return decoded.hasFullClientAccess;
  })();

  const [clientData, setClientData] = useState({});
  const [displayArchive, setDisplayArchive] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const fields = [
    { name: "name", placeholder: "Name" },
    { name: "isBusiness", placeholder: "Entity or Individual" },
    { name: "transactionType", placeholder: "Transaction Type" },
    { name: "businessType", placeholder: "Transaction or Business Activity" },
    { name: "businessWebsite", placeholder: "Business Website" },
    {
      name: "placeOfBirth",
      placeholder: "Place of Birth / Incorporation",
    },
    { name: "status", placeholder: "Status" },
    {
      name: "sourceOfFunds",
      placeholder: "Source of Funds",
    },
    {
      name: "sourceOfWealth",
      placeholder: "Source of Wealth",
    },
    {
      name: "industryOccupation",
      placeholder: "Industry / Occupation",
    },
    {
      name: "expectedTransactionVolume",
      placeholder: "Expected Transaction Volume",
    },
    {
      name: "expectedTransactionValue",
      placeholder: "Expected Transaction Value",
    },
    { name: "isHighNetWorth", placeholder: "High Net Worth Client" },
    { name: "isRegulated", placeholder: "Regulated Client" },
    { name: "regulatorName", placeholder: "Regulator Name" },
    { name: "regulatorType", placeholder: "Regulation Type" },
    { name: "eligibleIntroducer", placeholder: "Eligible Introducer" },
    { name: "introducerName", placeholder: "Introducer Name" },
    { name: "ongoingScreening", placeholder: "Ongoing Screening" },
  ];
  const [Clients, setClients] = useState([]);
  const loading = useSelector(selectLoading);

  const loadClients = useCallback(async () => {
    const response = await Client.getClientsByUser(id);
    setClients(
      response.data
        .filter((t) => t.group === id)
        .map((t) => ({ ...t, key: t?._id }))
    );
  }, [id]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const handleDelete = async (data, archived) => {
    await Client.deleteClient({
      clientId: data._id,
    }).then(() => loadClients());
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Score",
      dataIndex: "riskRating",
      render: (_, record) => record?.riskRating,
      sorter: (a, b) => a.riskRating - b.riskRating,
    },
    {
      title: "Risk Rating",
      dataIndex: "rating",
      render: (_, record) => getScoreText(record?.riskRating ?? 0),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Link to={`/app/clients/related-parties/${text._id}`}>
            <Button type="primary">Details</Button>
          </Link>
          {record.finalAssessmentId && (
            <Link to={`/app/periodicreview/${record.finalAssessmentId}/x`}>
              <Button type="primary">Periodic Review</Button>
            </Link>
          )}

          {canEdit && (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleDelete(text, !record.archived)}
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const [AddData, setAddData] = useState({
    group: id,
    name: "",
    isBusiness: false,
    businessType: "",
    businessWebsite: "",
    placeOfBirth: "",
    status: "",
    isHighNetWorth: false,
    isRegulated: false,
    ongoingScreening: false,
    transactionType: false,
    regulatorName: "",
    regulatorType: "",
    eligibleIntroducer: false,
    introducerName: "",
    sourceOfFunds: "",
    sourceOfWealth: "",
    industryOccupation: "",
    relatedParties: [],
  });

  const EditClientGroup = async (data) => {
    await Client.editClientGroup({
      clientGroupId: id,
      ...data,
    });
  };
  const Add = async () => {
    await Client.createClient({ ...AddData });
    setOpenAdd(false);

    loadClients();
    setAddData({
      group: id,
      name: "",
      isBusiness: false,
      businessType: "",
      businessWebsite: "",
      placeOfBirth: "",
      status: "",
      isHighNetWorth: false,
      isRegulated: false,
      ongoingScreening: false,
      transactionType: false,
      regulatorName: "",
      regulatorType: "",
      eligibleIntroducer: false,
      introducerName: "",
      sourceOfFunds: "",
      sourceOfWealth: "",
      industryOccupation: "",
      relatedParties: [],
    });
  };

  const handleChangeAdd = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddData((prev) => ({ ...prev, [name]: value }));
  };
  const [openAdd, setOpenAdd] = useState(false);
  useEffect(() => {
    if (id) {
      const getSetClient = async () => {
        const { data } = await ClientService.getClientGroups();
        setClientData(data.find((e) => e._id === id));
      };
      getSetClient();
    }
  }, [id]);

  return (
    <div>
      <Button
        className="mb-4"
        type="primary"
        onClick={() => navigate(`/app/clients`)}
      >
        <div className="flex items-center gap-2">
          <AiOutlineDoubleLeft />
          <div>Back to Client Groups</div>
        </div>
      </Button>

      <h3 className="capitalize">{clientData.name}</h3>
      {canEdit && (
        <Descriptions
          layout="horizontal"
          colon={false}
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          className="mt-10"
        >
          <Descriptions.Item
            label="Name"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={{
                onChange: (e) => {
                  setClientData((prev) => ({ ...prev, name: e }));
                  if (e === clientData.name) return;
                  EditClientGroup({ ...clientData, name: e });
                },
              }}
            >
              {clientData.name}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item
            label="Group Description"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={{
                onChange: (e) => {
                  setClientData((prev) => ({ ...prev, businessTagLine: e }));
                  if (e === clientData.businessTagLine) return;
                  EditClientGroup({ ...clientData, businessTagLine: e });
                },
              }}
            >
              {clientData.businessTagLine}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      )}

      <h4 className="!mt-20 capitalize">Clients</h4>
      <div className="flex flex-col w-full">
        <Table
          className="w-full max-w-2xl"
          dataSource={Clients.filter((c) => displayArchive || !c.archived)}
          columns={columns}
          loading={loading}
          pagination={{
            pageSizeOptions: [10, 20, 50, 100],
            showSizeChanger: true,
          }}
          footer={() =>
            canEdit ? (
              <div className="flex gap-3 items-center justify-between">
                <Button type="primary" onClick={() => setOpenAdd(true)}>
                  Add Client
                </Button>
              </div>
            ) : null
          }
        />

        {canEdit && (
          <div>
            <Button type="primary">
              <label htmlFor="bulk-upload">Bulk Add</label>
              <Input
                className="hidden"
                type="file"
                id="bulk-upload"
                onChange={(e) =>
                  handleXLSXTOJSON(
                    { sheet: e.target.files[0] },
                    async (json) => {
                      json.shift();

                      await Client.createClientBulk({
                        clients: json,
                        groupId: id,
                      });
                      loadClients();
                      setOpenAdd(false);
                    }
                  )
                }
              />
            </Button>
            <a className="mx-3" href={SampeBulkAddClients} download={true}>
              Sample Excel Sheet
            </a>
          </div>
        )}

        <Modal
          open={openAdd}
          onCancel={() => setOpenAdd(false)}
          title="Add Client"
          destroyOnClose
          onOk={Add}
          loading={loading}
          footer={[
            <Button onClick={() => setOpenAdd(false)} danger>
              Cancel
            </Button>,
            <Button type="primary" onClick={Add} loading={loading}>
              Add Client
            </Button>,
          ]}
        >
          {fields.map((e, i) => (
            <div className="mb-4" key={i}>
              <label>{e.placeholder}</label>
              {typeof AddData[e.name] === "boolean" ? (
                <Switch
                  onChange={(x) =>
                    handleChangeAdd({ target: { value: x, name: e.name } })
                  }
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  checkedChildren={
                    e.name === "isBusiness"
                      ? "Entity"
                      : e.name === "transactionType"
                      ? "Ongoing"
                      : undefined
                  }
                  unCheckedChildren={
                    e.name === "isBusiness"
                      ? "Individual"
                      : e.name === "transactionType"
                      ? "One-off"
                      : undefined
                  }
                />
              ) : (
                <Input
                  value={AddData[e.name] || ""}
                  onPressEnter={Add}
                  onChange={handleChangeAdd}
                  name={`${e.name}`}
                  placeholder={e.placeholder}
                  required
                />
              )}
            </div>
          ))}
        </Modal>
      </div>
    </div>
  );
};

export default EditGroupPage;
