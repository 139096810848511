import { message } from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { logout, setLoading } from "../redux/auth/actions";
import { store } from "../redux/store";
import Auth from "./Auth";

export const middleField = (api) => {
  api.interceptors.response.use(
    (response) => {
      store.dispatch(setLoading(false));
      if (response?.data?.message) message.success(response.data.message);
      return response;
    },
    (error) => {
      store.dispatch(setLoading(false));
      const { response } = error;
      if (response) {
        const { data } = response;
        if (data && data.message) {
          if (
            data.message === "Invalid access_token" ||
            data.message?.toLowerCase?.() === "jwt must be provided" ||
            data.message?.toLowerCase() === "jwt malformed"
          ) {
            store.dispatch(logout());
            window.location.href = "/";
            return;
          }

          message.error(data.message);
        }
        if (data && data.detail?.[0]?.msg) {
          message.error(data.detail?.[0]?.msg);
        }
      }
      return Promise.reject(error);
    }
  );

  api.interceptors.request.use(
    async (config) => {
      store.dispatch(setLoading(true));

      let access_token = Cookies.get("accessToken");
      let refresh_token = Cookies.get("refreshToken");

      if (access_token) {
        const decodedToken = jwt_decode(access_token);
        if (5000 + decodedToken.exp * 1000 <= new Date()) {
          const result = await Auth.refresh({
            accessToken: access_token,
            refreshToken: refresh_token,
          });
          const response = await result.json();

          if (!response?.accessToken) {
            store.dispatch(logout());
            window.location.href = "/";
            return;
          }

          Cookies.set("accessToken", response?.accessToken);
          Cookies.set("refreshToken", response?.refreshToken);

          access_token = response?.accessToken;
          refresh_token = response?.refreshToken;
        }
      }

      return {
        ...config,
        headers: {
          authorization: `Bearer ${access_token}`,
        },
      };
    },
    (error) => {
      store.dispatch(setLoading(false));
      return Promise.reject(error);
    }
  );
};
