import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Typography, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Admin from "../../../service/Admin";
import Public from "../../../service/Public";
import { uploadToCloudinary } from "./LandingPage";

const HeroImage = () => {
  const [GALink, setGALink] = useState(null);
  const [heroImage, setHeroImage] = useState(null);
  const [heroImage2, setHeroImage2] = useState(null);
  const [heroImage3, setHeroImage3] = useState(null);
  const [heroImage4, setHeroImage4] = useState(null);
  const [heroImage5, setHeroImage5] = useState(null);

  const loadData = async () => {
    const data = await Public.getHeroImage("heroImage");
    if (data.data.heroImage) setHeroImage(data.data.heroImage);
  };
  const loadData2 = async () => {
    const data = await Public.getHeroImage("heroImage2");
    if (data.data.heroImage2) setHeroImage2(data.data.heroImage2);
  };
  const loadData3 = async () => {
    const data = await Public.getHeroImage("heroImage3");
    if (data.data.heroImage3) setHeroImage3(data.data.heroImage3);
  };
  const loadData4 = async () => {
    const data = await Public.getHeroImage("heroImage4");
    if (data.data.heroImage4) setHeroImage4(data.data.heroImage4);
  };
  const loadData5 = async () => {
    const data = await Public.getHeroImage("heroImage5");
    if (data.data.heroImage5) setHeroImage5(data.data.heroImage5);
  };
  const loadData6 = async () => {
    const data = await Public.getHeroImage("GALink");
    if (data.data.GALink) setGALink(data.data.GALink);
  };

  useEffect(() => {
    loadData();
    loadData2();
    loadData3();
    loadData4();
    loadData5();
    loadData6();
  }, []);

  const handleUpload = useCallback(
    async (file, key) => {
      const cloudinaryResponse = await uploadToCloudinary(file);
      await Admin.setHeroImage(
        {
          [key]: cloudinaryResponse.secure_url,
        },
        key
      );
      loadData();
      loadData2();
      loadData3();
      loadData4();
      loadData5();
    },
    [loadData]
  );

  return (
    <>
      <h4>Google Analytics Track ID</h4>
      <Typography.Paragraph
        editable={{
          onChange: async (e) => {
            await Admin.setHeroImage(
              {
                GALink: e,
              },
              "GALink"
            );
            loadData6();
          },
        }}
      >
        {GALink}
      </Typography.Paragraph>

      <h4>Hero Image</h4>
      <Upload.Dragger
        name="tutorial"
        accept="jpeg|png"
        beforeUpload={(file) => {
          handleUpload(file, "heroImage");
          return false; // Prevent automatic upload
        }}
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <PlusOutlined />
        </p>
        {heroImage && <img src={heroImage} style={{ height: 100 }} />}
        <p className="ant-upload-text">Click or Drag and Drop</p>
        <p className="ant-upload-hint">Supported: .jpeg or .png</p>
      </Upload.Dragger>

      <Divider />
      <h4>Hero Image 2</h4>
      <Upload.Dragger
        name="tutorial"
        accept="jpeg|png"
        beforeUpload={(file) => {
          handleUpload(file, "heroImage2");
          return false; // Prevent automatic upload
        }}
        showUploadList={false}
      >
        <p className="ant-upload-drag-icon">
          <PlusOutlined />
        </p>
        {heroImage2 && <img src={heroImage2} style={{ height: 100 }} />}
        <p className="ant-upload-text">Click or Drag and Drop</p>
        <p className="ant-upload-hint">Supported: .jpeg or .png</p>
      </Upload.Dragger>
    </>
  );
};

export default HeroImage;
