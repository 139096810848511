import {
  Button,
  Form,
  Input,
  List,
  Popconfirm,
  Space,
  Typography,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import Admin from "../../../service/Admin";
import Public from "../../../service/Public";

const EmailTemplate = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const loadData = useCallback(async () => {
    const data = await Public.getEmails();
    const emails = data.data.emails ?? { subscriptionReminder: "x" };

    setData(emails);
    form.setFieldsValue(emails);
  }, [form]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onFinish = useCallback(
    async (values) => {
      Admin.setEmails({ emails: values });
    },
    [form]
  );

  return (
    <div>
      <h4>Email Templates</h4>
      <div className="p-3 backdrop-blur-xl rounded-lg">
        <Form form={form} onFinish={onFinish}>
          <Form.Item
            label="Subscription Reminder"
            name="subscriptionReminder"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input.TextArea rows={10} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default EmailTemplate;
