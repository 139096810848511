import React, { useCallback, useEffect, useState } from "react";
import Public from "../../service/Public";

const Copyright = () => {
  const [copyright, setCopyright] = useState(localStorage.copyright ?? null);

  const loadData = useCallback(async () => {
    const data = await Public.getCopyright();
    if (data.data.copyright) {
      setCopyright(data.data.copyright);
      localStorage.setItem("copyright", data.data.copyright);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <h1>Copyright</h1>
      <div dangerouslySetInnerHTML={{ __html: copyright }} />
    </>
  );
};

export default Copyright;
