import {
  Alert,
  Button,
  Input,
  Skeleton,
  Space,
  Switch,
  Table,
  Tabs,
  message,
} from "antd";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import {
  default as RiskAssessment,
  default as Risks,
} from "../../../service/RiskAssessment";
import PeriodicReview from "../periodicreview/index";
import ResultCollection from "../results/index";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { average } from ".";
import { selectLoading } from "../../../redux/auth/selectors";
import { getScoreText } from "../CountryRiskScores";
import { KYCLevelAppliedOptions } from "../results/Result";
import {
  EntityProductServiceOffered,
  clientCharacteristics,
  deliveryChannelFactors,
  jurisdictionTouchpointsFactors,
  sourceOfFundsFactors,
} from "./data";

const Index = () => {
  const canEdit = (() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken);
    return decoded.hasFullClientAccess;
  })();

  const loading = useSelector(selectLoading);

  const [riskAssessment, setRiskAssessment] = useState(null);
  const [scores, setScores] = useState(null);
  const [manualOverride, setmanualoveride] = useState(0);
  const [manualOverrideRationale, setmanualOverrideRationale] = useState("");
  const [complianceNotes, setcompliancenotes] = useState("");
  const [clients, setclients] = useState("");
  const [PEP, setPEP] = useState(false);
  const [KYCLevelApplied, setKYCLevelApplied] = useState("standard");
  const [adverseMedia, setadverseMedia] = useState(false);
  const [SAR, setSAR] = useState(false);
  const [declined, setdeclined] = useState(false);
  const [sanctions, setsanctions] = useState(false);
  const [incompleteKYC, setincompleteKYC] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const [scoresjurisdiction, setScoresJurisdiction] = useState(null);
  const [factors, setFactors] = useState(clientCharacteristics);
  const [DeliveryChannel, setDeliveryChannel] = useState(
    deliveryChannelFactors
  );
  const [JurisdictionTouchPoints, setJurisdictionTouchPoints] = useState(
    jurisdictionTouchpointsFactors
  );
  const [SourceOfFunds, setSourceOfFunds] = useState(sourceOfFundsFactors);
  const { id, activeTabKey } = useParams();
  const [factorsEntityProduct, setFactorsEntityProduct] = useState(
    EntityProductServiceOffered
  );
  const [period, setperiod] = useState(new Date().toISOString().split("T")[0]);
  const [onboarding, setOnboarding] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectperiod, setselectperiod] = useState("monthly");

  const componentRef = useRef();
  const handleExportRiskAssessment = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp",
  });

  const scoreload = useCallback(async () => {
    try {
      const res = await Risks.getSingleRiskAssessments({ id });
      setRiskAssessment(res.data);
      const { data: countryscores } = await Risks.getCountryRiskScores();
      console.log(res);
      const { data } = await Risks.getRiskFactorScores();
      setPEP(res.data?.PEP);
      setKYCLevelApplied(res.data?.KYCLevelApplied);
      setsanctions(res.data?.sanctions);
      setincompleteKYC(res.data?.incompleteKYC);
      setSAR(res.data?.SAR);
      setclients(res.data.client);
      setadverseMedia(res.data.adverseMedia);
      setmanualoveride(parseInt(res.data.manualOverride));
      setmanualOverrideRationale(res.data.manualOverrideRationale);
      setcompliancenotes(res.data.complianceNotes);
      setdeclined(res.data.declined);
      setselectperiod(res.data.period);
      setFinalScore(
        res.data?.riskAssessmentResults?.[
          res.data?.riskAssessmentResults?.length - 1
        ]?.result?.finalOverallScore ?? 0
      );
      setAssessmentDate(res.data.assessmentDate.split("T")[0]);
      setperiod(res.data.periodicReviewDate.split("T")[0]);
      setOnboarding(res.data?.onboardingDate?.split?.("T")?.[0]);
      setFactors((prev) =>
        prev.map((e) => {
          const key = e.key;
          const factor =
            res.data.riskAssessmentForm.ClientCharacteristics[key]?.factor;
          const selection =
            res.data.riskAssessmentForm.ClientCharacteristics[key]?.selection;

          const score = Array.isArray(factor)
            ? Math.max(
                ...[
                  0,
                  ...factor.map((c) => data.ClientCharacteristics[c]?.score),
                ]
              )
            : data.ClientCharacteristics[factor]?.score;

          return {
            ...e,
            factor: factor,
            selection: selection,
            show: selection === "Yes",
            initialScore: score || 0,
          };
        })
      );
      setDeliveryChannel((prev) =>
        prev.map((e) => {
          const key = e.key;
          const factor =
            res.data.riskAssessmentForm.DeliveryChannelsRisk[key]?.factor;
          const selection =
            res.data.riskAssessmentForm.DeliveryChannelsRisk[key]?.selection;
          const initialScore = data.DeliveryChannelsRisk[factor]?.score;
          return {
            ...e,
            factor,
            selection,
            initialScore: initialScore || 0,
            show: selection === "Yes",
          };
        })
      );
      setJurisdictionTouchPoints((prev) =>
        prev.map((e) => {
          const key = e.key;

          const country =
            res.data.riskAssessmentForm.jurisdictionTouchPoints[key]?.country;

          const score = Array.isArray(country)
            ? Math.max(...[0, ...country.map((c) => countryscores[c]?.score)])
            : countryscores[country]?.score;

          return { ...e, country, initialScore: score || 0 };
        })
      );
      setSourceOfFunds((prev) =>
        prev.map((e) => {
          const key = e.key;
          const factor =
            res.data.riskAssessmentForm.SourceofFundsTransaction[key]?.factor;
          const selection =
            res.data.riskAssessmentForm.SourceofFundsTransaction[key]
              ?.selection;

          const initialScore = Array.isArray(factor)
            ? Math.max(
                ...[
                  0,
                  ...factor.map((c) => data.SourceofFundsTransaction[c]?.score),
                ]
              )
            : data.SourceofFundsTransaction[factor]?.score;

          return {
            ...e,
            factor,
            selection,
            initialScore: initialScore || 0,
            show: selection === "Yes",
          };
        })
      );
      setFactorsEntityProduct((prev) => {
        return prev.map((e) => {
          const key = e?.key;
          const factor =
            res.data.riskAssessmentForm?.EntityProductServiceOffered?.[key] ??
            [];
          return {
            ...e,
            factor,
            initialScore: average(
              factor
                .map((x) => data["EntityProductServiceOffered"]?.[x]?.score)
                .filter((e) => !!e)
            ).toFixed(1),
            value: factor,
          };
        });
      });
      setScores(data);
      setexpectedTransactionValue(res.data?.expectedTransactionValue);
      setexpectedTransactionVolume(res.data?.expectedTransactionVolume);

      setScoresJurisdiction(countryscores);
    } catch (error) {
      console.log(error);
    }
  }, [id]);
  useEffect(() => {
    scoreload();
  }, [scoreload]);

  const handleCommentsChange = (index, value, setFactors) => {
    setFactors((prevFactors) =>
      prevFactors?.map((factor, i) =>
        i === index ? { ...factor, selection: value } : factor
      )
    );
    setFactors((prevFactors) =>
      prevFactors.map((factor, i) =>
        i === index
          ? {
              ...factor,
              show: value === "Yes",
              initialScore: value !== "Yes" ? 0 : factor.initialScore,
            }
          : factor
      )
    );
  };

  const columns = (vals, setter, comments = true, factorSelectconfig) => [
    {
      title: "Factors",
      dataIndex: "name",
      key: "name",
    },
    comments && !factorSelectconfig?.hideComments
      ? {
          title: "Comments: Yes/No/NA",
          dataIndex: "selection",
          key: "selection",
          render: (text, record, index) => (
            <Select
              options={[
                { value: "NA", label: "NA" },
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              className="w-full"
              onChange={(value) => {
                handleCommentsChange(index, value.value, setter);
                if (value.value !== "Yes") {
                  setter((prevFactors) =>
                    prevFactors.map((factor, i) => {
                      return i === index
                        ? {
                            ...factor,
                            initialScore: 0,
                            factor: "",
                            value: "",
                          }
                        : factor;
                    })
                  );
                }
              }}
              value={{ label: text, value: text }}
            ></Select>
          ),
        }
      : {},
    {
      title: "Factor",
      dataIndex: "show",
      key: "show",
      render: (text, record, index) =>
        text ? (
          <Select
            isMulti={record?.isMulti}
            {...factorSelectconfig}
            value={
              record?.isMulti
                ? Array.isArray(record.factor)
                  ? record.factor.map((v) => ({
                      value: v,
                      label: vals[v]?.title,
                    }))
                  : []
                : typeof record?.factor === "string"
                ? { value: record?.factor, label: vals[record.factor]?.title }
                : record.factor?.map?.((e) => {
                    if (e)
                      return {
                        value: e,
                        label: vals[e]?.title,
                      };
                    return e;
                  })
            }
            className="min-w-[100px]"
            options={Object.keys(vals).map((key) => {
              return {
                value: key,
                label: vals[key]?.title,
              };
            })}
            onChange={(value) => {
              setter((prevFactors) =>
                prevFactors.map((factor, i) => {
                  return i === index
                    ? {
                        ...factor,
                        initialScore:
                          record?.isMulti && Array.isArray(value)
                            ? Math.max(
                                0,
                                ...value.map((v) => Number(vals[v.value].score))
                              )
                            : Array.isArray(value)
                            ? average(
                                value.map((x) => vals[x.value].score)
                              ).toFixed(1)
                            : Number(vals[value.value].score),
                        factor: Array.isArray(value)
                          ? value.map((v) => v.value)
                          : value.value,
                        value: Array.isArray(value)
                          ? value.map((v) => v)
                          : value,
                      }
                    : factor;
                })
              );
            }}
            style={{ width: "200px" }}
            dropdownStyle={{ whiteSpace: "wrap" }}
          ></Select>
        ) : null,
    },
    comments
      ? {
          title: "Initial Score",
          dataIndex: "initialScore",
          key: "initialScore",
        }
      : {},
  ];

  const columnJurisdiction = [
    {
      title: "Factors",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Factor",
      dataIndex: "country",
      render: (__, _, index) => {
        return (
          <Space>
            <div style={{ width: 200 }}>
              <Select
                isMulti={_?.isMulti}
                className="w-full"
                options={Object.keys(scoresjurisdiction).map((key) => {
                  return {
                    value: key,
                    label: scoresjurisdiction?.[key]?.title ?? key,
                  };
                })}
                value={
                  Array.isArray(__)
                    ? __.map((v) => ({
                        value: v,
                        label: scoresjurisdiction?.[v]?.title ?? v,
                      }))
                    : {
                        value: __,
                        label: scoresjurisdiction?.[__]?.title ?? __,
                      }
                }
                onChange={(value) => {
                  setJurisdictionTouchPoints((prevFactors) =>
                    prevFactors.map((factor, i) => {
                      return i === index
                        ? {
                            ...factor,
                            initialScore: Array.isArray(value)
                              ? Math.max(
                                  0,
                                  ...value.map((v) =>
                                    Number(scoresjurisdiction[v.value].score)
                                  )
                                )
                              : Number(scoresjurisdiction[value.value].score),
                            country: Array.isArray(value)
                              ? value.map((v) => v.value)
                              : value.value,
                          }
                        : factor;
                    })
                  );
                }}
              />
            </div>
            {!_?.isMulti && (
              <Button
                onClick={() => {
                  setJurisdictionTouchPoints((prevFactors) =>
                    prevFactors.map((factor, i) => {
                      return i === index
                        ? {
                            ...factor,
                            initialScore: 0,
                            country: "",
                          }
                        : factor;
                    })
                  );
                }}
              >
                x
              </Button>
            )}
          </Space>
        );
      },
    },
    {
      title: "Initial Score",
      dataIndex: "initialScore",
      key: "initialScore",
    },
  ];

  const data = (factors) => {
    return factors.map((factor, index) => ({
      key: `DeliveryChannel__${index}`,
      ...factor,
    }));
  };
  const [expectedTransactionValue, setexpectedTransactionValue] = useState(0);
  const [expectedTransactionVolume, setexpectedTransactionVolume] = useState(0);
  const [assessmentDate, setAssessmentDate] = useState(new Date());
  if ([scores, scoresjurisdiction].some((s) => !s)) return <Skeleton active />;

  const transformer = (fac) => {
    const obj = {};
    fac.forEach((e) => {
      obj[e.key] = {
        factor: e.factor ?? e.value,
        selection: e.selection,
      };
    });
    return obj;
  };
  const countries = (fac) => {
    const obj = {};
    fac.forEach((e) => {
      obj[e.key] = {
        country: e.country,
      };
    });
    return obj;
  };

  const handleSubmit = async () => {
    if (factorsEntityProduct[0].value.length === 0)
      return message.info(
        "You must select products / services / transactions offered"
      );
    if (factors.every((f) => f.factor === "" || f.factor?.length === 0))
      return message.info("You must select at least one client characteristic");
    if (DeliveryChannel.every((f) => f.factor === "" || f.factor?.length === 0))
      return message.info("You must select at least one delivery channel");
    if (SourceOfFunds.every((f) => f.factor === "" || f.factor?.length === 0))
      return message.info("You must select at least one source of funds");
    if (
      JurisdictionTouchPoints.every(
        (f) => f.country === "" || f.country?.length === 0
      )
    )
      return message.info("You must select at least one country");

    const formData = {
      id,
      riskAssessmentForm: {
        EntityProductServiceOffered: {
          typeOfProductServiceOfferedToClient: factorsEntityProduct[0].value
            ?.map?.((v) => v?.value ?? v)
            ?.filter?.((a) => !!a),
        },
        ClientCharacteristics: transformer(factors),
        DeliveryChannelsRisk: transformer(DeliveryChannel),
        jurisdictionTouchPoints: countries(JurisdictionTouchPoints),
        SourceofFundsTransaction: transformer(SourceOfFunds),
        manualOverride: manualOverride,
        manualOverrideRationale: manualOverrideRationale,
        complianceNotes: complianceNotes,
        PEP: PEP,
        KYCLevelApplied,
        adverseMedia: adverseMedia,
        SAR: SAR,
        declined: declined,
        sanctions: sanctions,
        incompleteKYC: incompleteKYC,
        client: clients,
        expectedTransactionValue: parseInt(expectedTransactionValue),
        expectedTransactionVolume: parseInt(expectedTransactionVolume),
        assessmentDate: new Date(assessmentDate).toISOString(),
        periodicReviewDate: new Date(period).toISOString(),
        onboardingDate: new Date(onboarding).toISOString(),
        period: selectperiod,
      },
    };

    if (
      factorsEntityProduct?.[0]?.value
        ?.map?.((v) => scores?.EntityProductServiceOffered?.[v?.value ?? v])
        ?.some?.((x) => x?.prohibited)
    )
      message.info(
        "Business with this client is restricted under the policy, escalate to the MLRO or Senior Management immediately"
      );
    await RiskAssessment.editRiskAssessment(formData).then(async () => {
      await scoreload();
      message.success("Successfully Edited Risk Assessment");
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  };
  if (!scores || !scoresjurisdiction) {
    return <Skeleton active />;
  }

  const items = [
    {
      display: canEdit,
      key: "1",
      label: `Edit Risk Assessment`,
      children: (
        <>
          <div ref={componentRef} className="p-1">
            {riskAssessment?.pendingApproval && (
              <>
                <Alert
                  type="warning"
                  message={
                    <>
                      <div>
                        This risk assessment has an MLRO field, and was not
                        approved by the senior management. Please consult the
                        senior manager for further assistance. If you are the
                        senior manager, please review the risk assessment and
                        click approve to mark it as approved.
                      </div>
                      <Space className="mt-2">
                        <Button
                          type="primary"
                          onClick={async () => {
                            await Risks.approveRiskAssessment({
                              riskAssessmentId: id,
                              isApproved: true,
                            });
                            await scoreload();
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          type="primary"
                          onClick={async () => {
                            await Risks.approveRiskAssessment({
                              riskAssessmentId: id,
                              isApproved: false,
                            });
                            await scoreload();
                          }}
                          danger
                        >
                          Decline
                        </Button>
                      </Space>
                    </>
                  }
                />
              </>
            )}
            {(riskAssessment.isApproved || riskAssessment.isDisapproved) &&
              riskAssessment?.latestApproverDisapprover && (
                <div className="w-full  flex gap-3 justify-between">
                  <div className="font-bold">
                    {riskAssessment.isApproved ? "Approved" : "Declined"} by
                  </div>
                  <div>
                    {riskAssessment?.latestApproverDisapprover?.name}{" "}
                    {riskAssessment?.latestApproverDisapprover
                      ?.teamMemberRole && (
                      <>
                        (
                        {
                          riskAssessment?.latestApproverDisapprover
                            ?.teamMemberRole
                        }
                        )
                      </>
                    )}
                  </div>
                </div>
              )}
            <h4 className="">{clients?.name}</h4>
            <div className="my-10">
              <h5 className="">Products / Services / Transactions</h5>
              <Table
                columns={columns(
                  scores["EntityProductServiceOffered"],
                  setFactorsEntityProduct,
                  true,
                  { isMulti: true, hideComments: true }
                )}
                dataSource={data(factorsEntityProduct)}
                pagination={false}
              />
            </div>
            <div className="my-10">
              <h5 className="">Client Characteristics</h5>
              <Table
                columns={columns(scores["ClientCharacteristics"], setFactors)}
                dataSource={data(factors)}
                pagination={false}
              />
            </div>

            <div className="my-10">
              <h5 className=""> Delivery Channel Risk</h5>
              <Table
                columns={columns(
                  scores["DeliveryChannelsRisk"],
                  setDeliveryChannel
                )}
                dataSource={data(DeliveryChannel)}
                pagination={false}
              />
            </div>

            <div className="my-10">
              <h5 className="">Jurisdiction TouchPoint</h5>
              <Table
                columns={columnJurisdiction}
                dataSource={data(JurisdictionTouchPoints)}
                pagination={false}
              />
            </div>

            <div className="my-5">
              <h5 className="">Source of Funds / Transactions</h5>
              <Table
                columns={columns(
                  scores["SourceofFundsTransaction"],
                  setSourceOfFunds
                )}
                dataSource={data(SourceOfFunds)}
                pagination={false}
              />
            </div>

            <div className=" my-10 flex justify-between font-bold">
              <div>Overall Score</div>
              <div>
                {finalScore} {getScoreText(finalScore)}
              </div>
            </div>

            <div className=" my-2 ">
              <label className="!font-bold">Manual Override</label>
              <Input
                value={manualOverride}
                onChange={(e) => {
                  console.log(typeof e.target.value);
                  if (e.target.value <= 0 || e.target.value === "") {
                    setmanualOverrideRationale("");
                  }
                  setmanualoveride(parseInt(e.target.value));
                }}
                type="number"
                className="w-[100px]"
                placeholder="Manual Overide"
              />
            </div>
            {manualOverride !== 0 && (
              <div className="  mb-2">
                <label className="!font-bold">Manual Override Rationale</label>
                <Input
                  value={manualOverrideRationale}
                  onChange={(e) => setmanualOverrideRationale(e.target.value)}
                  type="text"
                  placeholder="Manual Override Rationale"
                />
              </div>
            )}

            <hr />

            {clients?.transactionType && (
              <div className="  ">
                <label className="!font-bold" htmlFor="periodicDate">
                  Next Periodic Date
                </label>
                <Input
                  value={period}
                  onChange={(e) => {
                    if (!e.target.value) return;
                    setperiod(e.target.value);
                  }}
                  id="periodicDate"
                  type="date"
                />
              </div>
            )}

            <div className="  ">
              <label className="!font-bold" htmlFor="periodicDate">
                Assessment Date
              </label>
              <Input
                value={assessmentDate}
                onChange={(e) => {
                  if (!e.target.value) return;
                  setAssessmentDate(e.target.value);
                }}
                id="periodicDate"
                type="date"
              />
            </div>
          </div>
          <hr />

          <Space>
            <Button
              type="primary"
              className="my-5"
              onClick={handleSubmit}
              disabled={loading}
            >
              Save
            </Button>

            <Button
              type="primary"
              className="my-5"
              onClick={handleExportRiskAssessment}
            >
              Print / PDF
            </Button>
          </Space>
        </>
      ),
    },
    {
      key: "2",
      label: `Result History`,
      children: (
        <>
          <ResultCollection />
        </>
      ),
    },
    {
      display: canEdit && !!clients?.transactionType,
      key: "3",
      label: `Periodic Reviews`,
      children: (
        <>
          <PeriodicReview />
        </>
      ),
    },
  ].filter((e) => e?.display !== false);

  return (
    <div>
      <Link
        to={`/app/clients/related-parties/${riskAssessment?.client?._id}`}
        className="text-blue-600"
      >
        « Back to Client
      </Link>

      <Tabs
        defaultActiveKey={activeTabKey || canEdit ? "1" : "2"}
        items={items}
      />
    </div>
  );
};

export default Index;
