import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const handleGeneratePDF = ({ docDef, header, ContentColumn, DataSource }) => {
  const docDefinition = docDef || {
    content: [
      { text: header, style: "header" }, // Title of the PDF
      {
        table: {
          headerRows: 1,
          body: [
            ContentColumn.map((column) => column.title),
            ...DataSource.map((client) =>
              ContentColumn.map((column) =>
                typeof client[column.dataIndex] === "boolean"
                  ? JSON.stringify(client[column.dataIndex])
                  : client[column.dataIndex] || ""
              )
            ),
          ],
        },
        style: "table",
      }, // Table data
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      table: {
        fontSize: 10,
      },
    },
  };

  const pdfDoc = pdfMake.createPdf(docDefinition);
  pdfDoc.download();
};

export { handleGeneratePDF };
