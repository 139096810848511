import { Button, Dropdown, Skeleton, Table, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stringify } from "uuid";
import { selectLoading } from "../../../redux/auth/selectors";
import RiskAssessment from "../../../service/RiskAssessment";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { exportToExcel } from "../Clients/clientByUser";
import { getScoreText } from "../CountryRiskScores";

const IDExpirationReport = () => {
  const [Reports, setReports] = useState(null);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    const loadClients = async () => {
      const response = await RiskAssessment.getIDExpirationReport({});
      setReports(
        response.data["riskReports"].map((t) => ({ ...t, key: t?._id }))
      );
    };
    loadClients();
  }, []);

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
    },
    {
      title: "Related Party Name",
      dataIndex: "relatedPartyName",
    },
    {
      title: "ID Number",
      dataIndex: "IDNumber",
    },
    {
      title: "ID Type",
      dataIndex: "IDType",
    },
    {
      title: "ID Expiration",
      dataIndex: "IDExpiration",
      render: (a) => (
        <div className="whitespace-nowrap">
          {a ? moment.utc(a).format("YYYY-MM-DD") : " "}
        </div>
      ),
    },
  ];
  if (!Reports) {
    return <Skeleton active />;
  }
  return (
    <>
      <Table
        className="w-full max-w-2xl"
        dataSource={Reports}
        columns={columns}
        loading={loading}
        pagination={true}
        footer={() => (
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: "export-excel",
                  label: (
                    <Button
                      onClick={() =>
                        exportToExcel(
                          JSON.parse(JSON.stringify(Reports)).map((e) => {
                            delete e?.clientId;
                            delete e?.clientGroupId;
                            return e;
                          })
                        )
                      }
                    >
                      Excel
                    </Button>
                  ),
                },
                {
                  key: "export-pdf",
                  label: (
                    <Button
                      onClick={() =>
                        handleGeneratePDF({
                          header: "Reports",
                          ContentColumn: columns.filter(
                            (c) => c.dataIndex !== "rating"
                          ),
                          DataSource: Reports,
                        })
                      }
                    >
                      PDF
                    </Button>
                  ),
                },
              ],
            }}
            arrow={{
              pointAtCenter: true,
            }}
          >
            Export
          </Dropdown.Button>
        )}
      />
    </>
  );
};

export default IDExpirationReport;
