import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import Admin from "../../../service/Admin";
import Public from "../../../service/Public";
import HTMLTextEditor from "./HtmlEditor";

const OtherLegal = () => {
  const [currentVal, setCurrenVal] = useState(null);
  const getCurrent = async () => {
    try {
      const res = await Public.getOtherLegal();
      setCurrenVal(res.data.otherLegal);
    } catch (e) {
      setCurrenVal("");
    }
  };
  useEffect(() => {
    getCurrent();
  }, [getCurrent]);

  if (currentVal === null) return <Skeleton active />;
  return (
    <div>
      <div className="my-10">
        <h4>Other Legal Information</h4>
        <HTMLTextEditor
          update={async (e) => await Admin.changeOtherLegal({ otherLegal: e })}
          quillattributes={{
            className: "relative h-[300px] max-w-[700px] flex flex-col gap-10",
          }}
          defaultValue={currentVal}
        />
      </div>
    </div>
  );
};

export default OtherLegal;
