import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Statistics = ({ hideFilter, statistics = [] }) => {
  const [filter, setFilter] = useState("all");
  const navigate = useNavigate();

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        {statistics.map((statistic) => (
          <div
            key={statistic.id}
            className={`${
              statistic?.link ? "cursor-pointer" : ""
            } p-4 rounded-lg shadow-md bg-white hover:bg-[#294895] [&>*]:hover:text-[#ffffff] transition duration-300 ease-in-out ${
              statistic.bgColor ? statistic.bgColor : ""
            }`}
            onClick={() => {
              if (!statistic?.link) return;
              navigate(statistic?.link);
            }}
          >
            <div className="flex items-center mb-2">
              <div className="text-2xl mr-2">{statistic.icon}</div>
              <p className="text-[11px] font-semibold whitespace-nowrap">
                {statistic.title}
              </p>
            </div>
            <div className="text font-bold">{statistic.value}</div>
          </div>
        ))}
      </div>

      {!hideFilter && (
        <div className="flex mb-2">
          <button
            className={`px-4 py-2 rounded-l-md focus:outline-none ${
              filter === "all"
                ? "bg-[#294895] text-[#ffffff] text-white"
                : "bg-gray-200"
            }`}
            onClick={() => handleFilterChange("all")}
          >
            Overall
          </button>
          <button
            className={`px-4 py-2 focus:outline-none ${
              filter === "7days"
                ? "bg-[#294895] text-[#ffffff] text-white"
                : "bg-gray-200"
            }`}
            onClick={() => handleFilterChange("7days")}
          >
            Last 7 Days
          </button>
          <button
            className={`px-4 py-2 focus:outline-none ${
              filter === "lastMonth"
                ? "bg-[#294895] text-[#ffffff] text-white"
                : "bg-gray-200"
            }`}
            onClick={() => handleFilterChange("lastMonth")}
          >
            Last Month
          </button>
          <button
            className={`px-4 py-2 focus:outline-none ${
              filter === "lastYear"
                ? "bg-[#294895] text-[#ffffff] text-white"
                : "bg-gray-200"
            }`}
            onClick={() => handleFilterChange("lastYear")}
          >
            Last Year
          </button>
        </div>
      )}
    </>
  );
};

export default Statistics;
