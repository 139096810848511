import { Button, Divider, Input, Switch, message } from "antd";
import React, { useEffect } from "react";
import User from "../../../service/User";

const Profile = () => {
  const [name, setFirstname] = React.useState("");
  const [isBusiness, setIsBusiness] = React.useState("");
  const [employeeStrength, setEmployeeStrength] = React.useState(0);
  const [contactNumber, setContactNumber] = React.useState("");
  const [vatNumber, setVatNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [ownname, setOwnname] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await User.updateProfile({
      name,
      isBusiness,
      employeeStrength,
      address,
      contactNumber,
      vatNumber,
      ownname,
    });
    message.success("Updated the profile successfully");
  };
  const handlePasswordChange = async () => {
    await User.changePassword({ oldPassword, newPassword });
  };
  useEffect(() => {
    const loadProfile = async () => {
      const { data } = await User.me();
      console.log(data);
      setFirstname(data?.name || "");
      setIsBusiness(data?.isBusiness);
      setContactNumber(data?.contactNumber);
      setEmployeeStrength(data?.employeeStrength);
      setVatNumber(data?.vatNumber);
      setAddress(data?.address);
      setOwnname(data?.ownname ?? "");
    };
    loadProfile();
  }, []);
  return (
    <>
      <h3>Profile</h3>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex space-x-4">
          <div className="w-full">
            <div>
              <label>{isBusiness ? "Company name" : "Full name"}</label>
              <Input
                value={name}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </div>
            {isBusiness && (
              <div>
                <label>{"Your full name"}</label>
                <Input
                  value={ownname}
                  onChange={(e) => setOwnname(e.target.value)}
                />
              </div>
            )}
            <div className="mt-4 mb-4">
              <Switch
                checked={isBusiness}
                onChange={(e) => setIsBusiness(e)}
                checkedChildren="Business"
                unCheckedChildren="Individual"
              />
            </div>
            {isBusiness && (
              <div>
                <label>Company Headcount</label>
                <input
                  type="number"
                  className="field nonsvg w-input"
                  maxLength={256}
                  placeholder={`Enter company headcount`}
                  value={employeeStrength}
                  onChange={(e) => {
                    setEmployeeStrength(parseInt(e.target.value));
                  }}
                />
              </div>
            )}
            <div>
              <label>VAT</label>
              <Input
                value={vatNumber}
                onChange={(e) => setVatNumber(e.target.value)}
              />
            </div>

            <Divider />
            <div className="flex w-full">
              <div className="w-full">
                <label>Street</label>
                <Input
                  value={address.street}
                  onChange={(e) =>
                    setAddress((a) => ({ ...a, street: e.target.value }))
                  }
                />
              </div>
              <div className="w-1/4">
                <label>No.</label>
                <Input
                  value={address.nr}
                  onChange={(e) =>
                    setAddress((a) => ({ ...a, nr: e.target.value }))
                  }
                />
              </div>
            </div>
            <div className="flex w-full">
              <div className="w-1/3">
                <label>Post Code</label>
                <Input
                  value={address.postcode}
                  onChange={(e) =>
                    setAddress((a) => ({ ...a, postcode: e.target.value }))
                  }
                />
              </div>
              <div className="w-full">
                <label>City</label>
                <Input
                  value={address.city}
                  onChange={(e) =>
                    setAddress((a) => ({ ...a, city: e.target.value }))
                  }
                />
              </div>
            </div>
            <Divider />

            <div>
              <label htmlFor="phone">Contact Number</label>
              <Input
                id="phone"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </form>

      <Divider />
      <div>
        <h4 className="mb-2">Change Password</h4>
        <label htmlFor="oldPassword">Old Password</label>
        <Input.Password
          id="oldPassword"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
        <label htmlFor="newPassword">New Password</label>
        <Input.Password
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </div>
      <Button type="primary" className="mt-4" onClick={handlePasswordChange}>
        Save
      </Button>
    </>
  );
};

export default Profile;
