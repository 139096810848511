import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class RiskAssessment {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  getWeightingTable() {
    return this.api.get("/getWeightingTable");
  }

  changeWeightingTable(data) {
    return this.api.put("/changeWeightingTable", data);
  }

  getRiskFactorScores() {
    return this.api.get("/getRiskFactorScores");
  }

  changeRiskFactorScores({
    EntityProductServiceOffered,
    ClientCharacteristics,
    SourceofFundsTransaction,
    DeliveryChannelsRisk,
  }) {
    return this.api.put("/changeRiskFactorScores", {
      EntityProductServiceOffered,
      ClientCharacteristics,
      SourceofFundsTransaction,
      DeliveryChannelsRisk,
    });
  }

  getCountryRiskScores() {
    return this.api.get("/getCountryRiskScores");
  }

  changeCountryRiskScores(body) {
    return this.api.put("/changeCountryRiskScores", body);
  }

  createRiskAssessment({ riskAssessmentForm }) {
    return this.api.post("/createRiskAssessment", { riskAssessmentForm });
  }

  editRiskAssessment({ id, riskAssessmentForm }) {
    return this.api.put("/editRiskAssessment", { id, riskAssessmentForm });
  }

  getRiskAssessments({ clientId }) {
    return this.api.get(`/getRiskAssessments?clientId=${clientId}`);
  }
  deleteRiskAssessment({ assessmentId }) {
    return this.api.delete(`/deleteRiskAssessment/${assessmentId}`);
  }

  getSingleRiskAssessments({ id }) {
    return this.api.get(`/getsingleriskAssessment/${id}`);
  }

  periodicReviewReminder() {
    return this.api.get("/periodicReviewReminder");
  }

  dailyRiskAssessmentCheck() {
    return this.api.get("/dailyRiskAssessmentCheck");
  }
  getPeriodicReviewConfig() {
    return this.api.get("/getPeriodicReviewConfig");
  }
  getClientGroupReport({ limit, offset }) {
    return this.api.get(
      `/getClientGroupReport?limit=${limit}&offset=${offset}`
    );
  }
  getScreeningReport() {
    return this.api.get(`/getScreeningReport`);
  }
  setPeriodicReviewConfig(config) {
    return this.api.put("/setPeriodicReviewConfig", { config });
  }

  approveRiskAssessment({ riskAssessmentId, isApproved }) {
    return this.api.post("/approveRiskAssessment", {
      riskAssessmentId,
      isApproved,
    });
  }

  submitPeriodicReview({
    riskAssessmentId,
    periodicReviewForm,
    nextReviewDate,
  }) {
    return this.api.post("/submitPeriodicReview", {
      riskAssessmentId,
      periodicReviewForm,
      nextReviewDate,
    });
  }
  submitPeriodicReviewUpdated({
    riskAssessmentId,
    periodicReviewForm,
    nextReviewDate,
  }) {
    return this.api.post("/submitPeriodicReviewUpdated", {
      riskAssessmentId,
      periodicReviewForm,
      nextReviewDate,
    });
  }

  getRiskReports({ limit, offset }) {
    return this.api.get(`/getRiskReports?limit=${limit}&offset=${offset}`);
  }

  ClientsReport({ limit, offset }) {
    return this.api.get(`/getClientReport?limit=${limit}&offset=${offset}`);
  }

  getRelatedReports() {
    return this.api.get("/getRelatedReports");
  }
  getRegulatoryReports({ limit, offset }) {
    return this.api.get(
      `/getRegulatoryReports?limit=${limit}&offset=${offset}`
    );
  }
  getEscalationReport() {
    return this.api.get(`/getEscalationReport`);
  }
  getAdverseReport({ limit, offset }) {
    return this.api.get(`/getAdverseReport?limit=${limit}&offset=${offset}`);
  }
  getPeriodicReviewReport({ limit, offset }) {
    return this.api.get(
      `/getPeriodicReviewReport?limit=${limit}&offset=${offset}`
    );
  }
  getIDExpirationReport({ limit, offset }) {
    return this.api.get(
      `/getIDExpirationReport?limit=${limit}&offset=${offset}`
    );
  }
  resetCountryRiskScores() {
    return this.api.post("/resetCountryRiskScores");
  }
  resetInherentRiskScores() {
    return this.api.post("/resetInherentRiskScores");
  }
  resetWeightingTable() {
    return this.api.post("/resetWeightingTable");
  }
}

export default new RiskAssessment(`${getBackendUrl()}/riskAssessment`);
