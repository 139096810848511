const WeighingtableData = {
  productService: "Products / Services / Transactions",

  clientCharacteristics: "Client Characteristics",

  deliveryChannel: "Delivery Channel",

  jurisdiction: "Jurisdiction",

  sourceOfFunds: "Source of Funds / Transaction",
};
export { WeighingtableData };
