import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class Auth {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  register({ email, password, otp }) {
    return this.api.post("/register", {
      email,
      password,
      otp,
      rValue: localStorage.rValue,
    });
  }

  createSubUser({
    name,
    email,
    password,
    parentUserId,
    teamMemberRole,
    hasFullClientAccess,
    accessedClients,
  }) {
    return this.api.post("/createSubUser", {
      name,
      email,
      password,
      parentUserId,
      teamMemberRole,
      hasFullClientAccess,
      accessedClients,
      baseUrl: window.location.origin,
    });
  }

  login({ email, password }) {
    return this.api.post("/login", { email, password });
  }
  verifyLogin({ email, code }) {
    return this.api.post("/verifyLogin", { email, code });
  }

  refresh({ accessToken, refreshToken }) {
    var raw = JSON.stringify({ accessToken, refreshToken });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
    };

    return fetch(this.baseURL + "/refresh", requestOptions);
  }

  requestPasswordReset({ email }) {
    return this.api.post("/requestPasswordReset", { email });
  }

  // TODO
  resetPassword({ resetToken, newPassword }) {
    return this.api.post("/resetPassword", { resetToken, newPassword });
  }
  teammemberPassword({ resetToken, newPassword }) {
    return this.api.post("/passwordsetteammember", {
      resetToken,
      newPassword,
    });
  }

  // TODO
  confirmEmail({ email }) {
    return this.api.post("/confirmEmail", { email });
  }
  resendOtp({ email }) {
    return this.api.post("/resendotp", { email });
  }
}

export default new Auth(`${getBackendUrl()}/auth`);
