import { Button, Skeleton, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RiskAssessment from "../../../service/RiskAssessment";
import { getScoreText } from "../CountryRiskScores";

const Index = () => {
  const { id } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [client, setClient] = useState(null);
  const navigate = useNavigate()
  useEffect(() => {
    const loadAssessment = async () => {
      const { data } = await RiskAssessment.getSingleRiskAssessments({
        id: id,
      });
      setClient(data?.client);
      setAssessment(
        data.riskAssessmentResults.reverse().map((e) => ({
          ...e,
          date: e.date,
          clientCharacteristics: e.result.clientCharacteristics.finalScore,
          deliveryChannel: e.result.deliveryChannel.finalScore,
          jurisdictionTouchPoints: e.result.jurisdictionTouchPoints.finalScore,
          productServiceRisk: e.result.productServiceRisk.finalScore,
          sourceOfFundsTransaction:
            e.result.sourceOfFundsTransaction.finalScore,
          finalOverallScore: e.result.finalOverallScore,
          id: e._id,
        }))
      );
    };
    loadAssessment();
  }, [id]);

  const column = [
    {
      title: "Client Name",
      dataIndex: "client",
      render: (_, __) => (
        <div className="whitespace-nowrap">{client?.name ?? ""}</div>
      ),
    },
    {
      title: "Created",
      dataIndex: "date",
      render: (a) => (
        <div className="whitespace-nowrap">
          {moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    { title: "Overall Scores", dataIndex: "finalOverallScore" },
    {
      title: "Risk Rating",
      dataIndex: "score",
      key: "score",
      render: (_, record) => getScoreText(record.finalOverallScore),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (_, data) => (
        <Button onClick={() => {
          navigate(`/app/result/${id}/${_}`, {
            state: data,
          });
        }} type="primary">Details</Button>
      ),
    },
  ];

  if (!assessment) {
    return <Skeleton active />;
  }
  return (
    <div>
      <h5 className="!my-10">Results</h5>
      <Table
        className="w-full max-w-2xl"
        dataSource={assessment}
        rowKey="uid"
        columns={column}
      />
    </div>
  );
};

export default Index;
