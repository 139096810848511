import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React from "react";
import ReactDOMServer from "react-dom/server";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const getScoreText = (score) => {
  if (score < 2) {
    return "Low";
  } else if (score < 3) {
    return "Medium";
  } else if (score < 4) {
    return "High";
  } else {
    return "Refer to MLRO";
  }
};

const handleGeneratePDF = ({ docDef, header, ContentColumn, DataSource }) => {
  const docDefinition = docDef || {
    content: [
      { text: header, style: "header" },
      {
        table: {
          headerRows: 1,
          body: [
            ContentColumn.map((column) => column.title),
            ...DataSource.map((client) => {
              const rowData = ContentColumn.map((column) => {
                if (column.dataIndex === "rating") {
                  // console.log("client[column.dataIndex]", client.riskRating);
                  return getScoreText(client.riskRating);
                } else {
                  const cellData =
                    client[column.dataIndex] === "rating"
                      ? getScoreText(client[column.dataIndex])
                      : client[column.dataIndex] || "";

                  return cellData;
                }
              });

              return rowData;
            }),
          ],
        },
        style: "table",
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      table: {
        fontSize: 10,
      },
      boldCell: {
        bold: true,
      },
    },
  };

  const pdfDoc = pdfMake.createPdf(docDefinition);
  pdfDoc.download();
};

export { handleGeneratePDF };
