import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class MultiLogin {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  getOptions() {
    return this.api.get("/getOptions");
  }
  switchLogin({ id }) {
    return this.api.post("/switchLogin", { id });
  }
}

export default new MultiLogin(`${getBackendUrl()}/multiLogin`);
