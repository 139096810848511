import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Public from "../service/Public";

const Landing = () => {
  const [api, contextHolder] = notification.useNotification();
  const [mobileMenu, setMobileMenu] = useState(false);

  const [heroImage, setHeroImage] = useState(localStorage.heroImage ?? null);
  const [heroImage2, setHeroImage2] = useState(localStorage.heroImage2 ?? null);
  const [heroImage3, setHeroImage3] = useState(localStorage.heroImage3 ?? null);
  const [heroImage4, setHeroImage4] = useState(localStorage.heroImage4 ?? null);
  const [heroImage5, setHeroImage5] = useState(localStorage.heroImage5 ?? null);

  const [subscriptions, setSubscriptions] = useState(
    localStorage.subscriptions ?? null
  );
  const [FAQs, setFAQs] = useState(localStorage.FAQs ?? null);

  const loadDataPricing = useCallback(async () => {
    const data = await Public.getSubscriptionPackages();
    if (data.data.subscriptions) {
      setSubscriptions(data.data.subscriptions);
      localStorage.setItem("subscriptions", data.data.subscriptions);
    }
  }, []);
  const loadDataFAQ = useCallback(async () => {
    const data = await Public.getFAQs();
    if (data.data.FAQs) {
      setFAQs(data.data.FAQs);
      localStorage.setItem("FAQs", data.data.FAQs);
    }
  }, []);

  const loadData = async () => {
    const data = await Public.getHeroImage("heroImage");
    if (data.data.heroImage) {
      setHeroImage(data.data.heroImage);
      localStorage.setItem("heroImage", data.data.heroImage);
    }
  };
  const loadData2 = async () => {
    const data = await Public.getHeroImage("heroImage2");
    if (data.data.heroImage2) {
      setHeroImage2(data.data.heroImage2);
      localStorage.setItem("heroImage2", data.data.heroImage2);
    }
  };
  const loadData3 = async () => {
    const data = await Public.getHeroImage("heroImage3");
    if (data.data.heroImage3) {
      setHeroImage3(data.data.heroImage3);
      localStorage.setItem("heroImage3", data.data.heroImage3);
    }
  };
  const loadData4 = async () => {
    const data = await Public.getHeroImage("heroImage4");
    if (data.data.heroImage4) {
      setHeroImage4(data.data.heroImage4);
      localStorage.setItem("heroImage4", data.data.heroImage4);
    }
  };
  const loadData5 = async () => {
    const data = await Public.getHeroImage("heroImage5");
    if (data.data.heroImage5) {
      setHeroImage5(data.data.heroImage5);
      localStorage.setItem("heroImage5", data.data.heroImage5);
    }
  };

  useEffect(() => {
    loadData();
    loadData2();
    loadData3();
    loadData4();
    loadData5();
    loadDataPricing();
    loadDataFAQ();
  }, [loadDataPricing]);

  const openNotification = (placement) => {
    api.success({
      message: `Would you like to test our solution?`,
      description: (
        <div className="landing-wrapper">
          <div>
            <a href="#" className="button cc-small w-button">
              Book a demo
            </a>
          </div>
        </div>
      ),
      placement,
      duration: 0,
    });
  };

  // useEffect(() => {
  //   setTimeout(() => openNotification("bottomRight"), 60 * 1000); // open demo booking popup after 60 seconds on the page
  // }, []);

  return (
    <div className="landing-wrapper">
      {contextHolder}
      <div>
        <div className="navbar wf-section">
          <div className="navbar-space" />
          <div
            data-animation="default"
            data-collapse="medium"
            data-duration={400}
            data-easing="ease-out"
            data-easing2="ease-out"
            role="banner"
            className="navbar-inner w-nav"
          >
            <div className="container">
              <div className="navbar-wrapper">
                <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                  <div className="nav-menu-flex">
                    <Link to="/" className="w-nav-brand">
                      <img
                        src="/images/Logo_horizontal.png"
                        loading="lazy"
                        height
                        alt=""
                        width={357}
                        srcSet="/images/Logo_horizontal-p-500.png 500w, images/Logo_horizontal-p-800.png 800w, images/Logo_horizontal-p-1080.png 1080w, images/Logo_horizontal-p-1600.png 1600w, images/Logo_horizontal-p-2000.png 2000w, images/Logo_horizontal-p-2600.png 2600w, images/Logo_horizontal.png 3056w"
                        sizes="(max-width: 767px) 100vw, (max-width: 991px) 203.8715362548828px, (max-width: 1279px) 21vw, 18vw"
                        className="logo"
                      />
                    </Link>
                    <ul role="list" className="nav-menu w-list-unstyled">
                      <li>
                        <a href="#home" className="nav-link">
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="#process" className="nav-link">
                          About
                        </a>
                      </li>
                      <li>
                        <a href="#howitworks" className="nav-link">
                          How it works
                        </a>
                      </li>
                      <li>
                        <a href="#pricing" className="nav-link">
                          Pricing
                        </a>
                      </li>
                    </ul>
                    <Link to="/auth/login" style={{ marginRight: 20 }}>
                      <img
                        src="/images/Frame-10.png"
                        loading="lazy"
                        width={45}
                        alt=""
                        className="image-2"
                      />
                    </Link>
                    <div className="navbar-button-wrapper">
                      <Link to="/contact" className="button cc-small w-button">
                        Get in touch
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
          <div
            data-collapse="medium"
            data-animation="default"
            data-duration={400}
            data-easing="ease-out"
            data-easing2="ease-out"
            data-w-id="577246c2-be80-9a0d-4fe9-018c566815d9"
            role="banner"
            className="navbar-inner-mobile w-nav"
          >
            <div className="container">
              <div className="navbar-wrapper-mobile">
                <Link
                  to="/"
                  aria-current="page"
                  className="navbar-brand-mobile w-nav-brand w--current"
                >
                  <img
                    src="/images/Logo_horizontal.png"
                    loading="lazy"
                    height
                    width="119.5"
                    alt="flux"
                    className="logo"
                  />
                </Link>
                <div className="flex items-center">
                  <Link to="/auth/login" style={{ marginRight: 20 }}>
                    <img
                      src="/images/Frame-10.png"
                      loading="lazy"
                      width={45}
                      alt=""
                      className="image-2"
                    />
                  </Link>
                  <div
                    data-w-id="577246c2-be80-9a0d-4fe9-018c566815de"
                    className="menu-button w-nav-button"
                    onClick={() => setMobileMenu((e) => !e)}
                  >
                    <div className="burger">
                      <div className="burger-line cc-top" />
                      <div className="burger-line cc-middle" />
                      <div className="burger-line cc-bottom" />
                    </div>
                  </div>
                </div>
              </div>
              <nav
                role="navigation"
                className="nav-menu-wrapper-mobile w-nav-menu"
                style={{ display: mobileMenu ? "unset" : "none" }}
              >
                <div className="nav-menu-flex-mobile">
                  <ul role="list" className="nav-menu w-list-unstyled">
                    <li>
                      <a
                        href="#home"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        href="#process"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        About
                      </a>
                    </li>
                    <li>
                      <a
                        href="#howitworks"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        How it works
                      </a>
                    </li>
                    <li>
                      <a
                        href="#pricing"
                        className="nav-link-mobile"
                        onClick={() => setMobileMenu(false)}
                      >
                        Pricing
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div className="navbar-shadow" />
          <div className="navbar-space" />
        </div>
        <section
          id="home"
          className="section background-primary-soft wf-section"
        >
          <div className="hero">
            <div className="hero-image-wrapper">
              <div className="div-block-2" />
              {heroImage && (
                <img
                  src={heroImage}
                  loading="lazy"
                  width={1000}
                  sizes="(max-width: 991px) 100vw, 50vw"
                  alt=""
                  className="hero-image"
                />
              )}
            </div>
            <div className="container">
              <div className="hero-grid">
                <div
                  id="w-node-dc678c3f-8d8f-00ba-7466-144a35cd5f25-9c4ebe05"
                  className="hero-content"
                >
                  <h1
                    data-w-id="5cd846b7-aae4-71b2-ca7a-70d8b7616be7"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    className="display-1 margin-bottom-20"
                  >
                    Welcome to Synrgy
                    <span className="text-gradient text-emphasize nonitalic">
                      Risk
                    </span>
                    !
                    <br />
                    <div style={{ fontSize: 24, lineHeight: 1, marginTop: 18 }}>
                      Redefining{" "}
                      <span className="text-gradient text-emphasize">
                        Risk Assessments{" "}
                      </span>
                      <span>for the Financial World.</span>
                    </div>
                  </h1>
                  <br />
                  <p
                    data-w-id="aeeed16e-bfc0-1466-2017-39da55aff322"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      fontSize: 18,
                      lineHeight: 1.1,
                      marginTop: 1,
                    }}
                    className="paragraph-large"
                  >
                    SynrgyRisk is a cost effective and customizable solution,
                    streamlining the way you manage your customer’s anti-money
                    laundering risk exposure.
                  </p>
                  <p
                    data-w-id="aeeed16e-bfc0-1466-2017-39da55aff322"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      fontSize: 18,
                      lineHeight: 1.1,
                      marginTop: 1,
                    }}
                    className="paragraph-large"
                  >
                    Leverage the power of data and let SynrgyRisk give you a
                    comprehensive and automated means of measuring,
                    understanding, and explaining your customer’s anti money
                    laundering risks.
                  </p>
                  <div
                    data-w-id="9a004d67-26d4-a27d-f78c-0ec4169fc242"
                    style={{
                      WebkitTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                  >
                    <Link
                      Link
                      to="/auth/login"
                      className="button cc-large w-button"
                    >
                      Sign up now
                    </Link>
                  </div>
                  <img
                    src="/images/doodle2.svg"
                    loading="lazy"
                    height={70}
                    data-w-id="62c94f9a-5b09-7cb4-88d8-b76cbdfdc2cc"
                    alt=""
                    className="doodle-arrow"
                  />
                  <img
                    src="/images/doodle1.svg"
                    loading="lazy"
                    height={45}
                    data-w-id="c1f648b3-2bae-f1cd-1a4e-1cfa0c938230"
                    alt=""
                    className="doodle-shine"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="process" className="section background-light wf-section">
          <div className="container">
            <div className="reasons">
              <div className="headline-reasons">
                <h3
                  data-w-id="dcff6d7c-9a88-842e-cff4-8eadd2d13368"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  }}
                  className="label text-secondary"
                >
                  Why Choose SynrgyRisk?
                </h3>
                <h2
                  data-w-id="8a5cd9a5-27e3-6133-dcf7-1c166a60f511"
                  style={{
                    WebkitTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    MozTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    msTransform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    transform:
                      "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  }}
                  className="display-2"
                >
                  Discover a platform that transforms risk into confidence
                </h2>
              </div>
              <div
                data-w-id="7ddfe38d-e9d5-0a7a-c508-ae4044a55f76"
                style={{
                  WebkitTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                data-current="Tab 1"
                data-easing="ease"
                data-duration-in={300}
                data-duration-out={100}
                className="w-tabs"
              >
                <div className="tabs-menu w-tab-menu">
                  <a
                    data-w-tab="Tab 1"
                    className="tab card w-inline-block w-tab-link w--current"
                  >
                    {/* <div className="step text-gradient">01</div> */}
                    <div className="tab-inner">
                      <h3>Efficient Reporting</h3>
                      <div className="body-color">
                        Embrace efficiency like never before. Our automated
                        platform is the key to swift, seamless reporting – it's
                        not just about saving time, it's about amplifying your
                        team's effectiveness and reducing cost.
                      </div>
                    </div>
                  </a>
                  <a
                    data-w-tab="Tab 2"
                    className="tab card w-inline-block w-tab-link"
                  >
                    {/* <div className="step text-gradient">02</div> */}
                    <div className="tab-inner">
                      <h3>Compliance Made Simple</h3>
                      <div className="body-color">
                        Navigate the complex field of regulatory demands with
                        ease. SynrgyRisk is your ally in deconstructing complex
                        compliance and AML obligations into simple, actionable
                        insights.
                      </div>
                    </div>
                  </a>
                  <a
                    data-w-tab="Tab 3"
                    className="tab card w-inline-block w-tab-link"
                  >
                    {/* <div className="step text-gradient">03</div> */}
                    <div className="tab-inner">
                      <h3>Risk Management Reimagined</h3>
                      <div className="body-color">
                        Experience the power of cutting-edge technology to
                        safeguard your organization, secure your peace of mind
                        and ensure your client’s AML risk exposure is mitigated.
                      </div>
                    </div>
                  </a>
                </div>
                <div className="tabs-content w-tab-content">
                  <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                    <div className="tab-pane-grid">
                      <div id="w-node-_33d5ff0a-a9ee-1551-3986-b35178efb1e7-9c4ebe05">
                        {heroImage2 && (
                          <img
                            src={heroImage2}
                            loading="lazy"
                            width={1000}
                            sizes="(max-width: 479px) 92vw, (max-width: 991px) 87vw, 43vw"
                            alt=""
                            className="rounded shadow"
                          />
                        )}
                      </div>
                      <div id="w-node-b791cf82-82b9-7055-7e04-4f63f43e2f91-9c4ebe05">
                        <h2>Proactive Risk Mitigation</h2>
                        <p>
                          Built upon decades of experience in the regulatory
                          compliance space, SynrgyRisk provides proactive risk
                          mitigation designed for your unique organizational
                          needs.
                        </p>
                        <div className="bullet-list margin-bottom-30">
                          <div className="bullet-list-item">
                            <div className="bullet">
                              <div className="unicon"></div>
                            </div>
                            <div>
                              Refine your AML strategy with a customisable
                              interface that adapts to your requirements.
                            </div>
                          </div>
                          <div className="bullet-list-item">
                            <div className="bullet">
                              <div className="unicon"></div>
                            </div>
                            <div>
                              Protect your business and clients from the risks
                              of non-compliance with the smartest, most
                              efficient review process available.
                            </div>
                          </div>
                          <div className="bullet-list-item">
                            <div className="bullet">
                              <div className="unicon"></div>
                            </div>
                            <div>
                              Say goodbye to extensive spreadsheet and manual
                              date entry. SynrgyRisk’s built in periodic review
                              feature means you receive notifications and can
                              conduct your reviews at the click of a button.
                            </div>
                          </div>
                        </div>
                        <Link to="/contact" className="button w-button">
                          Get in touch
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="howitworks"
          className="section background-light wf-section"
        >
          <div className="container">
            <div className="services-grid">
              <div
                id="w-node-_72b8af2d-5524-9ad8-95e6-936ff7033a26-9c4ebe05"
                data-w-id="72b8af2d-5524-9ad8-95e6-936ff7033a26"
                style={{
                  WebkitTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="sticky"
              >
                <h3 className="label text-secondary">
                  HOW&nbsp;IT&nbsp;WORKS?
                </h3>
                <h2 className="display-2 margin-bottom-25">
                  Streamlining AML Compliance - A closer look at our process
                </h2>
                <h3>Centralised simplicity in risk assessment </h3>
                <p className="margin-bottom-35">
                  Our platform offers a unified, straightforward approach to
                  customer risk ratings and systematic reviews, ensuring you
                  stay ahead of AML risk exposures with confidence.
                </p>
                <h2 className="display-2 margin-bottom-25">
                  Comprehensive automation for informed decisions
                </h2>
                <h3>Centralised simplicity in risk assessment </h3>
                <p className="margin-bottom-35">
                  SynrgyRisk equips your business with an all-encompassing
                  toolkit for a comprehensive assessment of anti-money
                  laundering risks. Discover the convenience of automation,
                  where measuring, understanding, and articulating customer AML
                  risks becomes an exercise in precision and clarity.
                </p>
                <Link to="/auth/login" className="button w-button mb-10">
                  Sign up now
                </Link>
              </div>
              <div id="w-node-e936af14-d0d6-3191-8d17-d905eda72cd8-9c4ebe05">
                <div>
                  <h2>
                    <strong>
                      Tailored Features for Tailored Risk Management
                    </strong>
                  </h2>
                  <div className="bullet-list margin-bottom-30">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        <div>
                          <strong>
                            Intelligent automation with a personal touch
                          </strong>
                        </div>
                        <div>
                          Our tool doesn't just automate risk assessments; it
                          adapts to you. Input your data, and watch as it
                          intuitively calculates risk with the option for manual
                          refinement, ensuring your unique risk tolerance is
                          always at the forefront.
                        </div>
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        <div>
                          <strong>Customization at your command</strong>
                        </div>
                        <div>
                          Input your risk factors to fit your appetite. Adjust
                          the weights, tailor the categories, and determine what
                          risks mean the most to your organization. SynrgyRisk
                          puts you in the driver's seat of data-driven
                          decision-making.
                        </div>
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        <div>
                          <strong>Seamless Integration</strong>
                        </div>
                        <div>
                          Transitioning to SynrgyRisk is a breeze with our bulk
                          upload feature. Migrate your client groups
                          effortlessly, ensuring your data is up-to-date and
                          ready for action.
                        </div>
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        <div>
                          <strong>Never Miss a Beat</strong>
                        </div>
                        <div>
                          Set review dates and let SynrgyRisk keep watch. With
                          proactive alerts for upcoming deadlines, you'll stay
                          ahead of the curve and in regulators' good graces.
                        </div>
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        <div>
                          <strong>Regulatory Reporting, Simplified</strong>
                        </div>
                        <div>
                          Exporting and sharing compliance reports is just a
                          click away. Download or email customer risk
                          assessments and reviews directly, providing clear,
                          concise evidence to satisfy regulatory bodies with
                          ease.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h2>
                    <strong>
                      SynrgyRisk allows you to tailor your risk assessment
                      framework for risks related to:
                    </strong>
                  </h2>

                  <div className="bullet-list ">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Products or Services</div>
                    </div>
                  </div>
                  <div className="bullet-list ">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Customer Characteristics</div>
                    </div>
                  </div>
                  <div className="bullet-list ">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Delivery Channels</div>
                    </div>
                  </div>
                  <div className="bullet-list ">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Country</div>
                    </div>
                  </div>
                  <div className="bullet-list margin-bottom-30">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>Transactions and Source of Funds</div>
                    </div>
                  </div>
                </div>
                <div>
                  <h2>
                    <strong>Features</strong>
                  </h2>
                  <div className="bullet-list margin-bottom-30">
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Our risk assessment tool offers an automated risk
                        assessment flow based on your data input, with manual
                        override and risk tolerance considerations
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Manage your risk appetite by customizing you risk
                        factors against the required risk categories, and
                        adjusting weights across the data points to determine
                        the risks relevant to your organization
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Bulk upload your current client groups and clients for a
                        smooth data migration
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Set periodic review dates and receive alerts when
                        deadlines are approaching
                      </div>
                    </div>
                    <div className="bullet-list-item">
                      <div className="bullet">
                        <div className="unicon"></div>
                      </div>
                      <div>
                        Download or directly email customer risk assessments and
                        periodic review forms directly as evidence for
                        regulators
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="pricing"
          className="section background-primary-soft wf-section"
        >
          <div className="container">
            <div className="pricing">
              <div
                data-w-id="6fdff87f-c3f4-a642-41fe-21e992ffafd8"
                className="headline-pricing"
              >
                <h3 className="label text-secondary">Pricing</h3>
                <h2 className="display-2" style={{ fontSize: 22 }}>
                  SynrgyRisk was designed as a{" "}
                  <span className="text-gradient text-emphasize">
                    cost-effective platform
                  </span>
                  , accessible to all sizes of business. Our fees are simple and
                  transparent. No hidden add-ons. Simply sign up to the package
                  best suited to your needs. And if your requirements exceed our
                  standard packages, we are happy to discuss a custom option.
                  Talk to us about white labelling today.
                </h2>
              </div>
              <div
                data-w-id="53334cf9-df90-50d4-4a1f-ea55c60f3cb3"
                className="prices-grid"
              >
                {subscriptions?.map?.((subscription) => (
                  <div
                    id="w-node-_53334cf9-df90-50d4-4a1f-ea55c60f3ce7-9c4ebe05"
                    className="pricing-card card shadow cc-larger"
                    key={subscription.key}
                  >
                    <div className="pricing-card-inner">
                      <h3 className="price">
                        <span className="price-currency">$</span>
                        {subscription.price}
                        <span className="price-duration">/year&amp;plan</span>
                      </h3>
                      <h4 className="margin-bottom-30">{subscription.title}</h4>
                      <div className="bullet-list margin-bottom-30">
                        <div className="bullet-list-item">
                          <div className="bullet">
                            <div className="unicon"></div>
                          </div>
                          <div>
                            <span className="bold-text">
                              {subscription.limit}
                            </span>{" "}
                            assessments
                          </div>
                        </div>
                      </div>
                      <Link
                        to="/auth/register"
                        className="button subbutton w-button"
                      >
                        Subscribe Now
                      </Link>
                    </div>
                  </div>
                ))}

                <div
                  id="w-node-_42f2fc2c-4869-fde7-ee97-ac54a00d1e19-9c4ebe05"
                  className="pricing-card card shadow"
                >
                  <div className="pricing-card-inner">
                    <h3 className="price">Custom</h3>
                    <div className="bullet-list margin-bottom-30">
                      <div className="bullet-list-item">
                        <div className="bullet">
                          <div className="unicon"></div>
                        </div>
                        <div className="text-block-9">
                          <span className="bold-text">Contact us</span>{" "}
                          <span>for a Quote</span>
                        </div>
                      </div>
                    </div>
                    <Link to="/contact" className="button subbutton w-button">
                      Contact us
                    </Link>
                  </div>
                </div>
              </div>

              <h2 className="display-2" style={{ fontSize: 22 }}>
                We also offer data migration and remediation support to get you
                seamlessly onboarded to the platform. Contact us to find about
                more about this service.
              </h2>

              <div
                data-w-id="d0f6ad60-6b32-56f5-bb7b-d2285ef547bc"
                style={{
                  WebkitTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0, 20%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                className="faq-wrapper"
              >
                {FAQs?.map?.((faq) => (
                  <div
                    id="w-node-d0f6ad60-6b32-56f5-bb7b-d2285ef547cd-9c4ebe05"
                    className="faq-item"
                    key={faq.key}
                  >
                    <div className="faq-item-inner">
                      <div className="faq-icon">
                        <div className="unicon"></div>
                      </div>
                      <div className="faq">
                        <h3>
                          <strong className="bold-text-9">
                            {faq.question}
                          </strong>
                        </h3>
                        <div className="text-block-7">{faq.answer}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <div className="footer background-dark">
          <div className="container">
            <div className="footer-cta">
              <div className="join">
                <h2 className="display-3 text-white">
                  Transform the way you conduct risk assessments today.
                </h2>
                <p>
                  If you want an informal discussion and demo to find out more
                  about how SynrgyRisk can help, please get in touch
                </p>
              </div>
              <div className="div-block">
                <Link to="/contact" className="button footerbutton w-button">
                  Get in touch
                </Link>
              </div>
            </div>
            <div className="divider" />
            <div className="footer-grid">
              <div>
                <img
                  src="/images/Logo_stacked_white.png"
                  loading="lazy"
                  height
                  alt=""
                  width="119.5"
                  srcSet="/images/Logo_stacked_white-p-500.png 500w, images/Logo_stacked_white-p-800.png 800w, images/Logo_stacked_white-p-1080.png 1080w, images/Logo_stacked_white-p-1600.png 1600w, images/Logo_stacked_white.png 1877w"
                  sizes="(max-width: 479px) 59vw, 119.49653625488281px"
                  className="logo margin-bottom-20"
                />
                <div className="margin-bottom-20">
                  © {new Date().getFullYear()} Synrgy Solutions.
                </div>
                <div className="social-links-wrapper">
                  <ul role="list" className="social-links w-list-unstyled">
                    <li className="social-link-item">
                      <a
                        href="https://www.linkedin.com/company/synrgyaml/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-link w-inline-block"
                      >
                        <div className="unicon"></div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbcf-cd8efba7">
                <div className="address margin-bottom-20">Get In Touch</div>
                <a
                  href="mailto:info@synrgy.solutions"
                  className="link-hover link-white"
                >
                  <strong>info@synrgy.solutions</strong>
                </a>
              </div>
              <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbd8-cd8efba7">
                <ul role="list" className="unordered-list margin-bottom-none">
                  <li>
                    <a href="#process" className="link-hover link-white">
                      About
                    </a>
                  </li>
                  <li>
                    <Link to="/legal/privacy" className="link-hover link-white">
                      Legals
                    </Link>
                  </li>
                  <li>
                    <a href="#howitworks" className="link-hover link-white">
                      How it works
                    </a>
                  </li>
                  <li>
                    <a href="#pricing" className="link-hover link-white">
                      Pricing
                    </a>
                  </li>
                  <li>
                    <Link to="/contact" className="link-hover link-white">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth/login" className="link-hover link-white">
                      Platform
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
