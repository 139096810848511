import {
  Alert,
  Button,
  FloatButton,
  Popconfirm,
  Select,
  Skeleton,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { v4 as uuid4 } from "uuid";
import RiskAssessment from "../../../service/RiskAssessment";
import User from "../../../service/User";
import { getScoreTextSelected } from "../CountryRiskScores";
import ExportButton from "./ExportButton";
const { Option } = Select;

const scoreOptions = [1, 3, 5, 10];

const App = () => {
  const [scores, setScores] = useState();
  const [me, setMe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shouldsave, setshouldsave] = useState(false);

  useEffect(() => {
    User.me().then((data) => setMe(data.data));
  }, []);

  const scoreLoad = async () => {
    setLoading(true);
    const { data } = await RiskAssessment.getRiskFactorScores();
    setLoading(false);
    setScores(data);
  };
  const Save = async () => {
    if (shouldsave) {
      await RiskAssessment.changeRiskFactorScores(scores);
    }
  };
  const handleScoreChange = useCallback((key, value, setTableData, path) => {
    setTableData((prevData) => ({
      ...prevData,
      [path]: {
        ...prevData[path],
        [key]: { ...prevData[path][key], score: value },
      },
    }));
    setshouldsave(true);
  }, []);

  const handleCommentChange = useCallback((key, value, setTableData, path) => {
    setTableData((prevData) => ({
      ...prevData,
      [path]: {
        ...prevData[path],
        [key]: { ...prevData[path][key], comments: value },
      },
    }));
    setshouldsave(true);
  }, []);

  const handleFieldChange = useCallback((key, value, setTableData, path) => {
    setTableData((prev) => ({
      ...prev,
      [path]: {
        ...prev[path],
        [key]: { ...prev[path][key], title: value },
      },
    }));
    setshouldsave(true);
  }, []);
  const handleProhibitChange = useCallback((key, value, setTableData, path) => {
    setTableData((prev) => ({
      ...prev,
      [path]: {
        ...prev[path],
        [key]: { ...prev[path][key], prohibited: value },
      },
    }));
    setshouldsave(true);
  }, []);

  const handleRemoveField = useCallback((setTableData, key, path) => {
    setTableData((prevData) => {
      const prev = JSON.parse(
        JSON.stringify({
          ...prevData,
        })
      );
      delete prev[path][key];
      return prev;
    });
    setshouldsave(true);
  }, []);

  const handleAddField = useCallback((setTableData, path) => {
    setTableData((prevData) => ({
      ...prevData,
      [path]: {
        ...prevData[path],
        [uuid4()]: { title: "", score: 1 },
      },
    }));
    setshouldsave(true);
  }, []);

  const columns = useCallback(
    (setFunction, path) => [
      {
        title: "Inherent Risk",
        dataIndex: "title",
        key: "title",
        render: (_, record) => (
          <Typography.Paragraph
            editable={{
              onChange: (e) => {
                handleFieldChange(record.key, e, setFunction, path);
              },
            }}
          >
            {record.title}
          </Typography.Paragraph>
        ),
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: "Inherent Risk Scores",
        dataIndex: "score",
        key: "score",
        render: (_, record) => (
          <Select
            value={record.score}
            onChange={(value) =>
              handleScoreChange(record.key, value, setFunction, path)
            }
          >
            {scoreOptions.map((score) => (
              <Option key={score} value={score}>
                {score}
              </Option>
            ))}
          </Select>
        ),
        sorter: (a, b) => a.score - b.score,
      },
      {
        title: "Risk Rating",
        dataIndex: "riskFactor",
        key: "riskFactor",
        render: (_, record) => getScoreTextSelected(record.score),
      },
      {
        title: "Prohibited",
        dataIndex: "prohibited",
        key: "prohibited",
        render: (_, record) => (
          <Switch
            checked={record.prohibited}
            onChange={(value) =>
              handleProhibitChange(record.key, value, setFunction, path)
            }
          />
        ),
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        render: (_, record) => (
          <Typography.Paragraph
            editable={{
              onChange: (e) => {
                handleCommentChange(record.key, e, setFunction, path);
              },
            }}
          >
            {record.comments}
          </Typography.Paragraph>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_, record) => (
          <Button
            onClick={() => handleRemoveField(setFunction, record.key, path)}
            danger
          >
            Delete
          </Button>
        ),
      },
    ],
    [
      handleFieldChange,
      handleScoreChange,
      handleRemoveField,
      handleProhibitChange,
    ]
  );

  useEffect(() => {
    scoreLoad();
  }, []);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div>
      <div className="my-10">
        <ExportButton />

        <h3>Inherent Risk Score</h3>
        <Alert
          type="info"
          message="Set the scores for your organization below."
        />

        {me && me?.role !== "team_member" && (
          <div className="mt-2">
            <Popconfirm
              title="All scores will be reset to system default. Are you sure?"
              onConfirm={async () => {
                await RiskAssessment.resetInherentRiskScores();
                await scoreLoad();
              }}
            >
              <Button type="primary">
                Click here to reset to system default
              </Button>
            </Popconfirm>
          </div>
        )}

        <div className="my-10">
          <h5 className="">Products / Services / Transactions</h5>
          <Table
            columns={columns(setScores, "EntityProductServiceOffered")}
            dataSource={Object.keys(
              scores?.EntityProductServiceOffered || {}
            ).map((e, i) => ({
              ...scores.EntityProductServiceOffered?.[e],
              key: e,
            }))}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex items-center gap-3">
                <Button
                  type="primary"
                  onClick={() =>
                    handleAddField(setScores, "EntityProductServiceOffered")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>

        <div className="my-10">
          <h5 className="">Client Characteristics</h5>
          <Table
            columns={columns(setScores, "ClientCharacteristics")}
            dataSource={Object.keys(scores?.ClientCharacteristics || {}).map(
              (e, i) => ({
                ...scores.ClientCharacteristics[e],
                key: e,
              })
            )}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex items-center gap-3">
                <Button
                  type="primary"
                  onClick={() =>
                    handleAddField(setScores, "ClientCharacteristics")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>

        <div className="my-10">
          <h5 className="">Delivery Channel</h5>
          <Table
            columns={columns(setScores, "DeliveryChannelsRisk")}
            dataSource={Object.keys(scores?.DeliveryChannelsRisk || {}).map(
              (e, i) => ({
                ...scores.DeliveryChannelsRisk[e],
                key: e,
              })
            )}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex items-center gap-3">
                <Button
                  type="primary"
                  onClick={() =>
                    handleAddField(setScores, "DeliveryChannelsRisk")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>

        <div className="my-10">
          <h5 className="">Source of Funds / Transactions</h5>
          <Table
            columns={columns(setScores, "SourceofFundsTransaction")}
            dataSource={Object.keys(scores?.SourceofFundsTransaction || {}).map(
              (e, i) => ({
                ...scores.SourceofFundsTransaction[e],
                key: e,
              })
            )}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex items-center gap-3">
                <Button
                  type="primary"
                  onClick={() =>
                    handleAddField(setScores, "SourceofFundsTransaction")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>
        {shouldsave && (
          <div className="bg-gray-100 px-5 py-2 ">
            <FloatButton
              shape="square"
              type="primary"
              style={{ right: 24 }}
              icon={<AiOutlineSave />}
              description="Save"
              onClick={Save}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
