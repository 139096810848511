import React, { useCallback, useEffect, useState } from "react";
import Public from "../../service/Public";

const OtherLegal = () => {
  const [otherLegal, setOtherLegal] = useState(localStorage.otherLegal ?? null);

  const loadData = useCallback(async () => {
    const data = await Public.getOtherLegal();
    if (data.data.otherLegal) {
      setOtherLegal(data.data.otherLegal);
      localStorage.setItem("otherLegal", data.data.otherLegal);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <h1>Other Legal Information</h1>
      <div dangerouslySetInnerHTML={{ __html: otherLegal }} />
    </>
  );
};

export default OtherLegal;
