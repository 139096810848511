import { Alert, Button } from "antd";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";
import User from "../../../service/User";
import { uploadToCloudinary } from "../ContentManagement/LandingPage";

const Support = () => {
  const loading = useSelector(selectLoading);
  const [file, setFile] = useState(null);

  const handleSubmit = useCallback(
    async (e) => {
      if (loading) return;
      e.preventDefault();
      const type = e.target[0].value;
      const message = e.target[1].value;

      await User.createSupportTicket({
        type,
        message: `${message} Url: ${file}`,
      });

      e.target[1].value = "";
    },
    [loading, file]
  );

  return (
    <>
      <form action="#" className="space-y-8" onSubmit={handleSubmit}>
        <div>
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Type
          </label>
          <select defaultValue={"Bug Report"}>
            <option value="Bug Report">Bug Report</option>
            <option value="Technical Support">Technical Support</option>
            <option value="Account Issue">Account Issue</option>
            <option value="Feedback and Suggestions">
              Feedback and Suggestions
            </option>
            <option value="General Inquiry">General Inquiry</option>
          </select>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
          >
            Your message
          </label>
          <textarea
            id="message"
            rows={6}
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
            placeholder="Let us know how we may help you"
            defaultValue={""}
          />
        </div>

        <div>
          <label htmlFor="file-img">File</label>
          <input
            type="file"
            id="file-img"
            onChange={async (e) => {
              const file = e.target.files[0];
              debugger;
              const res = await uploadToCloudinary(file);
              debugger;
              setFile(res.secure_url);
            }}
          />
        </div>

        <Button htmlType="submit" type="primary">
          Send Message
        </Button>

        <Alert
          type="info"
          message="We aim to address all critical issues within 7 days."
        />
      </form>
    </>
  );
};

export default Support;
