import { Divider } from "antd";
import React from "react";
import FAQs from "./FAQs";
import HeroImage from "./HeroImage";
import Pricing from "./Pricing";

const cloudinaryConfig = {
  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
  api_secret: process.env.REACT_APP_CLOUDINARY_API_SECRET,
  upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
};

export async function uploadToCloudinary(file) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", cloudinaryConfig.upload_preset);

  try {
    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/auto/upload`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Video upload failed:", response.statusText);
    }
  } catch (error) {
    console.error("Video upload error:", error);
  }
}

const LandingPage = () => {
  return (
    <div>
      <HeroImage />
      <Divider />

      <Pricing />
      <Divider />

      <FAQs />
    </div>
  );
};

export default LandingPage;
