import * as XLSX from "xlsx";
const handleXLSXTOJSON = async ({ sheet }, callback) => {
  const XLSXTOJSON = new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const jsonResult = jsonData.map((row) =>
        row.reduce((acc, cell, index) => {
          const header = jsonData[0][index];
          if (header) {
            acc[header] = cell;
          }
          return acc;
        }, {})
      );
      resolve(jsonResult);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(sheet);
  });
  const json = await XLSXTOJSON;
  callback(json);
};
export { handleXLSXTOJSON };
