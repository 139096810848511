import { Select } from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MultiLogin from "../../service/MultiLogin";

const MultiLoginSelector = () => {
  const [loginOptions, setLoginOptions] = useState([]);
  const [currentUserId, setCurrentUserId] = useState(null);
  const location = useLocation();

  useEffect(() => {
    MultiLogin.getOptions().then(({ data }) => {
      setLoginOptions(
        data.map((option) => ({
          value: option._id,
          label: option.companyName,
        }))
      );
    });
  }, [location]);

  useEffect(() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return;
    const decoded = jwt_decode(accessToken);

    setCurrentUserId(decoded.userId);
  }, []);

  const handleChange = async (e) => {
    const result = await MultiLogin.switchLogin({
      id: e,
    });

    if (!result?.data?.accessToken) return;

    Cookies.set("accessToken", result?.data?.accessToken);
    Cookies.set("refreshToken", result?.data?.refreshToken);

    window.location.reload();
  };

  if (loginOptions.length < 2) return <></>;
  return (
    <Select
      style={{ width: 120 }}
      options={loginOptions}
      value={currentUserId}
      onChange={handleChange}
    />
  );
};

export default MultiLoginSelector;
