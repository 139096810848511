import { Button, Typography } from "antd";
import React, { useState } from "react";
import RiskAssessmentE from "../../../service/RiskAssessment";
import User from "../../../service/User";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { WeighingtableData } from "../RiskAssessmentManagement/data";
import * as XLSX from "xlsx";

const ExportButton = () => {
  const columns = [
    {
      title: "Inherent Risk",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Inherent Risk Scores",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Risk Factor",
      dataIndex: "riskFactor",
      key: "riskFactor",
    },
  ];

  const exportToExcel = async () => {
    try {
      const { data: countryData } =
        await RiskAssessmentE.getCountryRiskScores();
      const { data: weightingData } = await RiskAssessmentE.getWeightingTable();
      const { data: scores } = await RiskAssessmentE.getRiskFactorScores();

      // Convert object data into arrays of objects
      const countryDataArray = Object.keys(countryData).map((key) => ({
        Country: countryData[key]?.title ?? key,
        Score: countryData[key]?.score ?? 0,
        Comment: countryData[key]?.comment ?? "",
      }));

      const weightingDataArray = Object.keys(weightingData).map((key) => ({
        Field: key,
        Weight: weightingData[key],
      }));

      const scoreDataArray = Object.keys(
        scores?.EntityProductServiceOffered || {}
      ).map((key) => ({
        InherentRisk: scores?.EntityProductServiceOffered[key]?.title ?? "",
        InherentRiskScore: scores?.EntityProductServiceOffered[key]?.score ?? 0,
      }));

      const scoreDataArrayClient = Object.keys(
        scores?.ClientCharacteristics || {}
      ).map((key) => ({
        InherentRisk: scores?.ClientCharacteristics[key]?.title ?? "",
        InherentRiskScore: scores?.ClientCharacteristics[key]?.score ?? 0,
      }));

      const scoreDataArrayDelivery = Object.keys(
        scores?.DeliveryChannelsRisk || {}
      ).map((key) => ({
        InherentRisk: scores?.DeliveryChannelsRisk[key]?.title ?? "",
        InherentRiskScore: scores?.DeliveryChannelsRisk[key]?.score ?? 0,
      }));

      const scoreArrayFundsTransactions = Object.keys(
        scores?.SourceofFundsTransaction || {}
      ).map((key) => ({
        InherentRisk: scores?.SourceofFundsTransaction[key]?.title ?? "",
        InherentRiskScore: scores?.SourceofFundsTransaction[key]?.score ?? 0,
      }));
      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert data to worksheet format
      const worksheet = XLSX.utils.json_to_sheet(countryDataArray);
      XLSX.utils.book_append_sheet(wb, worksheet, "Country Risk Scores");

      const worksheet2 = XLSX.utils.json_to_sheet(weightingDataArray);
      XLSX.utils.book_append_sheet(wb, worksheet2, "Weighting Table");

      const worksheet3 = XLSX.utils.json_to_sheet(scoreDataArray);
      XLSX.utils.book_append_sheet(
        wb,
        worksheet3,
        "Products Services Transaction"
      );

      const worksheet4 = XLSX.utils.json_to_sheet(scoreDataArrayClient);
      XLSX.utils.book_append_sheet(wb, worksheet4, "Client  Characteristics");

      const worksheet5 = XLSX.utils.json_to_sheet(scoreDataArrayDelivery);
      XLSX.utils.book_append_sheet(wb, worksheet5, "Delivery Channel");

      const worksheet6 = XLSX.utils.json_to_sheet(scoreArrayFundsTransactions);
      XLSX.utils.book_append_sheet(
        wb,
        worksheet6,
        "Source of Funds Transactions"
      );
      // Generate Excel file and download it

      XLSX.writeFile(wb, "export.xlsx");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  return (
    <>
      <Button
        className="mb-5"
        type="primary"
        onClick={async () => {
          const { data: countryData } =
            await RiskAssessmentE.getCountryRiskScores();
          const { data: weightingData } =
            await RiskAssessmentE.getWeightingTable();
          const { data: scores } = await RiskAssessmentE.getRiskFactorScores();

          handleGeneratePDF({
            docDef: {
              content: [
                { text: "Products / Services / Transactions", style: "header" },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      columns
                        .filter(
                          (e) => !["action", "riskFactor"].includes(e.dataIndex)
                        )
                        .map((column) => column.title),
                      ...Object.keys(scores?.EntityProductServiceOffered || {})
                        .map((e, i) => ({
                          ...scores.EntityProductServiceOffered?.[e],
                          key: e,
                        }))
                        .map((client) =>
                          columns
                            .filter(
                              (e) =>
                                !["action", "riskFactor"].includes(e.dataIndex)
                            )
                            .map((column) => client[column.dataIndex] || "")
                        ),
                    ],
                  },
                  style: "table",
                },

                { text: "Client Characteristics", style: "header" },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      columns
                        .filter(
                          (e) => !["action", "riskFactor"].includes(e.dataIndex)
                        )
                        .map((column) => column.title),
                      ...Object.keys(scores?.ClientCharacteristics || {})
                        .map((e, i) => ({
                          ...scores.ClientCharacteristics[e],
                          key: e,
                        }))
                        .map((client) =>
                          columns
                            .filter(
                              (e) =>
                                !["action", "riskFactor"].includes(e.dataIndex)
                            )
                            .map((column) => client[column.dataIndex] || "")
                        ),
                    ],
                  },
                  style: "table",
                },

                { text: "Delivery Channel", style: "header" },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      columns
                        .filter(
                          (e) => !["action", "riskFactor"].includes(e.dataIndex)
                        )
                        .map((column) => column.title),
                      ...Object.keys(scores?.DeliveryChannelsRisk || {})
                        .map((e, i) => ({
                          ...scores.DeliveryChannelsRisk[e],
                          key: e,
                        }))
                        .map((client) =>
                          columns
                            .filter(
                              (e) =>
                                !["action", "riskFactor"].includes(e.dataIndex)
                            )
                            .map((column) => client[column.dataIndex] || "")
                        ),
                    ],
                  },
                  style: "table",
                },

                { text: "Source of Funds / Transactions", style: "header" },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      columns
                        .filter(
                          (e) => !["action", "riskFactor"].includes(e.dataIndex)
                        )
                        .map((column) => column.title),
                      ...Object.keys(scores?.SourceofFundsTransaction || {})
                        .map((e, i) => ({
                          ...scores.SourceofFundsTransaction[e],
                          key: e,
                        }))
                        .map((client) =>
                          columns
                            .filter(
                              (e) =>
                                !["action", "riskFactor"].includes(e.dataIndex)
                            )
                            .map((column) => client[column.dataIndex] || "")
                        ),
                    ],
                  },
                  style: "table",
                },

                { text: "Country Risk Scores", style: "header" },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ["Country", "Score", "Comment"],
                      ...Object.keys(countryData || {})
                        .filter((a) => !["__v", "_id"].includes(a))
                        .map((e, i) => [
                          countryData[e]?.title ?? e,
                          countryData[e]?.score ?? 0,
                          countryData[e]?.comment ?? "",
                        ]),
                    ],
                  },
                  style: "table",
                },

                { text: "Weighting Table", style: "header" },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ["Field", "Weight"],
                      ...Object.keys(weightingData || {})
                        .filter(
                          (a) =>
                            !a?.includes?.("Data") &&
                            !["__v", "_id"].includes(a)
                        )
                        .map((e, i) => [
                          WeighingtableData[e] ?? e,
                          weightingData[e],
                        ]),
                    ],
                  },
                  style: "table",
                },
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                table: {
                  fontSize: 10,
                },
              },
            },
          });
        }}
      >
        Export PDF
      </Button>

      <Button className="mb-5 ml-5" type="primary" onClick={exportToExcel}>
        Export Excel
      </Button>
    </>
  );
};

export default ExportButton;
