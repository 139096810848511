import {
  Alert,
  Button,
  Checkbox,
  Descriptions,
  Divider,
  Input,
  Modal,
  Select,
  Skeleton,
  Space,
  Typography,
  message,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectLoading } from "../../../redux/auth/selectors";
import Public from "../../../service/Public";
import User from "../../../service/User";
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from "./utils";

const Plan = () => {
  const loading = useSelector(selectLoading);
  const [limit, setLimit] = useState(null);
  const [user, setUser] = useState(null);
  const [currentLimit, setCurrentLimit] = useState(0);
  const [currentScreening, setCurrentScreening] = useState(false);
  const [includeScreening, setIncludeScreening] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [optionToActivateScreening, setOptionToActivateScreening] =
    useState(false);
  const [adminLimit, setAdminLimit] = useState(0);
  const [cardCvv, setCardCvv] = useState("");
  const [cardExp, setCardExp] = useState("");
  const [focus, setFocus] = useState("");
  const [cardNr, setCardNr] = useState("");
  const [cardName, setCardName] = useState("");
  const [confirmation, setConfirmation] = useState(null);
  const [screeningPrice, setScreeningPrice] = useState(
    localStorage?.screeningPrice ?? 0
  );

  const [subscriptions, setSubscriptions] = useState(
    localStorage.subscriptions ?? null
  );
  const loadDataPricing = useCallback(async () => {
    const data = await Public.getSubscriptionPackages();
    if (data.data.subscriptions) {
      setSubscriptions(data.data.subscriptions);
      localStorage.setItem("subscriptions", data.data.subscriptions);
      if (data.data.subscriptions?.[0])
        setLimit(data.data.subscriptions[0].key);
    }
  }, []);
  useEffect(() => {
    loadDataPricing();
  }, [loadDataPricing]);

  useEffect(() => {
    Public.getPricing().then((data) => {
      setScreeningPrice(data.data.screeningPrice);
      localStorage.screeningPrice = data.data.screeningPrice;
    });
  }, []);

  const fetchLimit = async () => {
    try {
      User.getLimit().then(({ data }) => {
        setCurrentLimit(data.limit);
        setAdminLimit(data.adminLimit);
        setCurrentScreening(data.screening);
      });
      const { data } = await User.me();
      setUser(data);
    } catch (error) {
      console.log("Error fetching limit:", error);
    }
  };

  useEffect(() => {
    fetchLimit();
  }, []);
  const handleLimitBuy = useCallback(
    async (test, screening) => {
      if (!acceptTerms)
        return message.error(
          "Please confirm that you have read and accept our terms of service"
        );
      const res = await User.handleSubscribe({
        selectedSubscription: limit,
        cardCvv,
        cardExp,
        cardNr,
        cardName,
        test,
        screening: includeScreening,
      });
      if (test) setConfirmation(res.data.result);
      else setConfirmation(false);
      if (test)
        setOptionToActivateScreening(res.data.optionToActivateScreening);
      else setOptionToActivateScreening(false);

      await fetchLimit();
    },
    [limit, cardCvv, cardExp, cardNr, cardName, includeScreening, acceptTerms]
  );

  const handleScreeningBuy = useCallback(
    async (test) => {
      if (!acceptTerms)
        return message.error(
          "Please confirm that you have read and accept our terms of service"
        );
      const res = await User.handleActivateScreening({
        cardCvv,
        cardExp,
        cardNr,
        cardName,
        test,
      });
      if (test) setConfirmation(res.data.result);
      else setConfirmation(false);

      await fetchLimit();
    },
    [cardCvv, cardExp, cardNr, cardName, acceptTerms]
  );

  if (!user) {
    return <Skeleton active />;
  }

  const handleInputFocus = ({ target }) => setFocus(target.name);

  const handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      setCardNr(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      setCardExp(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      setCardCvv(target.value);
    } else if (target.name === "name") {
      target.value = target.value;
      setCardName(target.value);
    }
  };

  if (adminLimit)
    return (
      <Alert
        type="info"
        message={
          <>
            Your account license limit is managed by Synrgy Solutions. Your
            current limit is {currentLimit}. Your screening license is{" "}
            {currentScreening ? "activated" : "deactivated"}. Contact{" "}
            <a href="mailto:info@synrgy.solutions">info@synrgy.solutions</a> to
            increase your limit.
          </>
        }
      />
    );
  return (
    <div className="">
      <div className="mb-10">
        <h3 className="font-semibold mb-4">Subscription</h3>
        {adminLimit === 0 && currentLimit === 0 && (
          <>
            <Alert
              type="info"
              message={
                <>
                  To gain access to our dynamic risk assessment tool you will
                  need to purchase an annual subscription plan based on the size
                  of your client base. Alternatively please contact{" "}
                  <a href="mailto:info@synrgy.solutions">
                    info@synrgy.solutions
                  </a>{" "}
                  to pay by wire.
                </>
              }
            />
          </>
        )}
        <Descriptions
          layout="horizontal"
          colon={false}
          bordered
          column={{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }}
          className="mt-10"
        >
          <Descriptions.Item
            label="Current License Limit"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text>{currentLimit}</Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item
            label="Current Screening"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text>
              {currentScreening ? "Activated" : "Deactivated"}
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item
            label="License Expiry Date"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text className="capitalize">
              {moment(user.subscription.nextRenewalDate).format(
                "YYYY-MM-DD HH:mm"
              )}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>

        <Divider />

        <Cards
          cvc={cardCvv}
          expiry={cardExp}
          focused={focus}
          number={cardNr}
          name={cardName}
        />

        <form>
          <div className="form-group">
            <small>Name on card:</small>

            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Name"
              pattern="[a-z A-Z-]+"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
          <div className="form-group">
            <small>Card Number:</small>

            <input
              type="tel"
              name="number"
              className="form-control"
              placeholder="Card Number"
              pattern="[\d| ]{16,22}"
              maxLength="19"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>

          <div className="form-group">
            <small>Expiration Date:</small>

            <input
              type="tel"
              name="expiry"
              className="form-control"
              placeholder="Valid Thru"
              pattern="\d\d/\d\d"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
          <div className="form-group">
            <small>CVC:</small>

            <input
              type="tel"
              name="cvc"
              className="form-control"
              placeholder="CVC"
              pattern="\d{4}|\d{3}"
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
        </form>

        <Divider />
        <Checkbox
          label="F"
          checked={acceptTerms}
          onChange={(e) => setAcceptTerms(e.target.checked)}
        >
          I accept the{" "}
          <a href="/legal/terms" target="_blank" className="text-blue-500">
            Terms of Service
          </a>
        </Checkbox>

        <Divider />

        <h3 className="!text-base !mt-10">Buy License</h3>
        <div className="flex items-center gap-3">
          <Select
            value={limit}
            onChange={(e) => setLimit(e)}
            style={{ width: 250 }}
          >
            {subscriptions?.map?.((subscription) => (
              <Select.Option value={subscription.key} key={subscription.key}>
                {subscription.title}: {subscription.limit} RAs - $
                {subscription.price}
              </Select.Option>
            ))}
          </Select>
          <Button
            type="primary"
            loading={loading}
            onClick={() => handleLimitBuy(true)}
          >
            Buy
          </Button>
        </div>
        <p className="label-p">
          By making a purchase, you agree to our{" "}
          <Link
            to="/legal/terms"
            className="link"
            style={{ display: "inline-block" }}
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            to="/legal/privacy"
            className="link"
            style={{ display: "inline-block" }}
          >
            Privacy&nbsp;Statement
          </Link>
        </p>

        <h3 className="!text-base !mt-10">Buy Screening</h3>
        <div className="flex items-center gap-3">
          <Button
            type="primary"
            loading={loading}
            onClick={() => handleScreeningBuy(true)}
          >
            Buy (${screeningPrice})
          </Button>
        </div>
        <p className="label-p">
          Screen against global sanctions and watchlists including, HMT, OFAC,
          EU and UN to name a few.
        </p>
        <p className="label-p">
          By making a purchase, you agree to our{" "}
          <Link
            to="/legal/terms"
            className="link"
            style={{ display: "inline-block" }}
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            to="/legal/privacy"
            className="link"
            style={{ display: "inline-block" }}
          >
            Privacy&nbsp;Statement
          </Link>
        </p>
      </div>

      <Modal
        open={confirmation}
        destroyOnClose
        onCancel={() => setConfirmation(null)}
        onOk={() => setConfirmation(null)}
        footer={[]}
      >
        <Alert type="success" message={confirmation} />
        <div>
          {!confirmation?.includes?.("annual screening license") &&
            optionToActivateScreening && (
              <Space className="mt-2">
                <Checkbox
                  value={includeScreening}
                  onChange={(e) => setIncludeScreening(e.target.checked)}
                />
                <div>Include screening</div>
              </Space>
            )}
        </div>
        <Button
          type="primary"
          loading={loading}
          onClick={() =>
            confirmation.includes("annual screening license")
              ? handleScreeningBuy(false)
              : handleLimitBuy(false)
          }
          className="mt-2"
        >
          Confirm Purchase
        </Button>
      </Modal>
    </div>
  );
};

export default Plan;
