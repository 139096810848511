import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./style/css/normalize.css";
import "./style/css/webflow.css";
import "./style/index.scss";

import { ConfigProvider } from "antd";
import AppRoutes from "./AppRoutes";
import LoadingIndicator from "./LoadingIndicator";
import { setLoading } from "./redux/auth/actions";
import { persistor, store } from "./redux/store";

if (process.env.NODE_ENV === "development") {
  // Hide development error overlay of react
  const style = document.createElement("style");
  style.type = "text/css";
  style.innerHTML = "body > iframe { display: none; }";
  document.head.appendChild(style);
}

const App = () => {
  useEffect(() => {
    store.dispatch(setLoading(false));
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider theme={{ token: { colorPrimary: "#294895" } }}>
          <Router>
            <AppRoutes />
          </Router>
          <LoadingIndicator />
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
