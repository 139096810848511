const clientCharacteristics = [
  {
    name: "Client Subject to Regulatory Oversight",
    key: "Clientsubjecttosupervision",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Retail Client / Individual",
    show: false,
    key: "Clientunregulated",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Operational Client",
    show: false,
    key: "ClientOperational",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Adverse Information Identified",
    show: false,
    key: "Clienthasadverseinformationsanctionsconnection",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Sanctions Connection Identified",
    show: false,
    key: "ClientHasSanctionsConnectionIdentified",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Acting on behalf of Third Party",
    show: false,
    key: "Clientisactingonbehalfofathirdparty",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Related to Cash Intensive or High Risk Sectors",
    show: false,
    key: "Clientbusinesscashintensiveorhighriskindustry",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Unusual or Complex Structure or Request",
    show: false,
    key: "Clienthasunusualorcomplexstructure",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "High Net Worth",
    show: false,
    key: "HNWUHNWClient",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Nominee Relationship",
    show: false,
    key: "NomineerelationshiporEligibleIntroducer",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Eligible Introducer Relationship",
    show: false,
    key: "eligibleIntroducerRelationship",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Incomplete or Inconsistent KYC",
    show: false,
    key: "ClientwithincompleteKYC",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "PEP or RCA",
    show: false,
    key: "PEPorAssociated",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "State or Government Owned",
    show: false,
    key: "InstitutionalClient",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Not for Profit, Foundation, Trust",
    show: false,
    key: "notForProfitFoundationTrust",
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Other",
    show: false,
    key: "Other",
    selection: "NA",
    initialScore: [],
    isMulti: true,
    factor: [],
  },
];
const deliveryChannelFactors = [
  {
    name: "Client Referred by Regulated or Known Intermediary or Agent",
    key: "Clientreferredbyreputablecounselintermediary",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Referred by Unknown Intermediary or Agent",
    key: "clientReferredByUnknownIntermediaryOrAgent",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Solicited",
    key: "ClientsolicitedorOwnClient",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Unsolicited",
    key: "Unsolicitednonfacetoface",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Face to Face",
    key: "ClientfacetofaceorKnownbutnonfacetoface",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Non-Face to Face",
    key: "ClientNonFaceToFace",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Known but Non-Face to Face",
    key: "ClientKnownButNonFaceToFace",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Client Through Platform",
    key: "clientThroughPlatform",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Own / Internal Client",
    key: "OwnInternalClient",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Through Video Conferencing",
    key: "throughVideoConferencing",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Other",
    key: "Other",
    show: false,
    selection: "NA",
    initialScore: [],
    isMulti: true,
    factor: [],
  },
];

const jurisdictionTouchpointsFactors = [
  {
    name: "Jurisdiction Client Resides / Operates",
    key: "JurisdictionClientResidesOperates",
    show: false,
    initialScore: 0,
    country: "",
  },
  {
    name: "Client Place of Birth / Incorporation",
    key: "ClientPlaceofBirthIncorporation",
    show: false,
    initialScore: 0,
    country: "",
  },
  {
    name: "Client Nationalities",
    key: "ClientNationalityIndividual",
    show: false,
    initialScore: 0,
    country: "",
  },
  {
    name: "Third Party Jurisdiction",
    key: "ThirdPartyJurisdiction",
    show: false,
    initialScore: "",
    country: "",
  },
  {
    name: "Other Third Party Countries",
    key: "OtherThirdPartyCountries",
    show: false,
    isMulti: true,
    initialScore: "",
    country: [],
  },
];

const sourceOfFundsFactors = [
  {
    name: "Funds or Assets Originate from Low to Med Risk Industry",
    key: "FundsAssetsOriginatefromLowRiskIndustry",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Funds or Assets Originate from High Risk Industry",
    key: "FundsAssetTransactionoriginatehighriskindustryproduct",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Source of Funds Identifiable",
    key: "Sourceidentifiable",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Source of Funds Unidentifiable",
    key: "SourceUnidentifiable",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Source of Funds from Regulated Institution or Exchange",
    key: "SourcefromRegulatedexchange",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Source of Funds from Unregulated Institution or Exchange",
    key: "Sourcefromunregulatedexchange",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Associated or Related Third Party Payment",
    key: "associatedOrRelatedThirdPartyPayment",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Unknown or Unassociated Third-party Payment",
    key: "Unknoworunassociatedthirdpartypayment",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Excess Payment",
    key: "Excesspaymentreceived",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Transaction Red Flag Identified (below reportable thresholds/depositing and immediate withdrawal/associated with illicit activity/unidentifiable etc.)",
    key: "Transactionredflag",
    show: false,
    selection: "NA",
    initialScore: "",
    factor: "",
  },
  {
    name: "Other",
    key: "Other",
    show: false,
    selection: "NA",
    initialScore: [],
    isMulti: true,
    factor: [],
  },
];
const EntityProductServiceOffered = [
  {
    name: "Select Products / Services / Transactions offered",
    key: "typeOfProductServiceOfferedToClient",
    show: true,
    selection: "NA",

    factor: [],
  },
];
export {
  EntityProductServiceOffered,
  clientCharacteristics,
  deliveryChannelFactors,
  jurisdictionTouchpointsFactors,
  sourceOfFundsFactors,
};
