import React, { useCallback, useEffect, useState } from "react";
import Public from "../../service/Public";

const Terms = () => {
  const [termsAndConditions, setPrivacyPolicy] = useState(
    localStorage.termsAndConditions ?? null
  );

  const loadData = useCallback(async () => {
    const data = await Public.getTerms();
    if (data.data.termsAndConditions) {
      setPrivacyPolicy(data.data.termsAndConditions);
      localStorage.setItem("termsAndConditions", data.data.termsAndConditions);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <h1>Terms of Service</h1>
      <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
    </>
  );
};

export default Terms;
