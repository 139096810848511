import { Button, InputNumber, Table, Typography, message } from "antd";
import React, { useEffect, useState } from "react";

import Admin from "../../../service/Admin";
import { WeighingtableData } from "./data";
const WeightingTable = () => {
  const [data, setData] = useState({});
  const [shouldsave, setshouldsave] = useState(false);
  const loadWeighingScore = async () => {
    const { data } = await Admin.getWeightingTable();

    setData(data.weightingTable);
  };

  useEffect(() => {
    loadWeighingScore();
  }, []);
  const Save = async () => {
    const total = Object.values(data).reduce((a, b) => a + b);

    if (Math.ceil(total * 100) === 100) {
      await Admin.changeWeightingTable({ weightingTable: data });
    } else {
      message.error("Total sum of weights must be equal to 100");
    }
  };
  const handleWeightChange = (value, key) => {
    setData((prevData) => ({ ...prevData, [key]: value }));
    setshouldsave(true);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Weight (%)",
      dataIndex: "key",
      key: "key",
      render: (text, record) => (
        <InputNumber
          min={0}
          max={100}
          step={1}
          value={(data[text] * 100).toFixed(0)}
          onChange={(value) => handleWeightChange(value / 100, text)}
        />
      ),
    },
  ];

  return (
    <>
      <h3>Weighting Table</h3>
      <Table
        columns={columns}
        dataSource={Object.keys(data).map((e) => {
          return {
            category: WeighingtableData[e],
            key: e,
          };
        })}
        pagination={false}
        footer={() =>
          shouldsave && (
            <Button type="primary" onClick={Save}>
              Save
            </Button>
          )
        }
      />
    </>
  );
};

export default WeightingTable;
