import { Segmented } from "antd";
import React, { useState } from "react";
import Copyright from "./Copyright";
import EmailTemplate from "./EmailTemplate";
import LandingPage from "./LandingPage";
import OtherLegal from "./OtherLegal";
import Privacy from "./Privacy";
import Terms from "./Terms";

const ContentManagement = () => {
  const [tab, setTab] = useState("Privacy");
  const Tabs = [
    { key: "Privacy", element: <Privacy /> },
    { key: "Terms", element: <Terms /> },
    { key: "Copyright", element: <Copyright /> },
    { key: "Other Legal Information", element: <OtherLegal /> },

    { key: "Landing Page", element: <LandingPage /> },

    { key: "Email Template", element: <EmailTemplate /> },
  ];
  return (
    <div>
      <h3 className="my-10">Content Management</h3>
      <Segmented
        className="my-5"
        options={[
          "Privacy",
          "Terms",
          "Copyright",
          "Other Legal Information",
          "Landing Page",
          "Email Template",
        ]}
        value={tab}
        onChange={(e) => setTab(e)}
      />
      {Tabs.find((e) => e.key === tab).element}
    </div>
  );
};

export default ContentManagement;
/*  <div className="my-10">
        <h4>Privacy Policy</h4>
        <HTMLTextEditor
          update={async (e) => await Admin.changePrivacy({ privacyPolicy: e })}
          quillattributes={{
            className: "relative h-[300px] max-w-[700px] flex flex-col gap-10",
          }}
        />
      </div>
      <div className="my-10">
        <h4>Terms and Conditions</h4>
        <HTMLTextEditor
          update={async (e) =>
            await Admin.changeTerms({ termsAndConditions: e })
          }
          quillattributes={{
            className: "relative h-[300px] max-w-[700px] flex flex-col gap-10",
          }}
        />
      </div>
      <div className="my-10">
        <h4>How it works</h4>
        <HTMLTextEditor
          update={async (e) => await Admin.changeHowItWorks({ howItWorks: e })}
          quillattributes={{
            className: "relative h-[300px] max-w-[700px] flex flex-col gap-10",
          }}
        />
      </div>
      <div className="my-10">
        <h4>About Us</h4>
        <HTMLTextEditor
          key={"aboutUs"}
          update={async (e) => {
            await Admin.changeAboutUs({ aboutUs: e });
          }}
          quillattributes={{
            className: "relative h-[300px] max-w-[700px] flex flex-col gap-10",
          }}
        />
      </div>*/
