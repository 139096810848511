import {
  Button,
  Dropdown,
  Input,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Switch,
  Table,
} from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SampeBulkAddGroupClients from "../../../assets/Samples/SampeBulkAddGroupClients.xlsx";
import { selectLoading } from "../../../redux/auth/selectors";
import Client from "../../../service/Client";
import { handleXLSXTOJSON } from "../../../utils/bulkUpload";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { exportToExcel } from "./clientByUser";

const ClientMain = () => {
  const [Clients, setClients] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [displayArchive, setDisplayArchive] = useState(false);
  const loading = useSelector(selectLoading);
  const { groupId } = useParams();

  const canEdit = (() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken);
    return decoded.hasFullClientAccess;
  })();

  const loadClients = useCallback(async () => {
    const response = await Client.getClientGroups({});
    setClients(
      response.data
        .map((t) => ({ ...t, key: t?._id }))
        .filter((t) => t.group === groupId)
    );
    setSelectedMember((selectedMember) =>
      selectedMember
        ? response.data.find((m) => m?._id === selectedMember?._id) ?? null
        : null
    );
  }, [groupId]);
  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const DropDownMenu = [
    {
      key: "export-excel",
      label: <Button onClick={() => exportToExcel(Clients)}>Excel</Button>,
    },
    {
      key: "export-pdf",
      label: (
        <Button
          onClick={() =>
            handleGeneratePDF({
              header: "Client Groups",
              ContentColumn: columns.filter((e) => e.title !== "Action"),
              DataSource: Clients,
            })
          }
        >
          PDF
        </Button>
      ),
    },
  ];

  const handleDelete = async (data, archived) => {
    await Client.deleteClientGroup({
      clientGroupId: data._id,
    }).then(() => loadClients());
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Description",
      dataIndex: "businessTagLine",
      key: "businessTagLine",
    },

    {
      title: "Review",
      key: "action",
      render: (text, record) => {
        return (
          <Space>
            {/* <Link to={`/app/clients/${text._id}`}>
              <Button type="primary">View</Button>
            </Link> */}
            <Link
              to={`/app/edit-client-group/${text._id}`}
              className="text-blue-900"
            >
              Client List
            </Link>
            {canEdit && (
              <Popconfirm
                title="Sure?"
                onConfirm={() => handleDelete(text, !record.archived)}
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const [open, setOpen] = useState(false);
  const [EditData, setEditData] = useState({
    name: "",
    businessTagLine: "",
  });
  const [AddData, setAddData] = useState({
    name: "",
    businessTagLine: "",
  });

  const Edit = async () => {
    await Client.editClientGroup({
      clientGroupId: selectedMember._id,
      ...EditData,
    });
    setOpen(false);
    const response = await Client.getClientGroups({});
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
    if (selectedMember)
      setSelectedMember(
        response.data.find((m) => m?._id === selectedMember?._id) ?? null
      );
  };
  const Add = async () => {
    await Client.createClientGroup({
      ...AddData,
    });
    setOpenAdd(false);
    const response = await Client.getClientGroups({});
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
    if (selectedMember)
      setSelectedMember(
        response.data.find((m) => m?._id === selectedMember?._id) ?? null
      );
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeAdd = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddData((prev) => ({ ...prev, [name]: value }));
  };
  const [openAdd, setOpenAdd] = useState(false);
  const ref = useRef();
  if (loading) {
    return <Skeleton active />;
  }
  return (
    <div className="flex flex-col " id="table_client">
      <h3 ref={ref}>Client Groups</h3>
      <Table
        className="w-full max-w-2xl"
        dataSource={Clients.filter((c) => displayArchive || !c.archived)}
        columns={columns}
        loading={loading}
        id="table_client"
        footer={() => (
          <div className="flex gap-3 items-center justify-between">
            {canEdit && (
              <Button type="primary" onClick={() => setOpenAdd(true)}>
                Add Client Group
              </Button>
            )}

            <Dropdown.Button
              type="primary"
              menu={{
                items: DropDownMenu,
              }}
              arrow={{
                pointAtCenter: true,
              }}
            >
              Export
            </Dropdown.Button>
          </div>
        )}
      />

      {canEdit && (
        <div>
          <Button type="primary">
            <label htmlFor="bulk-upload">Bulk Add</label>
            <Input
              className="hidden"
              type="file"
              id="bulk-upload"
              onChange={(e) =>
                handleXLSXTOJSON({ sheet: e.target.files[0] }, async (json) => {
                  json.shift();
                  await Client.createClientGroupBulk({
                    groups: json,
                  });
                  loadClients();
                  setOpenAdd(false);
                })
              }
            />
          </Button>
          <a className="mx-3" href={SampeBulkAddGroupClients} download={true}>
            Sample Excel Sheet
          </a>
        </div>
      )}

      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Edit Client Group"
        onOk={Edit}
        loading={loading}
        footer={[
          <Button onClick={() => setOpen(false)} danger>
            Cancel
          </Button>,
          <Button type="primary" onClick={Edit} loading={loading}>
            Edit Member
          </Button>,
        ]}
      >
        <div className="mb-4">
          <label>Business Name</label>
          <Input
            value={EditData?.name || ""}
            onChange={handleChange}
            name="name"
            onPressEnter={Edit}
            placeholder="Name"
          />
        </div>

        <div className="mb-4">
          <label>Group Description</label>
          <Input
            value={EditData?.businessTagLine || ""}
            onPressEnter={Edit}
            onChange={handleChange}
            name="businessTagLine"
            placeholder="Group Description"
          />
        </div>
      </Modal>
      <Modal
        open={openAdd}
        onCancel={() => setOpenAdd(false)}
        title="Add Client Group"
        onOk={Edit}
        loading={loading}
        footer={[
          <Button onClick={() => setOpenAdd(false)} danger>
            Cancel
          </Button>,
          <Button type="primary" onClick={Add} loading={loading}>
            Add Client Group
          </Button>,
        ]}
      >
        <div className="mb-4">
          <label>Business Name</label>
          <Input
            value={AddData?.name || ""}
            onChange={handleChangeAdd}
            name="name"
            onPressEnter={Add}
            placeholder="Name"
          />
        </div>

        <div className="mb-4">
          <label>Group Description</label>
          <Input
            value={AddData?.businessTagLine || ""}
            onPressEnter={Add}
            onChange={handleChangeAdd}
            name="businessTagLine"
            placeholder="Group Description"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ClientMain;
