import {
  Button,
  Form,
  Input,
  Modal,
  Skeleton,
  Space,
  Table,
  Timeline,
  Typography,
  message,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectLoading } from "../../../redux/auth/selectors";
import {
  default as RiskAssessment,
  default as RiskAssessmentE,
} from "../../../service/RiskAssessment";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { PeriodicReviewData, PeriodicReviewDataOverride } from "./data";

const getArray = (override, overrideReview, periodicreview) =>
  override
    ? Object.keys(overrideReview)
        .map((e) => ({
          ...overrideReview[e],
          key: e,
        }))
        .map((e, i) => [
          e?.title ?? "",
          e?.value ? "Yes" : "No",
          e?.notes ?? "",
        ])
    : Object.keys(periodicreview)
        .map((e) => ({
          ...periodicreview[e],
          key: e,
        }))
        .map((e, i) => [
          e?.title ?? "",
          e?.value ? "Yes" : "No",
          e?.notes ?? "",
        ]);

export default function Index() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "" }]);
  };

  const handleSave = useCallback(async () => {
    try {
      const config = {
        additionalFields: questions,
      };
      await RiskAssessment.setPeriodicReviewConfig(config);
      message.success("Configuration saved successfully!");
      setVisible(false);
    } catch (error) {
      console.error("Error saving configuration:", error);
    }
  }, [questions]);

  const loading = useSelector(selectLoading);

  const [periodicreview, setperiodreview] = useState(PeriodicReviewData);
  const [oldPeriodicReviews, setOldPeriodicReviews] = useState([]);
  const [riskFactorScores, setRiskFactorScores] = useState(null);
  const { id } = useParams();
  const [override, setOverride] = useState(false);
  const [client, setClient] = useState(null);
  const [reviewDate, setReviewDate] = useState(null);
  const [reviewer, setReviewer] = useState(null);
  const [overrideReview, setOverideReview] = useState({});

  const getData = useCallback(async () => {
    RiskAssessment.getRiskFactorScores().then(({ data }) =>
      setRiskFactorScores(data)
    );
    RiskAssessment.getSingleRiskAssessments({ id }).then(({ data }) => {
      console.log(data);

      const reviewdate =
        data?.periodicReviewForms?.[data?.periodicReviewForms.length - 1].date;
      const reviewer =
        data?.periodicReviewForms?.[data?.periodicReviewForms.length - 1]
          .reviewer.name;
      setReviewer(reviewer);
      setReviewDate(moment(reviewdate).format("YYYY-MM-DD"));
      setOldPeriodicReviews(data?.periodicReviewForms?.reverse?.());
      setClient(data.client);
      Object.keys(PeriodicReviewData).map((e) => {
        try {
          switch (e) {
            case "anyPEPassociations":
              setperiodreview((prev) => ({
                ...prev,
                [e]: { ...prev[e], value: data?.PEP || false },
              }));
              break;
            case "anyNewAdverseMediaAssociation":
              setperiodreview((prev) => ({
                ...prev,
                [e]: { ...prev[e], value: data?.adverseMedia || false },
              }));
              break;

            case "anyNewSanctionsRiskAssociations":
              setperiodreview((prev) => ({
                ...prev,
                [e]: { ...prev[e], value: data?.sanctions || false },
              }));
              break;

            case "newTypeOfProductServiceOfferedToClient":
              setperiodreview((prev) => {
                return {
                  ...prev,
                  [e]: {
                    ...prev[e],
                    value:
                      data?.riskAssessmentForm?.EntityProductServiceOffered
                        ?.typeOfProductServiceOfferedToClient,
                  },
                };
              });
              break;
            default:
          }
        } catch (error) {
          console.log(error);
        }
        return e;
      });
    });
  }, [id]);

  const getDataPeriodic = async () => {
    const config = await RiskAssessmentE.getPeriodicReviewConfig();
    setQuestions(config.data.additionalFields);
  };

  useEffect(() => {
    getData();
    getDataPeriodic();
  }, [getData]);

  const [nextReviewDate, setNextReviewDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [comments, setComments] = useState("");
  const handleValueChange = (key, value) => {
    setperiodreview((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value: value,
      },
    }));
  };
  const handleSubmit = async () => {
    const formData = JSON.parse(JSON.stringify(periodicreview));
    Object.keys(formData).forEach((e) => {
      delete formData[e].render;
      delete formData[e].key;
    });
    console.log(formData);
    formData.comments = comments;
    formData.extraQuestions = questions.map((question, i) => ({
      ...question,
      answer: answers?.[i],
    }));
    await RiskAssessment.submitPeriodicReviewUpdated({
      riskAssessmentId: id,
      periodicReviewForm: formData,
      nextReviewDate,
    }).then(async () => {
      await getData();
      message.success("Successfully Submitted Periodic Review");
    });
  };
  const handleNoteChange = (key, value) => {
    setperiodreview((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        notes: value,
      },
    }));
  };
  const columns = () => [
    {
      title: "Question",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Yes/No",
      dataIndex: "value",
      render: (__, _) => {
        return _?.render?.(
          __,
          riskFactorScores,
          _,
          handleValueChange,
          periodicreview
        );
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render: (_, { key }) => {
        return (
          <div style={{ minWidth: 200 }}>
            <Typography.Paragraph
              editable={{ onChange: (e) => handleNoteChange(key, e) }}
            >
              {_}
            </Typography.Paragraph>
          </div>
        );
      },
    },
  ];

  const additionalFieldsColumns = [
    {
      title: "Question",
      dataIndex: "title",
      key: "title",
      render: (a) => a?.question,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render: (a, record) => (
        <div style={{ minWidth: 200 }}>
          {override ? (
            answers?.[record.idx]?.notes
          ) : (
            <Typography.Paragraph
              editable={{
                onChange: (e) =>
                  setAnswers((a) => {
                    const cur = [...a];
                    cur[record.idx] = { notes: e };
                    return cur;
                  }),
              }}
            >
              {answers?.[record.idx]?.notes}
            </Typography.Paragraph>
          )}
        </div>
      ),
    },
  ];

  if (!riskFactorScores) {
    return <Skeleton active />;
  }
  const handleOverride = (form, date, e) => {
    const newForm = Object.keys(form).map((e) => ({
      ...form[e],
      render: PeriodicReviewDataOverride?.[e]?.render,
    }));
    setOverideReview(newForm);
    setOverride(true);
    setReviewDate(date);
    setReviewer(e?.reviewer);
    setComments(form.comments ?? "");
    if (form.extraQuestions?.length > 0)
      setAnswers(form.extraQuestions.map((q) => q.answer));
    else setAnswers([]);
  };
  return (
    <div>
      <h5 className="!my-10">
        Periodic Review for {client?.name}{" "}
        {reviewDate && moment(reviewDate).format("DD.MM.YY HH:mm")}
      </h5>
      <Table
        columns={columns()}
        rowKey={"uid"}
        pagination={false}
        dataSource={
          override
            ? Object.keys(overrideReview).map((e) => ({
                ...overrideReview[e],
                key: e,
              }))
            : Object.keys(periodicreview).map((e) => ({
                ...periodicreview[e],
                key: e,
              }))
        }
      />
      {questions.length > 0 && (
        <div>
          <h3>Review Questions</h3>
          <Table
            columns={additionalFieldsColumns}
            rowKey={"uid"}
            pagination={false}
            dataSource={questions.map((question, i) => ({
              title: question,
              notes: answers?.[i]?.notes,
              idx: i,
            }))}
          />
        </div>
      )}
      {overrideReview && (
        <div className="bg-gray-200 p-3 rounded-xl mt-4">
          <div>
            <strong>Comments</strong>
          </div>
          <div>{comments}</div>
        </div>
      )}
      {override || (
        <>
          <div className="w-[50%] my-10">
            <label className="!font-bold">Next Review Date</label>
            <Input
              value={nextReviewDate}
              onChange={(e) => setNextReviewDate(e.target.value)}
              type="date"
            />
          </div>
          <div className="w-[50%] my-10">
            <label className="!font-bold">Comments</label>
            <Input.TextArea
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
          <Button type="primary" disabled={loading} onClick={handleSubmit}>
            Submit
          </Button>
        </>
      )}
      {override && (
        <Button
          type="primary"
          className="my-10"
          onClick={() => {
            setOverride(false);
            setOverideReview(null);
            setReviewDate(null);
            setReviewer(null);
            setAnswers([]);
          }}
        >
          Create New Review
        </Button>
      )}
      <Button
        type="primary"
        className="ml-2"
        onClick={async () => {
          handleGeneratePDF({
            docDef: {
              content: [
                {
                  text: `Periodic Review: ${client?.name ?? ""}`,
                  style: "header",
                },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ["Question", "Yes/No", "Notes"],

                      getArray(override, overrideReview, periodicreview)[0].map(
                        (_, colIndex) =>
                          getArray(
                            override,
                            overrideReview,
                            periodicreview
                          ).map((row) => row[colIndex])
                      ),
                    ],
                  },
                  style: "table",
                },
                {
                  text: ``,
                  style: "header",
                },
                {
                  text: `Review Questions`,
                  style: "header",
                },
                {
                  table: {
                    headerRows: 1,
                    body: [
                      ["Question", "Notes"],
                      [0, 1].map((idx) =>
                        questions.map((question, i) =>
                          idx === 0 ? question.question : answers?.[i]?.notes
                        )
                      ),
                    ],
                  },
                  style: "table",
                },
                {
                  text: ``,
                  style: "header",
                },
                {
                  text: `Information`,
                  style: "header",
                },
                {
                  text: `Review Date: ${reviewDate}`,
                },
                {
                  text: `Next Review Date: ${nextReviewDate}`,
                },
                {
                  text: `Reviewed By: ${reviewer}`,
                },
                {
                  text: `Comments: ${comments}`,
                },
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10],
                },
                table: {
                  fontSize: 8,
                },
              },
            },
          });
        }}
      >
        Export PDF
      </Button>
      <div className="p-3 rounded-xl custom-shadow my-10 mx-2">
        <h5 className="!my-10">Periodic Review Dates</h5>
        <Timeline
          mode="left"
          items={oldPeriodicReviews.map((e) => ({
            label: (
              <div
                className="cursor-pointer capitalize"
                onClick={() => handleOverride(e.form, e.date, e)}
              >
                {moment(e.date).fromNow()}
              </div>
            ),

            children: (
              <div
                className="cursor-pointer "
                onClick={() => handleOverride(e.form, e.date, e)}
              >
                Date: {e.date.split("T")[0]}
              </div>
            ),
          }))}
        />
      </div>
    </div>
  );
}
