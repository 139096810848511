import { Button, Select, Skeleton, Table, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { AiOutlineSave } from "react-icons/ai";
import { v4 as uuid4 } from "uuid";
import Admin from "../../../service/Admin";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { getScoreTextSelected } from "../CountryRiskScores";
import WeightingTable from "./WeightingTable";
import Country from "./countryscores";
import { WeighingtableData } from "./data";
const { Option } = Select;

const scoreOptions = [1, 3, 5, 10];

const App = () => {
  const [scores, setScores] = useState();
  const [loading, setLoading] = useState(false);
  const [shouldsave, setshouldsave] = useState(false);
  const scoreLoad = async () => {
    setLoading(true);
    const { data } = await Admin.getRiskFactorScores();
    setLoading(false);
    setScores(data.riskFactorScores);
  };
  const Save = async () => {
    if (shouldsave) {
      await Admin.changeRiskFactorScores({
        riskFactorScores: scores,
      });
    }
  };
  const handleScoreChange = useCallback((key, value, setTableData, path) => {
    setTableData((prevData) => ({
      ...prevData,
      [path]: {
        ...prevData[path],
        [key]: { ...prevData[path][key], score: value },
      },
    }));
    setshouldsave(true);
  }, []);

  const handleFieldChange = useCallback((key, value, setTableData, path) => {
    setTableData((prev) => ({
      ...prev,
      [path]: {
        ...prev[path],
        [key]: { ...prev[path][key], title: value },
      },
    }));
    setshouldsave(true);
  }, []);

  const handleAddField = useCallback((setTableData, path) => {
    setTableData((prevData) => ({
      ...prevData,
      [path]: {
        ...prevData[path],
        [uuid4()]: { title: "", score: 1 },
      },
    }));
    setshouldsave(true);
  }, []);
  const handleRemoveField = useCallback((setTableData, key, path) => {
    setTableData((prevData) => {
      const prev = JSON.parse(
        JSON.stringify({
          ...prevData,
        })
      );
      delete prev[path][key];
      return prev;
    });
    setshouldsave(true);
  }, []);

  const columns = useCallback(
    (setFunction, path) => [
      {
        title: "Inherent Risk",
        dataIndex: "title",
        key: "title",
        sorter: (a, b) => a.title.localeCompare(b.title),
        render: (_, record) => (
          <Typography.Paragraph
            editable={{
              onChange: (e) => {
                handleFieldChange(record.key, e, setFunction, path);
              },
            }}
          >
            {record.title}
          </Typography.Paragraph>
        ),
      },
      {
        title: "Inherent Risk Scores",
        dataIndex: "score",
        key: "score",
        sorter: (a, b) => a.score - b.score,
        render: (_, record) => (
          <Select
            value={record.score}
            onChange={(value) =>
              handleScoreChange(record.key, value, setFunction, path)
            }
          >
            {scoreOptions.map((score) => (
              <Option key={score} value={score}>
                {score}
              </Option>
            ))}
          </Select>
        ),
      },
      {
        title: "Risk Factor",
        dataIndex: "riskFactor",
        key: "riskFactor",
        render: (_, record) => getScoreTextSelected(record.score),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_, record) => (
          <Button
            onClick={() => handleRemoveField(setFunction, record.key, path)}
            danger
          >
            Delete
          </Button>
        ),
      },
    ],
    [handleFieldChange, handleScoreChange, handleRemoveField]
  );

  useEffect(() => {
    scoreLoad();
  }, []);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div>
      <div className="my-10">
        <Button
          className="mb-5"
          type="primary"
          onClick={async () => {
            const { data: countryData } = await Admin.getCountryRiskScores();
            const { data: weightingData } = await Admin.getWeightingTable();

            handleGeneratePDF({
              docDef: {
                content: [
                  {
                    text: "Products / Services / Transactions",
                    style: "header",
                  },
                  {
                    table: {
                      headerRows: 1,
                      body: [
                        columns(setScores, "EntityProductServiceOffered")
                          .filter(
                            (e) =>
                              !["action", "riskFactor"].includes(e.dataIndex)
                          )
                          .map((column) => column.title),
                        ...Object.keys(
                          scores?.EntityProductServiceOffered || {}
                        )
                          .map((e, i) => ({
                            ...scores.EntityProductServiceOffered?.[e],
                            key: e,
                          }))
                          .map((client) =>
                            columns(setScores, "EntityProductServiceOffered")
                              .filter(
                                (e) =>
                                  !["action", "riskFactor"].includes(
                                    e.dataIndex
                                  )
                              )
                              .map((column) => client[column.dataIndex] || "")
                          ),
                      ],
                    },
                    style: "table",
                  },

                  { text: "Client Characteristics", style: "header" },
                  {
                    table: {
                      headerRows: 1,
                      body: [
                        columns(setScores, "ClientCharacteristics")
                          .filter(
                            (e) =>
                              !["action", "riskFactor"].includes(e.dataIndex)
                          )
                          .map((column) => column.title),
                        ...Object.keys(scores?.ClientCharacteristics || {})
                          .map((e, i) => ({
                            ...scores.ClientCharacteristics[e],
                            key: e,
                          }))
                          .map((client) =>
                            columns(setScores, "ClientCharacteristics")
                              .filter(
                                (e) =>
                                  !["action", "riskFactor"].includes(
                                    e.dataIndex
                                  )
                              )
                              .map((column) => client[column.dataIndex] || "")
                          ),
                      ],
                    },
                    style: "table",
                  },

                  { text: "Delivery Channel", style: "header" },
                  {
                    table: {
                      headerRows: 1,
                      body: [
                        columns(setScores, "DeliveryChannelsRisk")
                          .filter(
                            (e) =>
                              !["action", "riskFactor"].includes(e.dataIndex)
                          )
                          .map((column) => column.title),
                        ...Object.keys(scores?.DeliveryChannelsRisk || {})
                          .map((e, i) => ({
                            ...scores.DeliveryChannelsRisk[e],
                            key: e,
                          }))
                          .map((client) =>
                            columns(setScores, "DeliveryChannelsRisk")
                              .filter(
                                (e) =>
                                  !["action", "riskFactor"].includes(
                                    e.dataIndex
                                  )
                              )
                              .map((column) => client[column.dataIndex] || "")
                          ),
                      ],
                    },
                    style: "table",
                  },

                  { text: "Source of Funds / Transactions", style: "header" },
                  {
                    table: {
                      headerRows: 1,
                      body: [
                        columns(setScores, "SourceofFundsTransaction")
                          .filter(
                            (e) =>
                              !["action", "riskFactor"].includes(e.dataIndex)
                          )
                          .map((column) => column.title),
                        ...Object.keys(scores?.SourceofFundsTransaction || {})
                          .map((e, i) => ({
                            ...scores.SourceofFundsTransaction[e],
                            key: e,
                          }))
                          .map((client) =>
                            columns(setScores, "SourceofFundsTransaction")
                              .filter(
                                (e) =>
                                  !["action", "riskFactor"].includes(
                                    e.dataIndex
                                  )
                              )
                              .map((column) => client[column.dataIndex] || "")
                          ),
                      ],
                    },
                    style: "table",
                  },

                  { text: "Country Risk Scores", style: "header" },
                  {
                    table: {
                      headerRows: 1,
                      body: [
                        ["Country", "Score", "Comment"],
                        ...Object.keys(countryData.countryRiskScores || {}).map(
                          (e, i) => [
                            countryData.countryRiskScores[e]?.title ?? e,
                            countryData.countryRiskScores[e]?.score,
                            countryData.countryRiskScores[e]?.comment,
                          ]
                        ),
                      ],
                    },
                    style: "table",
                  },

                  { text: "Weighting Table", style: "header" },
                  {
                    table: {
                      headerRows: 1,
                      body: [
                        ["Field", "Weight"],
                        ...Object.keys(weightingData.weightingTable || {}).map(
                          (e, i) => [
                            WeighingtableData[e] ?? e,
                            weightingData.weightingTable[e],
                          ]
                        ),
                      ],
                    },
                    style: "table",
                  },
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  table: {
                    fontSize: 10,
                  },
                },
              },
            });
          }}
        >
          Export PDF
        </Button>

        <h3>Inherent Risk Score</h3>

        <div className="my-10">
          <h5 className="">Products / Services / Transactions</h5>
          <Table
            columns={columns(setScores, "EntityProductServiceOffered")}
            dataSource={Object.keys(
              scores?.EntityProductServiceOffered || {}
            ).map((e, i) => ({
              ...scores.EntityProductServiceOffered?.[e],
              key: e,
            }))}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex items-center gap-3">
                <Button
                  type="primary"
                  onClick={() =>
                    handleAddField(setScores, "EntityProductServiceOffered")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>

        <div className="my-10">
          <h5 className="">Client Characteristics</h5>
          <Table
            columns={columns(setScores, "ClientCharacteristics")}
            dataSource={Object.keys(scores?.ClientCharacteristics || {}).map(
              (e, i) => ({
                ...scores.ClientCharacteristics[e],
                key: e,
              })
            )}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex items-center gap-3">
                <Button
                  type="primary"
                  onClick={() =>
                    handleAddField(setScores, "ClientCharacteristics")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>

        <div className="my-10">
          <h5 className="">Delivery Channel</h5>
          <Table
            columns={columns(setScores, "DeliveryChannelsRisk")}
            dataSource={Object.keys(scores?.DeliveryChannelsRisk || {}).map(
              (e, i) => ({
                ...scores.DeliveryChannelsRisk[e],
                key: e,
              })
            )}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex items-center gap-3">
                <Button
                  type="primary"
                  onClick={() =>
                    handleAddField(setScores, "DeliveryChannelsRisk")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>

        <div className="my-10">
          <h5 className="">Source of Funds / Transactions</h5>
          <Table
            columns={columns(setScores, "SourceofFundsTransaction")}
            dataSource={Object.keys(scores?.SourceofFundsTransaction || {}).map(
              (e, i) => ({
                ...scores.SourceofFundsTransaction[e],
                key: e,
              })
            )}
            pagination={false}
            bordered
            footer={() => (
              <div className="flex gap-3 items-center">
                <Button
                  type="primary"
                  className=""
                  onClick={() =>
                    handleAddField(setScores, "SourceofFundsTransaction")
                  }
                >
                  Add Field
                </Button>
              </div>
            )}
          />
        </div>
        {shouldsave && (
          <div className="bg-gray-100 px-5 py-2 ">
            <Button
              type="primary"
              className="flex items-center gap-1"
              onClick={Save}
            >
              <AiOutlineSave />
              Save
            </Button>
          </div>
        )}
      </div>
      <WeightingTable />
      <Country />
    </div>
  );
};

export default App;
