import { AutoComplete, Card, Input } from "antd";
import React, { useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import { NavLink, useNavigate } from "react-router-dom";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import User from "../../service/User";

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <div
      style={{
        padding: "8px", // Adjust styling as needed
        fontWeight: isHighlighted ? 700 : 400, // Adjust fontWeight as needed
        backgroundColor: isHighlighted ? "#f0f0f0" : "transparent", // Highlight background color
        cursor: "pointer", // Add a pointer cursor
      }}
    >
      <NavLink
        to={suggestion.link}
        style={{
          textDecoration: "none", // Remove underline for the link
          color: "#333", // Adjust link color as needed
        }}
      >
        {parts.map((part, index) => (
          <span key={String(index)}>{part.text}</span>
        ))}
      </NavLink>
    </div>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <div
      {...containerProps}
      style={{
        position: "absolute",
        zIndex: 9999,
        width: "100%", // Set the width as needed
        maxHeight: "200px", // Set the max height as needed
        overflowY: "auto", // Add vertical scroll if needed
        marginTop: "4px", // Adjust the margin as needed
      }}
    >
      {children}
    </div>
  );
}

async function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  if (inputLength < 2) return [];

  const searchResult = await User.search({
    text: inputValue,
  });

  return [...searchResult.data];
}

const SearchUI = () => {
  const navigate = useNavigate();

  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const suggestionsRef = useRef(null);

  const handleSuggestionsFetchRequested = async (e) => {
    const result = await getSuggestions(e.value);
    setSuggestions(result);
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const handleChange = (event, { newValue }) => {
    setValue(newValue);
  };
  const handleSuggestionSelected = (event, { suggestion, method }) => {
    if (method === "enter") {
      navigate(suggestion.link);
    }
  };

  return (
    <>
      <div
        style={{ position: "relative", display: "flex", alignItems: "center" }}
      >
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={handleSuggestionsClearRequested}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={renderSuggestion}
          renderSuggestionsContainer={renderSuggestionsContainer}
          onSuggestionSelected={handleSuggestionSelected}
          inputProps={{
            placeholder: "Search Entity",
            value,
            onChange: handleChange,
            style: {
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              fontSize: "16px",
            },
          }}
          ref={suggestionsRef}
        />
      </div>
    </>
  );
};

export default SearchUI;
