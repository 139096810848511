import React, { useCallback, useEffect, useState } from "react";
import Public from "../../service/Public";

const Privacy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(
    localStorage.privacyPolicy ?? null
  );

  const loadData = useCallback(async () => {
    const data = await Public.getPrivacy();
    if (data.data.privacyPolicy) {
      setPrivacyPolicy(data.data.privacyPolicy);
      localStorage.setItem("privacyPolicy", data.data.privacyPolicy);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <h1>Privacy Policy</h1>
      <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
    </>
  );
};

export default Privacy;
