import { Tabs } from "antd";
import React, { useState } from "react";
import AdverseReport from "./AdverseReport";
import ClientGroupReport from "./ClientGroupReport";
import EscalationReport from "./EscalationReport";
import IDExpirationReport from "./IDExpirationReport";
import PeriodicReviewReport from "./PeriodicReviewReport";
import RegulatoryReport from "./RegulatoryReport";
import RelatedReport from "./RelatedReport";
import RiskReport from "./RiskReport";
import ScreeningReport from "./ScreeningReport";
import ClientsReport from "./ClientsReport";

const Index = () => {
  const [activeTab, setActiveTab] = useState("1");

  const items = [
    {
      key: "1",
      label: `Risk Report`,
    },
    {
      key: "1.2",
      label: `Related Party Report`,
    },
    {
      key: "1.3",
      label: `Client Group Report`,
    },
    {
      key: "1.4",
      label: `Screening Report`,
    },
    {
      key: "2",
      label: `Regulatory Report`,
    },
    {
      key: "3",
      label: `Escalation Report`,
    },
    {
      key: "4",
      label: `Adverse Report`,
    },
    {
      key: "5",
      label: `Periodic Review Report`,
    },
    {
      key: "6",
      label: `ID Expiration Report`,
    },
    {
      key: "7",
      label: `Client Report`,
    },
  ];

  return (
    <div className="flex flex-col mr-2">
      <h3>Reports</h3>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={(e) => setActiveTab(e)}
        active={activeTab}
      />

      {activeTab === "1" && <RiskReport />}
      {activeTab === "1.2" && <RelatedReport />}
      {activeTab === "1.3" && <ClientGroupReport />}
      {activeTab === "1.4" && <ScreeningReport />}
      {activeTab === "2" && <RegulatoryReport />}
      {activeTab === "3" && <EscalationReport />}
      {activeTab === "4" && <AdverseReport />}
      {activeTab === "5" && <PeriodicReviewReport />}
      {activeTab === "6" && <IDExpirationReport />}
      {activeTab === "7" && <ClientsReport />}
    </div>
  );
};

export default Index;
