import { Button, Form, Modal, Space, Typography, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import RiskAssessment from "../../../service/RiskAssessment";

const AdditionalQuestions = () => {
  const [form] = Form.useForm();
  const [questions, setQuestions] = useState([]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "" }]);
  };

  const handleSave = useCallback(async () => {
    try {
      const config = {
        additionalFields: questions,
      };
      await RiskAssessment.setPeriodicReviewConfig(config);
      message.success("Configuration saved successfully!");
    } catch (error) {
      console.error("Error saving configuration:", error);
    }
  }, [questions]);

  useEffect(() => {
    RiskAssessment.getPeriodicReviewConfig().then((config) => {
      setQuestions(config.data.additionalFields);
    });
  }, []);

  return (
    <>
      <h3>Review Questions</h3>

      <Form form={form} initialValues={{ questions }}>
        {questions.map((question, index) => (
          <Form.Item
            key={index}
            label={`Question ${index + 1}`}
            name={["questions", index, "question"]}
            initialValue={question.question}
          >
            <Space className="flex justify-between">
              <Typography.Paragraph
                style={{ marginTop: 4 }}
                editable={{
                  onChange: (e) => {
                    setQuestions((prevQuestions) =>
                      prevQuestions.map((q, i) =>
                        i === index ? { ...q, question: e } : q
                      )
                    );
                  },
                }}
              >
                {question.question}
              </Typography.Paragraph>
              <Button
                danger
                onClick={() => {
                  setQuestions((prev) => {
                    const cur = [...prev];
                    cur.splice(index, 1);
                    return cur;
                  });
                }}
              >
                Delete
              </Button>
            </Space>
          </Form.Item>
        ))}

        <Button onClick={handleAddQuestion} type="dashed" block>
          Add New Question
        </Button>
      </Form>

      <Button type="primary" onClick={handleSave}>
        Save
      </Button>
    </>
  );
};

export default AdditionalQuestions;
