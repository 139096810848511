import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import Forgot from "./Forgot";
import Login from "./Login";
import Onboarding from "./Onboarding";
import Register from "./Register";
import TeamRegister from "./TeamMemberRegister";

const Auth = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/app");
  }, [user]);

  return (
    <div className="auth-wrapper">
      <section className="section">
        <div
          className="container"
          style={{ width: "100vw", maxWidth: "unset" }}
        >
          <div className="right">
            <div className="right-wrap">
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route
                  path="/teammemberregister/:resetToken"
                  element={<TeamRegister />}
                />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/onboarding" element={<Onboarding />} />
              </Routes>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Auth;
