import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import { selectLoading } from "../../../redux/auth/selectors";
import Client from "../../../service/Client";

export const exportToExcel = (data) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

  XLSX.writeFile(workbook, "data.xlsx");
};

const ClientGroup = () => {
  const fields = [
    { name: "name", placeholder: "Name" },
    { name: "businessType", placeholder: "Transaction or Business Activity" },
    { name: "businessWebsite", placeholder: "Business Website" },
    { name: "placeOfBirth", placeholder: "Place of Birth / Incorporation" },
    { name: "status", placeholder: "Status" },
    {
      name: "sourceOfFunds",
      placeholder: "Source of Funds",
    },
    {
      name: "sourceOfWealth",
      placeholder: "Source of Wealth",
    },
    {
      name: "industryOccupation",
      placeholder: "Industry / Occupation",
    },
    {
      name: "expectedTransactionVolume",
      placeholder: "Expected Transaction Volume",
    },
    {
      name: "expectedTransactionValue",
      placeholder: "Expected Transaction Value",
    },
    { name: "transactionType", placeholder: "Transaction Type" },
    { name: "isHighNetWorth", placeholder: "High Net Worth Client" },
    { name: "isRegulated", placeholder: "Regulated Client" },
    { name: "regulatorName", placeholder: "Regulator Name" },
    { name: "regulatorType", placeholder: "Regulation Type" },
    { name: "eligibleIntroducer", placeholder: "Eligible Introducer" },
    { name: "introducerName", placeholder: "Introducer Name" },
    { name: "ongoingScreening", placeholder: "Ongoing Screening" },
  ];
  const [Clients, setClients] = useState([]);
  const loading = useSelector(selectLoading);
  const { groupId } = useParams();

  const loadClients = useCallback(async () => {
    if (!groupId) return;

    const response = await Client.getClientsByUser(groupId);
    setClients(
      response.data
        .filter((t) => t.group === groupId)
        .map((t) => ({ ...t, key: t?._id }))
    );
  }, [groupId]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Business",
      dataIndex: "isBusiness",
      key: "isBusiness",
      render: (isTrue) => {
        console.log(isTrue);
        return <Space>{isTrue ? "Yes" : "No"}</Space>;
      },
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      key: "transactionType",
    },
    {
      title: "Transaction or Business Activity",
      dataIndex: "businessType",
      key: "businessType",
    },
    {
      title: "Business Website",
      dataIndex: "businessWebsite",
      key: "businessWebsite",
    },
    {
      dataIndex: "placeOfBirth",
      key: "placeOfBirth",
      title: "Place of Birth / Incorporation",
    },
    {
      title: "Source of Funds",
      dataIndex: "sourceOfFunds",
      key: "sourceOfFunds",
    },
    {
      title: "Source of Wealth",
      dataIndex: "sourceOfWealth",
      key: "sourceOfWealth",
    },
    {
      title: "Industry / Occupation",
      dataIndex: "industryOccupation",
      key: "industryOccupation",
    },

    {
      dataIndex: "expectedTransactionVolume",
      key: "expectedTransactionVolume",
      title: "Expected Transaction Volume",
    },
    {
      dataIndex: "expectedTransactionValue",
      key: "expectedTransactionValue",
      title: "Expected Transaction Value",
    },
    {
      title: "High Net Worth Client",
      dataIndex: "isHighNetWorth",
      key: "isHighNetWorth",
    },
    {
      title: "Regulated Client",
      dataIndex: "isRegulated",
      key: "isRegulated",
    },
    {
      title: "Regulator Name",
      dataIndex: "regulatorName",
      key: "regulatorName",
    },
    {
      title: "Regulation Type",
      dataIndex: "regulatorType",
      key: "regulatorType",
    },
    {
      title: "Eligible Introducer",
      dataIndex: "eligibleIntroducer",
      key: "eligibleIntroducer",
    },
    {
      title: "Introducer Name",
      dataIndex: "introducerName",
      key: "introducerName",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          <Link to={`/app/clients/related-parties/${text._id}`}>
            <Button type="primary">Details</Button>
          </Link>
          <Popconfirm
            title="Are you sure?"
            okText="Yes"
            onConfirm={() => handleDelete(text)}
          >
            <Button danger>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [EditData, setEditData] = useState({
    clientId: "",
    name: "",
    isBusiness: "",
    businessType: "",
    businessWebsite: "",
    placeOfBirth: "",
    status: "",
    isHighNetWorth: false,
    isRegulated: false,
    ongoingScreening: false,
    transactionType: false,
    regulatorName: "",
    regulatorType: "",
    eligibleIntroducer: false,
    introducerName: "",
    sourceOfFunds: "",
    sourceOfWealth: "",
    industryOccupation: "",
    group: params.groupId,
    relatedParties: [],
  });
  const [AddData, setAddData] = useState({
    group: params.groupId,
    name: "",
    isBusiness: false,
    businessType: "",
    businessWebsite: "",
    placeOfBirth: "",
    status: "",
    isHighNetWorth: false,
    isRegulated: false,
    ongoingScreening: false,
    transactionType: false,
    regulatorName: "",
    regulatorType: "",
    eligibleIntroducer: false,
    introducerName: "",
    sourceOfFunds: "",
    sourceOfWealth: "",
    industryOccupation: "",
    relatedParties: [],
  });

  const handleDelete = async (data) => {
    await Client.deleteClient({
      clientId: data._id,
    }).then(() => setClients((prev) => prev.filter((t) => t._id !== data._id)));
  };
  const Edit = async () => {
    await Client.editClient({
      ...EditData,
      relatedParties: undefined,
    });
    setOpen(false);

    loadClients();
  };
  const Add = async () => {
    await Client.createClient({ ...AddData });
    setOpenAdd(false);

    loadClients();
    setAddData({
      group: params.groupId,
      name: "",
      isBusiness: false,
      businessType: "",
      businessWebsite: "",
      placeOfBirth: "",
      status: "",
      isHighNetWorth: false,
      isRegulated: false,
      ongoingScreening: false,
      transactionType: false,
      regulatorName: "",
      regulatorType: "",
      eligibleIntroducer: false,
      introducerName: "",
      sourceOfFunds: "",
      sourceOfWealth: "",
      industryOccupation: "",
      relatedParties: [],
    });
  };

  const handleChangeAdd = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddData((prev) => ({ ...prev, [name]: value }));
  };
  const [openAdd, setOpenAdd] = useState(false);
  return (
    <div className="flex flex-col ">
      <h3>Clients</h3>
      <Table
        className="w-full max-w-2xl"
        dataSource={Clients}
        columns={columns}
        loading={loading}
        footer={() => (
          <Space>
            <Button type="primary" onClick={() => setOpenAdd(true)}>
              Add Client
            </Button>
          </Space>
        )}
      />

      <Modal
        open={openAdd}
        onCancel={() => setOpenAdd(false)}
        title="Add Client "
        onOk={Add}
        destroyOnClose
        loading={loading}
        footer={[
          <Button onClick={() => setOpenAdd(false)} danger>
            Cancel
          </Button>,
          <Button type="primary" onClick={Add} loading={loading}>
            Add Client
          </Button>,
        ]}
      >
        {fields.map((e, i) => (
          <div className="mb-4" key={i}>
            <label>{e.placeholder}</label>
            {typeof AddData[e.name] === "boolean" ? (
              <Switch
                onChange={handleChangeAdd}
                name={`${e.name}`}
                placeholder={e.placeholder}
              />
            ) : (
              <Input
                value={AddData[e.name] || ""}
                onPressEnter={Add}
                onChange={handleChangeAdd}
                name={`${e.name}`}
                placeholder={e.placeholder}
                required
              />
            )}
          </div>
        ))}
        <div className="mb-4 flex w-full">
          <Select
            onPressEnter={Add}
            value={AddData.isBusiness ? "yes" : "no"}
            defaultValue={""}
            className="w-full"
            onChange={(e) => {
              e === "yes"
                ? setAddData((prev) => ({ ...prev, isBusiness: true }))
                : setAddData((prev) => ({ ...prev, isBusiness: false }));
            }}
            name={"isBusiness"}
            placeholder={"Is Business?"}
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
          />
        </div>
      </Modal>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Edit Client "
        onOk={Edit}
        loading={loading}
        footer={[
          <Button onClick={() => setOpen(false)} danger>
            Cancel
          </Button>,
          <Button type="primary" onClick={Edit} loading={loading}>
            Edit Client
          </Button>,
        ]}
      >
        {fields.map((e, i) => (
          <div className="mb-4" key={i}>
            <label>{e.placeholder}</label>
            {typeof EditData[e.name] === "boolean" ? (
              <Switch
                onChange={handleChangeAdd}
                name={`${e.name}`}
                placeholder={e.placeholder}
              />
            ) : (
              <Input
                value={EditData[e.name] || ""}
                onPressEnter={Add}
                onChange={handleChangeAdd}
                name={`${e.name}`}
                placeholder={e.placeholder}
                required
              />
            )}
          </div>
        ))}
        <div className="mb-4 flex w-full">
          <Select
            onPressEnter={Edit}
            value={EditData.isBusiness ? "yes" : "no"}
            defaultValue={""}
            className="w-full"
            onChange={(e) => {
              e === "yes"
                ? setEditData((prev) => ({ ...prev, isBusiness: true }))
                : setEditData((prev) => ({ ...prev, isBusiness: false }));
            }}
            name={"isBusiness"}
            placeholder={"Is Business?"}
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ClientGroup;
