import { Alert } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import User from "../../../service/User";

const Referral = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    User.me().then((data) => setUser(data.data));
  }, []);
  return (
    <>
      <h3>Referral Program</h3>

      <div>
        Share the love and introduce a friend to our platform! When you do, we
        will share 15% of their first payment with you!
      </div>

      <br />

      <h4>How does it work?</h4>

      <div>
        <strong>Generate & Share</strong>: Use this page to generate your unique
        referral link. Share this link with your friends, family, or anyone you
        think would benefit from our platform.
      </div>
      <div>
        <strong>They Join</strong>: Your friend registers and subscribes using
        your referral link.
      </div>
      <div>
        <strong>You Both Benefit</strong>: Once their subscription is confirmed,
        you'll receive a 15% of the total payment. It's our way of saying thanks
        for spreading the word!
      </div>

      <br />
      <br />

      <div>Start sharing now and enjoy the rewards together!</div>

      {user && (
        <div className="shareLink">
          <div className="permalink">
            <input
              className="textLink"
              id="text"
              type="text"
              name="shortlink"
              value={`${window.location.origin}?r=${user?._id}`}
              readonly=""
            />
            <span
              className="copyLink"
              id="copy"
              tooltip="Copy to clipboard"
              style={{ left: 15, position: "absolute" }}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}?r=${user?._id}`
                );
              }}
            >
              <AiOutlineCopy />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default Referral;
