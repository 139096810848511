export const prepareNetworkGraphData = (graphData) => {
  const nodes = [];
  const edges = [];
  let subjectNode = null;

  if (!graphData?.length) return { nodes, edges };

  graphData?.forEach((data) => {
    const subjectLabel = data?.subjectLabel?.value;
    const objectLabel = data?.objectLabel?.value;
    const propertyLabel = data?.propertyLabel?.value;
    const subjectImage = data?.subjectImage?.value;
    const image = data?.image?.value;

    if (!subjectNode) {
      subjectNode = {
        id: 0,
        label: subjectLabel,
        ...(subjectImage && { shape: "image", image: subjectImage }),
      };
      nodes?.push(subjectNode);
    }

    const objectNode = {
      id: nodes?.length,
      label: objectLabel,
      ...(image && { shape: "image", image: image }),
    };
    nodes?.push(objectNode);

    edges?.push({
      from: subjectNode?.id,
      to: objectNode?.id,
      label: propertyLabel,
    });
  });

  return { nodes, edges };
};
