import { Button, Input, Select, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { AiFillPlusCircle, AiOutlineSave } from "react-icons/ai";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { selectLoading } from "../../../redux/auth/selectors";

import Admin from "../../../service/Admin";
import { getScoreTextSelected } from "../CountryRiskScores";
import { countries } from "./countries";
import { handleXLSXTOJSON } from "../../../utils/bulkUpload";
import SampleBulkAddClients from "../../../assets/Samples/SampleBulkUploadCountryRiskScore.xlsx";

const { Option } = Select;

const scoreOptions = [1, 3, 5, 10];

const App = () => {
  const [scores, setScores] = useState([]);
  const [shouldsave, setshouldsave] = useState(false);

  const loading = useSelector(selectLoading);
  const scoreload = async () => {
    const { data } = await Admin.getCountryRiskScores();

    delete data?._id;
    delete data?.__v;
    setScores(data.countryRiskScores);
  };

  const handleScoreChange = (key, value, setTableData) => {
    setTableData((prev) => {
      return { ...prev, [key]: { ...prev[key], score: value } };
    });
    setshouldsave(true);
  };

  const handleScoreAdd = () => {
    setScores((prev) => {
      return { ...prev, [uuid()]: { title: "", comment: "", score: 1 } };
    });
    setshouldsave(true);
  };

  const handleDelete = (key) => {
    setScores((prev) => {
      delete prev?.[key];
      return { ...prev };
    });
    setshouldsave(true);
  };

  const handleTitleChange = (key, value) => {
    setScores((prev) => {
      return {
        ...prev,
        [key]: {
          title: value,
          comment: prev[key]?.comment ?? "",
          score: prev[key]?.score ?? 1,
        },
      };
    });
    setshouldsave(true);
  };

  const handleBulkUpload = (e) => {
    const file = e.target.files[0];
    handleXLSXTOJSON({ sheet: file }, async (json) => {
      json.shift(); // Remove header row if necessary
      const newScores = {};
      json.forEach((row) => {
        const id = uuid();
        newScores[id] = {
          title: row.Country,
          score: row.inherentRiskScore,
          comment: row.comment,
        };
      });
      setScores((prevScores) => ({ ...prevScores, ...newScores }));
      setshouldsave(true);
    });
  };

  const handleFieldChange = (key, value, setTableData) => {
    setTableData((prev) => {
      return { ...prev, [key]: { ...prev[key], comment: value } };
    });
    setshouldsave(true);
  };

  async function Save() {
    if (shouldsave) {
      await Admin.changeCountryRiskScores({
        countryRiskScores: scores,
      });
    }
  }

  const columns = (setFunction) => [
    {
      title: "Country",
      dataIndex: "key",
      key: "key",
      sorter: (a, b) => a.key.localeCompare(b.key),
      render: (e) => (
        <Typography.Paragraph
          editable={{
            onChange: (value) => {
              handleTitleChange(e, value);
            },
          }}
        >
          {scores?.[e]?.title ?? countries?.[e] ?? e}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Inherent Risk Scores",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
      render: (_, record) => (
        <Select
          value={record.score}
          onChange={(value) =>
            handleScoreChange(record.key, value, setFunction)
          }
        >
          {scoreOptions.map((score) => (
            <Option key={score} value={score}>
              {score}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Risk Factor",
      dataIndex: "riskFactor",
      key: "riskFactor",
      render: (_, record) => getScoreTextSelected(record.score),
    },
    {
      title: "Override Applied",
      dataIndex: "comment",
      key: "score",
      render: (_, record) => (
        <Typography.Paragraph
          editable={{
            onChange: (e) => {
              handleFieldChange(record.key, e, setFunction);
            },
          }}
        >
          {record.comment}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_, record) => (
        <Button danger onClick={() => handleDelete(record.key)}>
          Delete
        </Button>
      ),
    },
  ];

  useEffect(() => {
    scoreload();
  }, []);

  return (
    <div>
      <div>
        <h3>Country Risk Score</h3>

        <div className="my-10">
          <Table
            loading={loading}
            columns={columns(setScores)}
            dataSource={Object.entries(scores).map((e, i) => ({
              ...e[1],
              key: e[0],
            }))}
            pagination={false}
            bordered
            footer={() => (
              <Space>
                <Button
                  type="primary"
                  onClick={handleScoreAdd}
                  icon={
                    <AiFillPlusCircle
                      style={{ position: "relative", top: 3 }}
                    />
                  }
                >
                  Add New Country
                </Button>
                <Button type="primary">
                  <label htmlFor="bulk-upload">Bulk Add</label>
                  <Input
                    className="hidden"
                    type="file"
                    id="bulk-upload"
                    onChange={handleBulkUpload}
                  />
                </Button>

                <a className="mx-3" href={SampleBulkAddClients} download={true}>
                  Sample Excel Sheet
                </a>

                {shouldsave && (
                  <Button
                    type="primary"
                    onClick={Save}
                    icon={
                      <AiOutlineSave style={{ position: "relative", top: 3 }} />
                    }
                  >
                    Save
                  </Button>
                )}
              </Space>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
