import Select from "react-select";

const PeriodicReviewData = {
  areAllClientIdentityAndVerificationDocumentsCurrent: {
    value: false,
    title: "Are all client identity and verification documents current ",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },

  anyMaterialChangeOwnershipAndControlOfTheClient: {
    value: false,
    title: "Any material change ownership and control of the client",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  hasClientBeenScreened: {
    value: false,
    title: "Has client been screened",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyPEPassociations: {
    value: false,
    title: "Any PEP associations",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyNewAdverseMediaAssociation: {
    value: false,
    title: "Any new adverse media association",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyNewSanctionsRiskAssociations: {
    value: false,
    title: "Any new sanctions risk associations",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyChangeInRiskRatingOfTheClient: {
    value: false,
    title: "Any change in risk rating of the client",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  areValueAndVolumeOfTransactionsAlignedWithAnticipatedActivityForTheCustomer: {
    value: false,
    title:
      "Are value and/or volume of transactions aligned with anticipated activity for the customer",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyOtherFlags: {
    value: false,
    title: "Any other flags",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        onChange={({ value }) => handleChange(key, value)}
      />
    ),
  },
  anyChangeInProductAndServicesOffered: {
    value: false,
    title: "Any change in products and services offered to the client",
    notes: "",

    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  newTypeOfProductServiceOfferedToClient: {
    value: [],
    title: "New Type of Product Service offered to client",
    notes: "",
    render: (value, data, { key }, handleChange, periodicreview) => {
      return (
        <Select
          isMulti
          isDisabled={
            !periodicreview?.anyChangeInProductAndServicesOffered?.value
          }
          value={value?.map((e) => ({
            value: e,
            label: data?.EntityProductServiceOffered?.[e]?.title,
          }))}
          options={
            Object.keys(data.EntityProductServiceOffered).map((e) => ({
              value: e,
              label: data?.EntityProductServiceOffered?.[e]?.title,
            })) || []
          }
          onChange={(data) =>
            handleChange(
              key,
              data.map((e) => e.value)
            )
          }
        />
      );
    },
  },
};
const PeriodicReviewDataOverride = {
  areAllClientIdentityAndVerificationDocumentsCurrent: {
    value: false,
    title: "Are all client identity and verification documents current ",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },

  anyMaterialChangeOwnershipAndControlOfTheClient: {
    value: false,
    title: "Any material change ownership and control of the client",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  hasClientBeenScreened: {
    value: false,
    title: "Has client been screened",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyPEPassociations: {
    value: false,
    title: "Any PEP associations",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyNewAdverseMediaAssociation: {
    value: false,
    title: "Any new adverse media association",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyNewSanctionsRiskAssociations: {
    value: false,
    title: "Any new sanctions risk associations",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyChangeInRiskRatingOfTheClient: {
    value: false,
    title: "Any change in risk rating of the client",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  areValueAndVolumeOfTransactionsAlignedWithAnticipatedActivityForTheCustomer: {
    value: false,
    title:
      "Are value and/or volume of transactions aligned with anticipated activity for the customer",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  anyOtherFlags: {
    value: false,
    title: "Any other flags",
    notes: "",
    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
        onChange={({ value }) => handleChange(key, value)}
      />
    ),
  },
  anyChangeInProductAndServicesOffered: {
    value: false,
    title: "Any change in products and services offered to the client",
    notes: "",

    render: (_, riskFactorScore, { key }, handleChange) => (
      <Select
        isDisabled={true}
        value={
          _ ? { value: true, label: "Yes" } : { value: false, label: "No" }
        }
        onChange={({ value }) => handleChange(key, value)}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  newTypeOfProductServiceOfferedToClient: {
    value: [],
    title: "New Type of Product Service offered to client",
    notes: "",
    render: (value, data, { key }, handleChange, periodicreview) => {
      return (
        <Select
          isDisabled={true}
          isMulti
          value={value?.map((e) => ({
            value: e,
            label: data?.EntityProductServiceOffered?.[e]?.title,
          }))}
          options={
            Object.keys(data.EntityProductServiceOffered).map((e) => ({
              value: e,
              label: data?.EntityProductServiceOffered?.[e]?.title,
            })) || []
          }
          onChange={(data) =>
            handleChange(
              key,
              data.map((e) => e.value)
            )
          }
        />
      );
    },
  },
};
export { PeriodicReviewData, PeriodicReviewDataOverride };
