import React from "react";
import { Row, Col, Card } from "antd";

import MultiLineChart from "./MultiLineChart";

const PlatformStatistics = () => {
  return (
    <div className="p-4">
      <h3 className="mb-4 my-10">Platform Statistics</h3>

      <Row className="flex items-center  flex-grow gap-1 my-10">
        <Col span={10}>
          <Card className="bg-white shadow-md hover:text-white cursor-pointer hover:bg-blue-900">
            <div className="text-md">Total Users</div>
            <div className="text-xl font-bold mt-2">1,500</div>
          </Card>
        </Col>
        <Col span={10}>
          <Card className="bg-white shadow-md hover:text-white cursor-pointer hover:bg-blue-900">
            <div className="text-md">Total Revenue</div>
            <div className="text-xl font-bold mt-2">$10,000</div>
          </Card>
        </Col>
        <Col span={10}>
          <Card className="bg-white shadow-md hover:text-white cursor-pointer hover:bg-blue-900">
            <div className="text-md">Average Order Value</div>
            <div className="text-xl font-bold mt-2">$50</div>
          </Card>
        </Col>
        <Col span={10}>
          <Card className="bg-white shadow-md hover:text-white cursor-pointer hover:bg-blue-900">
            <div className="text-md">Conversion Rate</div>
            <div className="text-xl font-bold mt-2">5%</div>
          </Card>
        </Col>
      </Row>

      <div className="mt-8">
        <MultiLineChart />
      </div>
    </div>
  );
};

export default PlatformStatistics;
