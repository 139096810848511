import { Button, Input, Skeleton, Space, Switch, Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { selectLoading } from "../../../redux/auth/selectors";
import ClientService from "../../../service/Client";
import {
  default as RiskAssessment,
  default as Risks,
} from "../../../service/RiskAssessment";
import { KYCLevelAppliedOptions } from "../results/Result";
import {
  EntityProductServiceOffered,
  clientCharacteristics,
  deliveryChannelFactors,
  jurisdictionTouchpointsFactors,
  sourceOfFundsFactors,
} from "./data";

export const average = (arr) => {
  if (!arr || arr.length === 0) return 0;
  const sum = arr.reduce((acc, cur) => acc + cur);
  const average = sum / arr.length;
  return average;
};

const Index = () => {
  const { clientId } = useParams();
  const loading = useSelector(selectLoading);

  const [scores, setScores] = useState(null);
  const [clients, setclients] = useState("");
  const [manualOverride, setmanualoveride] = useState(0);
  const [manualOverrideRationale, setmanualOverrideRationale] = useState("");
  const [complianceNotes, setcompliancenotes] = useState("");
  const [PEP, setPEP] = useState(false);
  const [KYCLevelApplied, setKYCLevelApplied] = useState("standard");
  const [adverseMedia, setadverseMedia] = useState(false);
  const [SAR, setSAR] = useState(false);
  const [declined, setdeclined] = useState(false);
  const [sanctions, setsanctions] = useState(false);
  const [incompleteKYC, setincompleteKYC] = useState(false);
  const [scoresjurisdiction, setScoresJurisdiction] = useState(null);
  const [factors, setFactors] = useState(clientCharacteristics);
  const [factors1, setFactors1] = useState(deliveryChannelFactors);
  const [factors2, setFactors2] = useState(jurisdictionTouchpointsFactors);
  const [factors3, setFactors3] = useState(sourceOfFundsFactors);
  const [factorsEntityProduct, setFactorsEntityProduct] = useState(
    EntityProductServiceOffered
  );
  const [expectedTransactionValue, setexpectedTransactionValue] = useState(0);
  const [expectedTransactionVolume, setexpectedTransactionVolume] = useState(0);
  const [assessmentDate, setAssessmentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [period, setperiod] = useState(
    new Date(new Date().setDate(new Date().getDate() + 32))
      .toISOString()
      .split("T")[0]
  );
  const [onboarding, setOnboarding] = useState(
    new Date(new Date().setDate(new Date().getDate() + 32))
      .toISOString()
      .split("T")[0]
  );

  const navigate = useNavigate();

  const handleSubmit = useCallback(async () => {
    if (
      !factorsEntityProduct[0]?.value ||
      factorsEntityProduct[0]?.value?.length === 0
    )
      return message.info(
        "You must select products / services / transactions offered"
      );
    if (factors.every((f) => f.factor === "" || f.factor?.length === 0))
      return message.info("You must select at least one client characteristic");
    if (factors1.every((f) => f.factor === "" || f.factor?.length === 0))
      return message.info("You must select at least one delivery channel");
    if (factors3.every((f) => f.factor === "" || f.factor?.length === 0))
      return message.info("You must select at least one source of funds");
    if (factors2.every((f) => f.country === "" || f.country?.length === 0))
      return message.info("You must select at least one country");

    const formData = {
      riskAssessmentForm: {
        EntityProductServiceOffered: {
          typeOfProductServiceOfferedToClient: factorsEntityProduct[0].value
            ?.map?.((v) => v?.value ?? v)
            ?.filter?.((a) => !!a),
        },
        ClientCharacteristics: transformer(factors),
        DeliveryChannelsRisk: transformer(factors1),
        jurisdictionTouchPoints: countries(factors2),
        SourceofFundsTransaction: transformer(factors3),
        manualOverride: manualOverride,
        manualOverrideRationale: manualOverrideRationale,
        complianceNotes: complianceNotes,
        PEP: PEP,
        KYCLevelApplied,
        adverseMedia: adverseMedia,
        SAR: SAR,
        declined: declined,
        sanctions: sanctions,
        incompleteKYC: incompleteKYC,
        client: clientId,
        expectedTransactionValue: parseInt(expectedTransactionValue),
        expectedTransactionVolume: parseInt(expectedTransactionVolume),
        assessmentDate: new Date(assessmentDate).toISOString(),
        periodicReviewDate: new Date(period).toISOString(),
        onboardingDate: new Date(onboarding).toISOString(),
        period: "monthly",
      },
    };
    if (
      factorsEntityProduct?.[0]?.value
        ?.map?.((v) => scores?.EntityProductServiceOffered?.[v?.value ?? v])
        ?.some?.((x) => x?.prohibited)
    )
      message.info(
        "Business with this client is restricted under the policy, escalate to the MLRO or Senior Management immediately"
      );

    RiskAssessment.createRiskAssessment(formData).then((res) => {
      message.success("Risk Assessment Created Successfully");
      navigate("/app/editriskassessment/" + res.data._id);
    });
  }, [
    clientId,
    PEP,
    KYCLevelApplied,
    SAR,
    adverseMedia,
    assessmentDate,
    complianceNotes,
    declined,
    expectedTransactionValue,
    expectedTransactionVolume,
    factors,
    factors1,
    factors2,
    factors3,
    factorsEntityProduct,
    incompleteKYC,
    manualOverride,
    manualOverrideRationale,
    navigate,
    period,
    onboarding,
    sanctions,
  ]);
  const scoreload = async () => {
    const { data } = await Risks.getRiskFactorScores();

    setScores(data);
  };

  const scoreload2 = async () => {
    const { data } = await Risks.getCountryRiskScores();

    setScoresJurisdiction(data);
  };

  const loadClient = async () => {
    const { data } = await ClientService.getClient(clientId);
    setclients(data);
  };

  useEffect(() => {
    scoreload();
    loadClient();
    scoreload2();
  }, []);

  const handleCommentsChange = (index, value, setFactors) => {
    setFactors((prevFactors) =>
      prevFactors?.map((factor, i) =>
        i === index ? { ...factor, selection: value } : factor
      )
    );
    setFactors((prevFactors) =>
      prevFactors.map((factor, i) =>
        i === index
          ? {
              ...factor,
              show: value === "Yes",
              initialScore: value !== "Yes" ? 0 : factor.initialScore,
            }
          : factor
      )
    );
  };

  const columns = (vals, setter, comments = true, factorSelectconfig) => [
    {
      title: "Factors",
      dataIndex: "name",
      key: "name",
    },
    comments && !factorSelectconfig?.hideComments
      ? {
          title: "Comments: Yes/No/NA",
          dataIndex: "selection",
          key: "selection",
          render: (text, record, index) => (
            <Select
              options={[
                { value: "NA", label: "NA" },
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ]}
              className="w-full"
              onChange={(value) => {
                handleCommentsChange(index, value.value, setter);
                if (value.value !== "Yes") {
                  setter((prevFactors) =>
                    prevFactors.map((factor, i) => {
                      return i === index
                        ? {
                            ...factor,
                            initialScore: 0,
                            factor: "",
                            value: "",
                          }
                        : factor;
                    })
                  );
                }
              }}
              value={{ label: text, value: text }}
            ></Select>
          ),
        }
      : {},
    {
      title: "Factor",
      dataIndex: "show",
      key: "show",
      render: (text, record, index) =>
        text ? (
          <Select
            isMulti={record?.isMulti}
            {...factorSelectconfig}
            className="w-full"
            options={Object.keys(vals).map((key) => {
              return {
                value: key,
                label: vals[key].title,
              };
            })}
            onChange={(value) => {
              setter((prevFactors) =>
                prevFactors.map((factor, i) => {
                  return i === index
                    ? {
                        ...factor,
                        initialScore:
                          record?.isMulti && Array.isArray(value)
                            ? Math.max(
                                0,
                                ...value.map((v) => Number(vals[v.value].score))
                              )
                            : Array.isArray(value)
                            ? average(
                                value.map((x) => vals[x.value].score)
                              ).toFixed(1)
                            : Number(vals[value.value].score),
                        factor: Array.isArray(value)
                          ? value.map((v) => v.value)
                          : value.value,
                        value: Array.isArray(value)
                          ? value.map((v) => v)
                          : value,
                      }
                    : factor;
                })
              );
            }}
            style={{ width: "200px" }}
          ></Select>
        ) : null,
    },
    comments
      ? {
          title: "Initial Score",
          dataIndex: "initialScore",
          key: "initialScore",
        }
      : {},
  ];

  const columnJurisdiction = [
    {
      title: "Factors",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Factor",

      render: (__, _, index) => {
        return (
          <Space>
            <div style={{ width: "200px" }}>
              <Select
                isMulti={_?.isMulti}
                className="w-full"
                options={Object.keys(scoresjurisdiction).map((key) => {
                  return {
                    value: key,
                    label: scoresjurisdiction?.[key]?.title ?? key,
                  };
                })}
                value={
                  Array.isArray(factors2?.[index]?.country)
                    ? factors2?.[index]?.country.map((country) => ({
                        value: country,
                        label: scoresjurisdiction?.[country]?.title ?? country,
                      }))
                    : {
                        value: factors2?.[index]?.country,
                        label:
                          scoresjurisdiction?.[factors2?.[index]?.country]
                            ?.title ?? factors2?.[index]?.country,
                      }
                }
                onChange={(value) => {
                  setFactors2((prevFactors) =>
                    prevFactors.map((factor, i) => {
                      return i === index
                        ? {
                            ...factor,
                            initialScore: Array.isArray(value)
                              ? Math.max(
                                  0,
                                  ...value.map((v) =>
                                    Number(scoresjurisdiction[v.value].score)
                                  )
                                )
                              : Number(scoresjurisdiction[value.value].score),
                            country: Array.isArray(value)
                              ? value.map((v) => v.value)
                              : value.value,
                          }
                        : factor;
                    })
                  );
                }}
              />
            </div>
            {!_?.isMulti && (
              <Button
                onClick={() => {
                  setFactors2((prevFactors) =>
                    prevFactors.map((factor, i) => {
                      return i === index
                        ? {
                            ...factor,
                            initialScore: 0,
                            country: "",
                          }
                        : factor;
                    })
                  );
                }}
              >
                x
              </Button>
            )}
          </Space>
        );
      },
    },
    {
      title: "Initial Score",
      dataIndex: "initialScore",
      key: "initialScore",
    },
  ];

  const data = (factors) => {
    return factors.map((factor, index) => ({
      key: `factors1__${index}`,
      ...factor,
    }));
  };

  if ([scores, scoresjurisdiction].some((s) => !s)) return <Skeleton active />;

  const transformer = (fac) => {
    const obj = {};
    fac.forEach((e) => {
      obj[e.key] = {
        factor: e.factor ?? e.value,
        selection: e.selection,
      };
    });
    return obj;
  };
  const countries = (fac) => {
    const obj = {};
    fac.forEach((e) => {
      obj[e.key] = {
        country: e.country,
      };
    });
    return obj;
  };

  return (
    <div>
      <h3>Risk Assessment</h3>

      <div className="my-10">
        <h5 className="">Products / Services / Transactions</h5>
        <Table
          columns={columns(
            scores["EntityProductServiceOffered"],
            setFactorsEntityProduct,
            true,
            { isMulti: true, hideComments: true }
          )}
          dataSource={data(factorsEntityProduct)}
          pagination={false}
        />
      </div>
      <div className="my-10">
        <h5 className="">Client Characteristics</h5>
        <Table
          columns={columns(scores["ClientCharacteristics"], setFactors)}
          dataSource={data(factors)}
          pagination={false}
        />
      </div>

      <div className="my-10">
        <h5 className=""> Delivery Channel</h5>
        <Table
          columns={columns(scores["DeliveryChannelsRisk"], setFactors1)}
          dataSource={data(factors1)}
          pagination={false}
        />
      </div>

      <div className="my-10">
        <h5 className="">Jurisdiction TouchPoint</h5>
        <Table
          columns={columnJurisdiction}
          dataSource={data(factors2)}
          pagination={false}
        />
      </div>

      <div className="my-5">
        <h5 className="">Source of Funds / Transactions</h5>
        <Table
          columns={columns(scores["SourceofFundsTransaction"], setFactors3)}
          dataSource={data(factors3)}
          pagination={false}
        />
      </div>

      <div className=" my-10 flex justify-between font-bold">
        <div>Final Overall Score</div>
        <div>0</div>
      </div>

      <div className=" mb-2 ">
        <label className="!font-bold">Manual Override</label>
        <Input
          value={manualOverride}
          onChange={(e) => {
            console.log(typeof e.target.value);
            if (e.target.value <= 0 || e.target.value === "") {
              setmanualOverrideRationale("");
            }
            setmanualoveride(parseInt(e.target.value));
          }}
          type="number"
          className="w-[100px]"
          placeholder="Manual Overide"
        />
      </div>
      {manualOverride !== 0 && (
        <div className=" mb-2 ">
          <label className="!font-bold">Manual Override Rationale</label>
          <Input
            value={manualOverrideRationale}
            onChange={(e) => setmanualOverrideRationale(e.target.value)}
            type="text"
            placeholder="Manual Override Rationale"
          />
        </div>
      )}

      <div className=" w-[100%] flex flex-col gap-2 my-10">
        {clients?.transactionType && (
          <div className="  ">
            <label className="!font-bold" htmlFor="periodicDate">
              Next Periodic Date
            </label>
            <Input
              value={period}
              onChange={(e) => {
                if (!e.target.value) return;
                setperiod(e.target.value);
              }}
              id="periodicDate"
              type="date"
            />
          </div>
        )}

        <div className="  ">
          <label className="!font-bold" htmlFor="periodicDate">
            Assessment Date
          </label>
          <Input
            value={assessmentDate}
            onChange={(e) => {
              if (!e.target.value) return;
              setAssessmentDate(e.target.value);
            }}
            id="periodicDate"
            type="date"
          />
        </div>
      </div>

      <hr />

      <Button
        type="primary"
        className="my-5"
        disabled={loading}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </div>
  );
};

export default Index;
