import axios from "axios";
import { prepareNetworkGraphData } from "../utils/prepareNetworkGraphData";

const BASE_WIKIDATA_SPARQL_URL = "https://query.wikidata.org/sparql?query=";

const wikidataClient = (query) => {
  return axios.get(BASE_WIKIDATA_SPARQL_URL + query);
};

export const getNetworkGraphData = async (id) => {
  if (!id) throw new Error("id is required");

  const sparqlQueryForNetworkGraph =
    encodeURIComponent(`SELECT ?subjectLabel ?subjectImage ?propertyLabel ?objectLabel ?image WHERE {
    {
      BIND(wd:${id} AS ?subject)
      ?subject rdfs:label ?subjectLabel.
      FILTER((LANG(?subjectLabel)) = "en")
      OPTIONAL { ?subject wdt:P18 ?subjectImage. }
    }
    ?subject ?property ?object.
    ?object rdfs:label ?objectLabel.
    FILTER((LANG(?objectLabel)) = "en")
    ?s wikibase:directClaim ?property;
      rdfs:label ?propertyLabel.
    FILTER((LANG(?propertyLabel)) = "en")
    OPTIONAL { ?object wdt:P18 ?image. }
  } LIMIT 30`);

  const response = await wikidataClient(sparqlQueryForNetworkGraph);

  if (response.status !== 200 || !response.data.results)
    throw new Error("something went wrong while fetching network graph data");

  const data = prepareNetworkGraphData(response.data.results.bindings);

  return data;
};
