import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Landing from "./pages/Landing";
import Auth from "./pages/auth";
import Contact from "./pages/contact";
import Dashboard from "./pages/dashboard";
import Legal from "./pages/legal";
import Public from "./service/Public";

function usePageViewsGoogleAnalytics() {
  let location = useLocation();
  useEffect(() => {
    const execute = async () => {
      if (!window?.GA_INITIALIZED) {
        const code = await Public.getGALink();
        console.log(code.data.link);
        ReactGA.initialize(code.data.link);
        window.GA_INITIALIZED = true;
      }
      ReactGA.set({ page: location.pathname });
      ReactGA.ga("set", "anonymizeIp", true);
      ReactGA.pageview(location.pathname);
    };
    execute();
  }, [location]);
}

const AppRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const rValue = queryParams.get("r");
    if (rValue) {
      localStorage.rValue = rValue;
    }
  }, [location]);

  usePageViewsGoogleAnalytics();

  return (
    <>
      <Routes>
        <Route path="/home" element={<Landing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/auth/*" element={<Auth />} />
        <Route path="/app/*" element={<Dashboard />} />
        <Route path="/legal/*" element={<Legal />} />
        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
