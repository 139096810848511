import {
  CheckOutlined,
  DeleteOutlined,
  HighlightOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Modal,
  Popconfirm,
  Skeleton,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { setLoading } from "../../../redux/auth/actions";
import { store } from "../../../redux/store";
import Admin from "../../../service/Admin";
import Public from "../../../service/Public";
import { uploadToCloudinary } from "../ContentManagement/LandingPage";
import FileUpload from "./FileUpload";

const AdminTutorials = () => {
  const [tutorials, setTutorials] = useState([]);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [thumbnailUpload, setThumbnailUpload] = useState(false);

  const handleOpenUploadModal = () => {
    setUploadModalVisible(true);
  };
  const handleCloseUploadModal = () => {
    setUploadModalVisible(false);
  };
  const handleCloseThumbnailUploadModal = () => {
    setThumbnailUpload(false);
  };

  const getData = useCallback(async () => {
    const res = await Public.getTutorials();
    setTutorials(res.data);
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  // Handler for uploading a new tutorial
  const handleUpload = useCallback(
    async (file) => {
      // Logic to handle tutorial upload
      // Update the tutorials state with the uploaded tutorial

      store.dispatch(setLoading(true));
      try {
        const cloudinaryResponse = await uploadToCloudinary(file);

        const newTutorial = {
          thumbnail: "",
          title: file.name,
          src: cloudinaryResponse.secure_url,
        };
        await Admin.addTutorial(newTutorial);
        await getData();

        message.success("Tutorial uploaded");
        handleCloseUploadModal();
      } catch (e) {
      } finally {
        store.dispatch(setLoading(false));
      }
    },
    [getData]
  );

  const handleUploadThumbnail = useCallback(
    async (file) => {
      if (!thumbnailUpload) return;

      const cloudinaryResponse = await uploadToCloudinary(file);
      await Admin.updateTutorial(thumbnailUpload._id, {
        thumbnail: cloudinaryResponse.secure_url,
      });
      await getData();

      message.success("Thumbnail updated");
      handleCloseThumbnailUploadModal();
    },
    [getData, thumbnailUpload]
  );

  // Handler for deleting a tutorial
  const handleDeleteTutorial = async (tutorialId) => {
    // Logic to delete the tutorial
    await Admin.deleteTutorial(tutorialId);
    await getData();

    message.success("Tutorial deleted");
  };

  const handleChangeTitle = async (text, id) => {
    await Admin.updateTutorial(id, { title: text });
    await getData();
  };

  const onDragEnd = async (result) => {
    const { destination, source } = result;
    if (!destination) return;

    if (source.index === destination.index) return;

    const newTutorials = Array.from(tutorials);
    const [movedTutorial] = newTutorials.splice(source.index, 1);
    newTutorials.splice(destination.index, 0, movedTutorial);

    // Update sequence for each tutorial and sync with backend
    await Promise.all(
      newTutorials.map(async (tutorial, index) => {
        await Admin.updateTutorial(tutorial._id, { sequence: index });
      })
    );

    await getData();
  };

  return (
    <>
      <FileUpload />

      <Divider />

      <h3>Tutorials</h3>
      {/* Tutorial Upload Modal */}
      <Modal
        title="Upload Tutorial"
        open={uploadModalVisible}
        onCancel={handleCloseUploadModal}
        footer={null}
      >
        <Upload.Dragger
          name="tutorial"
          accept=".mp4"
          beforeUpload={(file) => {
            handleUpload(file);
            return false; // Prevent automatic upload
          }}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <PlusOutlined />
          </p>
          <p className="ant-upload-text">Click or Drag and Drop</p>
          <p className="ant-upload-hint">Supported: .mp4</p>
        </Upload.Dragger>
      </Modal>

      <Modal
        title="Update Thumnail"
        open={thumbnailUpload}
        onCancel={() => setThumbnailUpload(false)}
        footer={null}
      >
        <Upload.Dragger
          name="tutorial"
          accept="[.jpeg,.png,.jpg]"
          beforeUpload={(file) => {
            handleUploadThumbnail(file);
            return false; // Prevent automatic upload
          }}
          showUploadList={false}
        >
          <p className="ant-upload-drag-icon">
            <PlusOutlined />
          </p>
          <p className="ant-upload-text">Click or Drag and Drop</p>
          <p className="ant-upload-hint">Supported: .jpeg, .png</p>
        </Upload.Dragger>
      </Modal>

      {/* Tutorial Management */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tutorial-list">
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2"
            >
              {tutorials
                .sort((a, b) => a.sequence - b.sequence)
                .map((tutorial, i) => (
                  <Draggable
                    key={tutorial._id}
                    draggableId={tutorial._id}
                    index={i}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="border p-4"
                      >
                        <div key={i} className="border p-4">
                          {tutorial.thumbnail ? (
                            <img
                              src={tutorial.thumbnail}
                              alt={" "}
                              className="w-full h-32 object-cover"
                            />
                          ) : (
                            <div className="flex justify-center items-center">
                              <Skeleton.Image />
                            </div>
                          )}

                          <div className="mt-2">
                            <Typography.Paragraph
                              editable={{
                                icon: <HighlightOutlined />,
                                tooltip: "click to edit text",
                                onChange: (e) =>
                                  handleChangeTitle(e, tutorial._id),
                                enterIcon: <CheckOutlined />,
                              }}
                            >
                              {tutorial.title}
                            </Typography.Paragraph>
                          </div>
                          <div className="mt-2 flex justify-between items-center">
                            <Button
                              type="link"
                              onClick={() => setThumbnailUpload(tutorial)}
                            >
                              New Thumbnail
                            </Button>
                            <Popconfirm
                              title="Are you sure to delete?"
                              onConfirm={() =>
                                handleDeleteTutorial(tutorial._id)
                              }
                            >
                              <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        type="primary"
        className="btn-primary mt-4"
        onClick={handleOpenUploadModal}
      >
        New Tutorial
      </Button>
    </>
  );
};

export default AdminTutorials;
