import React from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "./redux/auth/selectors";

const LoadingIndicator = () => {
  const loading = useSelector(selectLoading);

  if (!loading) return <></>;
  return (
    <>
      <div className="fixed right-0 top-0 mt-2 mr-2" style={{ zIndex: 3000 }}>
        <div className="flex justify-center items-center">
          <div className="relative w-12 h-12 animate-spin rounded-full bg-gradient-to-r from-blue-400 via-blue-600 to-blue-500 "></div>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-3 -translate-y-3 w-6 h-6 bg-gray-200 rounded-full border-2 border-white" />
        </div>
      </div>
    </>
  );
};

export default LoadingIndicator;
