export const translationEn = {
  Login: "Login",
  Email: "Email",
  "Enter email": "Enter email",
  "Enter password": "Enter password",
  Password: "Password",
  "Forgot password?": "Forgot password?",
  "Don't have an account yet?": "Don't have an account yet?",
  Register: "Register",
  "Reset Password": "Reset Password",
  Reset: "Reset",
  "By registering, you accept our": "By registering, you accept our",
  "Already registered?": "Already registered?",
  "Privacy policy": "Privacy policy",
};
export const translationDe = {
  Login: "Anmelden",
  Email: "E-Mail",
  "Enter email": "E-Mail eingeben",
  "Enter password": "Passwort eingeben",
  Password: "Passwort",
  "Forgot password?": "Passwort vergessen?",
  "Don't have an account yet?": "Noch nicht registriert?",
  Register: "Registrieren",
  "Reset Password": "Passwort Zurücksetzen",
  Reset: "Zurücksetzen",
  "By registering, you accept our": "Beim Registrieren, akzeptieren Sie unsere",
  "Already registered?": "Bereits registriert?",
  "Privacy policy": "Datenschutzerklärung",
};
