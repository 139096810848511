import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class Admin {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  sendNotification({ associatedUsers, message }) {
    return this.api.post("/sendNotification", { associatedUsers, message });
  }

  listUsers({ role, accountActivated, name, limit, page }) {
    console.log({
      role,
      accountActivated,
      name,
      limit,
      page,
    });
    return this.api.get("/listUsers", {
      params: {
        role,
        accountActivated,
        name,
        limit,
        page,
      },
    });
  }

  userDetails({ userId }) {
    return this.api.get("/userDetails", {
      params: {
        userId,
      },
    });
  }
  getNotificationConfig() {
    return this.api.get("/getnotificationconfig");
  }

  setAccountActivated({ userId, accountActivated }) {
    return this.api.put("/setAccountActivated", { userId, accountActivated });
  }

  getSubscriptionPackages() {
    return this.api.get("/getSubscriptionPackages");
  }

  updateSubscriptionPackages({ subscriptions }) {
    return this.api.put("/updateSubscriptionPackages", { subscriptions });
  }

  getNotifications() {
    return this.api.get("/getNotifications");
  }

  updateNotifications({ notifications }) {
    return this.api.put("/updateNotifications", { notifications });
  }

  getWeightingTable() {
    return this.api.get("/getWeightingTable");
  }

  changeWeightingTable({ weightingTable }) {
    return this.api.put("/changeWeightingTable", { weightingTable });
  }

  getRiskFactorScores() {
    return this.api.get("/getRiskFactorScores");
  }

  changeRiskFactorScores({ riskFactorScores }) {
    return this.api.put("/changeRiskFactorScores", { riskFactorScores });
  }

  getCountryRiskScores() {
    return this.api.get("/getCountryRiskScores");
  }

  changeCountryRiskScores({ countryRiskScores }) {
    return this.api.put("/changeCountryRiskScores", { countryRiskScores });
  }

  changePrivacy({ privacyPolicy }) {
    return this.api.put("/changePrivacy", { privacyPolicy });
  }

  changeTerms({ termsAndConditions }) {
    return this.api.put("/changeTerms", { termsAndConditions });
  }

  changeCopyright({ copyright }) {
    return this.api.put("/changeCopyright", { copyright });
  }
  changeOtherLegal({ otherLegal }) {
    return this.api.put("/changeOtherLegal", { otherLegal });
  }

  changeHowItWorks({ howItWorks }) {
    return this.api.put("/changeHowItWorks", { howItWorks });
  }

  changeAboutUs({ aboutUs }) {
    return this.api.put("/changeAboutUs", { aboutUs });
  }
  updateUserDetails({ userId, update }) {
    return this.api.put("/updateuser", { update, userId });
  }
  triggerSubscriptionReminderInOneYear({ userId }) {
    return this.api.post("/triggerSubscriptionReminderInOneYear", { userId });
  }

  setHeroImage(body, key) {
    return this.api.put(`/setHeroImage?key=${key}`, body);
  }

  setPricing({ pricing }) {
    return this.api.put("/setPricing", { pricing });
  }

  setFAQs({ FAQs }) {
    return this.api.put("/setFAQs", { FAQs });
  }

  setEmails({ emails }) {
    return this.api.put("/setEmails", { emails });
  }
  setScreeningPrice({ screeningPrice }) {
    return this.api.put("/setScreeningPrice", { screeningPrice });
  }
  sendBulkEmail({ subject, message, query }) {
    return this.api.post("/sendBulkEmail", { subject, message, query });
  }

  addTutorial({ thumbnail, title, src }) {
    return this.api.post("/addTutorial", { thumbnail, title, src });
  }
  updateTutorial(id, { thumbnail, title, src, sequence }) {
    return this.api.put(`/updateTutorial?id=${id}`, {
      thumbnail,
      title,
      src,
      sequence,
    });
  }
  deleteTutorial(id) {
    return this.api.delete(`/deleteTutorial?id=${id}`);
  }

  addFile({ title, src }) {
    return this.api.post("/addFile", { title, src });
  }
  updateFile(id, { title, src, sequence }) {
    return this.api.put(`/updateFile?id=${id}`, {
      title,
      src,
      sequence,
    });
  }
  deleteFile(id) {
    return this.api.delete(`/deleteFile?id=${id}`);
  }
  addUpcommingChange({ title, src }) {
    return this.api.post("/addUpcommingChange", { title, src });
  }
  updateUpcommingChange(id, { title, src, sequence }) {
    return this.api.put(`/updateUpcommingChange?id=${id}`, {
      title,
      src,
      sequence,
    });
  }
  deleteUpcommingChange(id) {
    return this.api.delete(`/deleteUpcommingChange?id=${id}`);
  }
}

export default new Admin(`${getBackendUrl()}/admin`);
