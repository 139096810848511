import { Button, Dropdown, Skeleton, Table, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoading } from "../../../redux/auth/selectors";
import RiskAssessment from "../../../service/RiskAssessment";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { exportToExcel } from "../Clients/clientByUser";

const EscalationReport = () => {
  const [Reports, setReports] = useState(null);
  const loading = useSelector(selectLoading);
  useEffect(() => {
    const loadClients = async () => {
      const response = await RiskAssessment.getEscalationReport({});
      setReports(
        response.data["riskReports"].map((t) => ({ ...t, key: t?._id }))
      );
    };
    loadClients();
  }, []);

  const columns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
    },
    {
      title: "MLRO / Senior Manager",
      dataIndex: "latestApproverDisapprover",
      render: (e) => e?.name,
    },
    {
      title: "Approved",
      dataIndex: "isApproved",
      render: (e) => (
        <Tag color={e ? "green" : "magenta"}>{e ? "Yes" : "No"}</Tag>
      ),
    },
    {
      title: "Declined",
      dataIndex: "isDisapproved",
      render: (e) => (
        <Tag color={e ? "green" : "magenta"}>{e ? "Yes" : "No"}</Tag>
      ),
    },
  ];
  if (!Reports) {
    return <Skeleton active />;
  }
  return (
    <>
      <Table
        className="w-full max-w-2xl"
        dataSource={Reports}
        columns={columns}
        loading={loading}
        pagination={true}
        footer={() => (
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: "export-excel",
                  label: (
                    <Button
                      onClick={() =>
                        exportToExcel(
                          JSON.parse(JSON.stringify(Reports)).map((e) => {
                            delete e?.clientId;
                            delete e?.clientGroupId;
                            return e;
                          })
                        )
                      }
                    >
                      Excel
                    </Button>
                  ),
                },
                {
                  key: "export-pdf",
                  label: (
                    <Button
                      onClick={() =>
                        handleGeneratePDF({
                          header: "Reports",
                          ContentColumn: columns.filter(
                            (c) => c.dataIndex !== "rating"
                          ),
                          DataSource: Reports,
                        })
                      }
                    >
                      PDF
                    </Button>
                  ),
                },
              ],
            }}
            arrow={{
              pointAtCenter: true,
            }}
          >
            Export
          </Dropdown.Button>
        )}
      />
    </>
  );
};

export default EscalationReport;
