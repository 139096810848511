export const countries = {
  AlandIslands: "Åland Islands",
  SvalbardandMayen: "Svalbard and Mayen",
  Tokelau: "Tokelau",
  Finland: "Finland",
  Faroeislands: "Faroe islands",
  Iceland: "Iceland",
  Norway: "Norway",
  Denmark: "Denmark",
  Greenland: "Greenland",
  SanMarino: "San Marino",
  Sweden: "Sweden",
  Estonia: "Estonia",
  VaticanCityState: "Vatican City State (Holy See)",
  Lithuania: "Lithuania",
  Andorra: "Andorra",
  Bermuda: "Bermuda",
  NewZealand: "New Zealand",
  Liechtenstein: "Liechtenstein",
  ChristmasIsland: "Christmas Island",
  Cocos: "Cocos (Keeling) Islands",
  NorfolkIsland: "Norfolk Island",
  FrenchPolynesia: "French Polynesia",
  Mayotte: "Mayotte",
  NewCaledonia: "New Caledonia",
  SaintBerthelemy: "Saint Berthélemy",
  SaintMartin: "Saint Martin (French part)",
  SaintPierreandMiquelon: "Saint Pierre and Miquelon",
  WallisandFutuna: "Wallis and Futuna",
  BruneiDarussalam: "Brunei Darussalam",
  BritishIndianOceanTerritory: "British Indian Ocean Territory",
  FalklandIslands: "Falkland Islands (Malvinas)",
  FrenchGuiana: "French Guiana",
  Pitcairn: "Pitcairn",
  SaintHelena: "Saint Helena, Ascension and Tristan",
  Guadeloupe: "Guadeloupe",
  Martinique: "Martinique",
  Reunion: "Réunion",
  Latvia: "Latvia",
  Portugal: "Portugal",
  Singapore: "Singapore",
  Bonaire: "Bonaire, Sint Eustatius and Saba",
  SouthKorea: "South Korea ",
  Uruguay: "Uruguay",
  PuertoRico: "Puerto Rico",
  Oman: "Oman",
  Australia: "Australia",
  Guernsey: "Guernsey ",
  Namibia: "Namibia",
  Austria: "Austria",
  Japan: "Japan",
  Bhutan: "Bhutan",
  CzechRepublic: "Czech Republic",
  Malawi: "Malawi",
  Mongolia: "Mongolia",
  Macau: "Macau",
  IsleOfMan: "Isle Of Man",
  Belgium: "Belgium",
  Poland: "Poland",
  Luxembourg: "Luxembourg",
  Jersey: "Jersey ",
  Ireland: "Ireland",
  Germany: "Germany",
  AmericanSamoa: "American Samoa",
  Botswana: "Botswana",
  France: "France",
  NorthMarianaIslands: "North Mariana Islands",
  Hungary: "Hungary",
  Mauritius: "Mauritius",
  Switzerland: "Switzerland",
  Fiji: "Fiji",
  Malta: "Malta",
  Zambia: "Zambia",
  CookIslands: "Cook Islands",
  Guam: "Guam",
  Georgia: "Georgia",
  Slovakia: "Slovakia",
  Spain: "Spain",
  Taiwan: "Taiwan",
  SolomonIslands: "Solomon Islands",
  Canada: "Canada",
  Monaco: "Monaco",
  Mauritania: "Mauritania",
  UnitedStatesVirginIslands: "United States Virgin Islands",
  Kuwait: "Kuwait",
  Netherlands: "Netherlands",
  Slovenia: "Slovenia",
  Gambia: "Gambia",
  Aruba: "Aruba",
  MarshallIslands: "Marshall Islands",
  CapeVerde: "Cape Verde",
  Qatar: "Qatar ",
  Niue: "Niue",
  Rwanda: "Rwanda",
  Tonga: "Tonga",
  Romania: "Romania",
  SaudiArabia: "Saudi Arabia",
  UnitedKingdom: "United Kingdom",
  Greece: "Greece",
  CostaRica: "Costa Rica",
  Italy: "Italy",
  Chile: "Chile",
  Nauru: "Nauru",
  Lesotho: "Lesotho",
  Montserrat: "Montserrat",
  Bulgaria: "Bulgaria",
  Ghana: "Ghana",
  TimorLeste: "Timor-Leste",
  DominicanRepublic: "Dominican Republic",
  Kazakhstan: "Kazakhstan",
  UnitedStates: "United States",
  Anguilla: "Anguilla",
  HongKong: "Hong Kong",
  Uzbekistan: "Uzbekistan",
  AntiguaandBarbuda: "Antigua and Barbuda",
  Grenada: "Grenada",
  Togo: "Togo",
  Micronesia: "Micronesia",
  SouthAfrica: "South Africa",
  SriLanka: "Sri Lanka",
  Eswatini: "Eswatini",
  Maldives: "Maldives",
  Cyprus: "Cyprus",
  Samoa: "Samoa",
  Bahrain: "Bahrain",
  Tuvalu: "Tuvalu",
  Kyrgyzstan: "Kyrgyzstan",
  Argentina: "Argentina",
  Madagascar: "Madagascar",
  Guatemala: "Guatemala",
  PapuaNewGuinea: "Papua New Guinea",
  Palau: "Palau",
  Suriname: "Suriname",
  Congo: "Congo (Brazzaville)",
  Croatia: "Croatia",
  StKittsNevis: "St Kitts & Nevis",
  Gabon: "Gabon",
  SaoTomePrin: "Sao Tome & Prin.",
  ElSalvador: "El Salvador",
  Bangladesh: "Bangladesh",
  EquatorialGuinea: "Equatorial Guinea",
  Honduras: "Honduras",
  StVincentGren: "St Vincent & Gren",
  Belize: "Belize",
  Kiribati: "Kiribati",
  Curacao: "Curacao",
  Angola: "Angola",
  Bahamas: "Bahamas",
  Turkmenistan: "Turkmenistan",
  TurksCaicos: "Turks & Caicos",
  Paraguay: "Paraguay",
  Armenia: "Armenia",
  Malaysia: "Malaysia",
  Moldova: "Moldova",
  Seychelles: "Seychelles",
  Peru: "Peru",
  Egypt: "Egypt",
  BritishVirginIslands: "British Virgin Islands",
  Guyana: "Guyana",
  SierraLeone: "Sierra Leone",
  StLucia: "St Lucia",
  Algeria: "Algeria",
  Mexico: "Mexico",
  Bolivia: "Bolivia",
  NorthMacedonia: "North Macedonia",
  Serbia: "Serbia",
  Comoros: "Comoros",
  Ethiopia: "Ethiopia",
  Montenegro: "Montenegro",
  Djibouti: "Djibouti",
  Indonesia: "Indonesia",
  Benin: "Benin",
  Niger: "Niger",
  Tajikistan: "Tajikistan",
  Azerbaijan: "Azerbaijan",
  Vietnam: "Vietnam",
  Ecuador: "Ecuador",
  Belarus: "Belarus",
  Dominica: "Dominica ",
  India: "India",
  Nepal: "Nepal",
  Colombia: "Colombia",
  Cameroon: "Cameroon",
  CoteDIvoire: "Cote D'Ivoire",
  Thailand: "Thailand",
  LaoPeoplesDemocraticRepublic: "Lao People's Democratic Republic",
  Tunisia: "Tunisia",
  China: "China",
  Israel: "Israel",
  Brazil: "Brazil",
  StMaarten: "St Maarten",
  Chad: "Chad",
  Gibraltar: "Gibraltar",
  BosniaHerzegovina: "Bosnia-Herzegovina",
  Liberia: "Liberia",
  CaymanIslands: "Cayman Islands",
  Vanuatu: "Vanuatu",
  Nigeria: "Nigeria",
  TrinidadTobago: "Trinidad & Tobago",
  Guinea: "Guinea",
  Sudan: "Sudan",
  UnitedArabEmirates: "United Arab Emirates",
  Cambodia: "Cambodia",
  Kenya: "Kenya",
  Ukraine: "Ukraine",
  Eritrea: "Eritrea",
  Senegal: "Senegal",
  Barbados: "Barbados",
  Jordan: "Jordan",
  Kosovo: "Kosovo",
  Cuba: "Cuba",
  Jamaica: "Jamaica",
  Uganda: "Uganda",
  BurkinaFaso: "Burkina Faso",
  Morocco: "Morocco",
  WesternSahara: "Western Sahara",
  GuineaBissau: "Guinea Bissau",
  CentralAfricanRep: "Central African Rep",
  Albania: "Albania",
  Pakistan: "Pakistan",
  Venezuela: "Venezuela",
  WestBank: "West Bank (Palestinian Territory, Occupied)",
  GazaStrip: "Gaza Strip",
  Panama: "Panama",
  Zimbabwe: "Zimbabwe",
  Tanzania: "Tanzania",
  Lebanon: "Lebanon",
  Burundi: "Burundi",
  Iraq: "Iraq",
  Nicaragua: "Nicaragua",
  Philippines: "Philippines",
  RussianFederation: "Russian Federation",
  Mozambique: "Mozambique",
  Turkey: "Turkey",
  Libya: "Libya",
  Haiti: "Haiti",
  SouthSudan: "South Sudan",
  Somalia: "Somalia",
  Mali: "Mali",
  Yemen: "Yemen",
  CongoDR: "Congo, the Democratic Republic",
  Syria: "Syria",
  Myanmar: "Myanmar",
  Afghanistan: "Afghanistan",
  NorthKorea: "North Korea",
  NotApplicable: "Not Applicable",
  Iran: "Iran, Islamic Republic of Iran",
  Other: "Other",
};
