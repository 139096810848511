import {
  Alert,
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Switch,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import Auth from "../../../service/Auth";
import User from "../../../service/User";

const NewTeamMember = ({ loadTeamMembers, clients }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [teamMemberRole, setTeamMemberRole] = useState("");
  const [open, setOpen] = useState(false);
  const [hasFullClientAccess, setHasFullClientAccess] = useState(true);
  const [accessedClients, setAccessedClients] = useState([]);

  const loading = useSelector(selectLoading);
  const user = useSelector(selectUser);

  const handleAdd = async () => {
    await Auth.createSubUser({
      name,
      email,
      password: uuidv4(),
      parentUserId: user._id,
      teamMemberRole,
      hasFullClientAccess,
      accessedClients,
    });
    await loadTeamMembers();
    setOpen(false);
    setName("");
    setEmail("");
    setTeamMemberRole("");
    setHasFullClientAccess(true);
    setAccessedClients([]);
  };
  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        New Member
      </Button>

      <Modal
        title="Add Team Member"
        open={open}
        onOk={handleAdd}
        onCancel={() => setOpen(false)}
        loading={loading}
        destroyOnClose
        footer={[
          <Button onClick={() => setOpen(false)} danger>
            Cancel
          </Button>,
          <Button type="primary" onClick={() => handleAdd()} loading={loading}>
            Add Member
          </Button>,
        ]}
      >
        <div className="mb-4">
          <label>Name</label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onPressEnter={handleAdd}
            loading={loading}
          />
        </div>
        <div className="mb-4">
          <label>Email</label>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onPressEnter={handleAdd}
            loading={loading}
          />
        </div>

        <div className="mb-4">
          <label>Role</label>
          <Input
            placeholder="Team Role"
            value={teamMemberRole}
            onChange={(e) => setTeamMemberRole(e.target.value)}
            onPressEnter={handleAdd}
            loading={loading}
          />
          <Alert
            className="mt-1 mb-4"
            type="info"
            message="Roles designated as 'MLRO' and 'Senior Management' have customization and approval capability"
          />
        </div>

        <Divider />
        <Typography.Title level={3}>Regulatory Access</Typography.Title>
        <div className="mb-4 flex items-center gap-2">
          <label>Full Client Access</label>

          <Switch
            checked={hasFullClientAccess}
            onChange={(e) => setHasFullClientAccess(e)}
            loading={loading}
          />
        </div>

        {hasFullClientAccess === true && (
          <div className="mb-4 flex items-center gap-2">
            <Alert
              type="info"
              message="You can disable full client access, and select specific clients that this user will be able to access. This option is suitable for external regulatory access. Once full client access is disabled, the person will not be able to perform certain action like creating, deleting and managing client groups and clients. Only clients where access has been provided can be edited."
            />
          </div>
        )}

        {hasFullClientAccess === false && (
          <>
            <div className="mb-4 flex items-center gap-2">
              <Select
                style={{ width: "100%" }}
                placeholder="Select Accessed Clients"
                mode="multiple"
                value={accessedClients}
                onChange={(e) => setAccessedClients(e)}
              >
                {clients.map((client) => (
                  <Select.Option value={client._id}>
                    {client.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default NewTeamMember;
