import { Button, Input, Skeleton, Switch, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useReactToPrint } from "react-to-print";
import { default as Risks } from "../../../service/RiskAssessment";
import { getScoreText } from "../CountryRiskScores";
import { average } from "../RiskAssessment";
import {
  EntityProductServiceOffered,
  WeighingtableData,
  clientCharacteristics,
  deliveryChannelFactors,
  jurisdictionTouchpointsFactors,
  sourceOfFundsFactors,
} from "./data";

export const KYCLevelAppliedOptions = [
  { value: "simplified", label: "Simplified" },
  { value: "standard", label: "Standard" },
  { value: "enhanced", label: "Enhanced" },
  { value: "na", label: "N/A" },
];

const Index = () => {
  const location = useLocation();
  const createdDate = location.state.date;
  const [scores, setScores] = useState(null);
  const [manualOverride, setmanualoveride] = useState(0);
  const [manualOverrideRationale, setmanualOverrideRationale] = useState("");
  const [complianceNotes, setcompliancenotes] = useState("");
  const navigate = useNavigate();

  const [PEP, setPEP] = useState(false);
  const [KYCLevelApplied, setKYCLevelApplied] = useState("standard");
  const [adverseMedia, setadverseMedia] = useState(false);
  const [SAR, setSAR] = useState(false);
  const [declined, setdeclined] = useState(false);
  const [sanctions, setsanctions] = useState(false);
  const [incompleteKYC, setincompleteKYC] = useState(false);
  const [scoresjurisdiction, setScoresJurisdiction] = useState(null);
  const [factors, setFactors] = useState(clientCharacteristics);
  const [weightingtable, setweighingtable] = useState([]);
  const [DeliveryChannel, setDeliveryChannel] = useState(
    deliveryChannelFactors
  );
  const [JurisdictionTouchPoints, setJurisdictionTouchPoints] = useState(
    jurisdictionTouchpointsFactors
  );
  const [clients, setclients] = useState("");

  const [SourceOfFunds, setSourceOfFunds] = useState(sourceOfFundsFactors);
  const { riskAssessmentId, resultId } = useParams();
  const [factorsEntityProduct, setFactorsEntityProduct] = useState(
    EntityProductServiceOffered
  );
  const [period, setperiod] = useState(new Date().toISOString().split("T")[0]);
  const [onboarding, setOnboarding] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [finalScore, setFinalScore] = useState(0);

  const componentRef = useRef();
  const handleExportRiskAssessment = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "emp",
  });

  useEffect(() => {
    const scoreload = async () => {
      try {
        const res = { data: {} };
        const singleAssessment = await Risks.getSingleRiskAssessments({
          id: riskAssessmentId,
        });

        res.data = singleAssessment.data.riskAssessmentResults.find(
          (e) => e._id === resultId
        ).result.riskAssessmentForm;
        const resultSingle = singleAssessment.data.riskAssessmentResults.find(
          (e) => e._id === resultId
        ).result;
        setweighingtable(
          Object.keys(WeighingtableData).map((e) => {
            return {
              category: WeighingtableData[e],
              score: resultSingle[e]?.weighting,
              finalScore: resultSingle[e]?.finalScore,
            };
          })
        );
        setFinalScore(resultSingle.finalOverallScore);
        setclients(singleAssessment?.data?.client);

        const { data: countryscores } = await Risks.getCountryRiskScores();
        const { data } = await Risks.getRiskFactorScores();
        setPEP(res.data?.PEP);
        setKYCLevelApplied(res.data?.KYCLevelApplied ?? "standard");
        setsanctions(res.data?.sanctions);
        setincompleteKYC(res.data?.incompleteKYC);
        setSAR(res.data?.SAR);
        setadverseMedia(res.data.adverseMedia);
        setmanualoveride(parseInt(res.data.manualOverride));
        setmanualOverrideRationale(res.data.manualOverrideRationale);
        setcompliancenotes(res.data.complianceNotes);
        setdeclined(res.data.declined);
        setAssessmentDate(res.data.assessmentDate.split("T")[0]);
        setperiod(res.data.periodicReviewDate.split("T")[0]);
        setOnboarding(res.data?.onboardingDate?.split?.("T")?.[0]);
        setFactors((prev) =>
          prev.map((e) => {
            const key = e.key;
            const factor = res.data.ClientCharacteristics[key]?.factor;
            const selection = res.data.ClientCharacteristics[key]?.selection;

            const score = Array.isArray(factor)
              ? Math.max(
                ...[
                  0,
                  ...factor.map((c) => data.ClientCharacteristics[c]?.score),
                ]
              )
              : data.ClientCharacteristics[factor]?.score;

            return {
              ...e,
              factor: factor,
              selection: selection,
              show: selection === "Yes",
              initialScore: score || "",
            };
          })
        );
        setDeliveryChannel((prev) =>
          prev.map((e) => {
            const key = e.key;
            const factor = res.data.DeliveryChannelsRisk[key]?.factor;
            const selection = res.data.DeliveryChannelsRisk[key]?.selection;
            const initialScore = data.DeliveryChannelsRisk[factor]?.score;
            return {
              ...e,
              factor,
              selection,
              initialScore: initialScore || "",
              show: selection === "Yes",
            };
          })
        );
        setJurisdictionTouchPoints((prev) =>
          prev.map((e) => {
            const key = e.key;

            const country = res.data.jurisdictionTouchPoints[key]?.country;

            const score = Array.isArray(country)
              ? Math.max(...[0, ...country.map((c) => countryscores[c]?.score)])
              : countryscores[country]?.score;

            return { ...e, country, initialScore: score || "" };
          })
        );
        setSourceOfFunds((prev) =>
          prev.map((e) => {
            const key = e.key;
            const factor = res.data.SourceofFundsTransaction[key]?.factor;
            const selection = res.data.SourceofFundsTransaction[key]?.selection;

            const initialScore = Array.isArray(factor)
              ? Math.max(
                ...[
                  0,
                  ...factor.map(
                    (c) => data.SourceofFundsTransaction[c]?.score
                  ),
                ]
              )
              : data.SourceofFundsTransaction[factor]?.score;
            return {
              ...e,
              factor,
              selection,
              initialScore: initialScore || "",
              show: selection === "Yes",
            };
          })
        );
        setFactorsEntityProduct((prev) => {
          return prev.map((e) => {
            const key = e?.key;
            const factor = res.data.EntityProductServiceOffered?.[key] ?? [];
            return {
              ...e,
              factor,
              initialScore: average(
                factor
                  .map((x) => data["EntityProductServiceOffered"]?.[x]?.score)
                  .filter((e) => !!e)
              ).toFixed(1),
              value: factor,
            };
          });
        });
        setScores(data);
        setexpectedTransactionValue(res.data?.expectedTransactionValue);
        setexpectedTransactionVolume(res.data?.expectedTransactionVolume);

        setScoresJurisdiction(countryscores);
      } catch (error) {
        console.log(error);
      }
    };
    scoreload();
  }, [riskAssessmentId, resultId]);

  const handleCommentsChange = (index, value, setFactors) => {
    setFactors((prevFactors) =>
      prevFactors?.map((factor, i) =>
        i === index ? { ...factor, selection: value } : factor
      )
    );
    setFactors((prevFactors) =>
      prevFactors.map((factor, i) =>
        i === index
          ? {
            ...factor,
            show: value === "Yes",
            initialScore: value !== "Yes" ? 0 : factor.initialScore,
          }
          : factor
      )
    );
  };

  const columns = (vals, setter, comments = true, factorSelectconfig) => [
    {
      title: "Factors",
      dataIndex: "name",
      key: "name",
    },
    comments && !factorSelectconfig?.hideComments
      ? {
        title: "Comments: Yes/No/NA",
        dataIndex: "selection",
        key: "selection",
        render: (text, record, index) => (
          <Select
            isDisabled
            options={[
              { value: "NA", label: "NA" },
              { value: "Yes", label: "Yes" },
              { value: "No", label: "No" },
            ]}
            className="w-full"
            onChange={(value) =>
              handleCommentsChange(index, value.value, setter)
            }
            value={{ label: text, value: text }}
          ></Select>
        ),
      }
      : {},
    {
      title: "Factor",
      dataIndex: "show",
      key: "show",
      render: (text, record, index) =>
        text ? (
          <Select
            isDisabled
            isMulti={record?.isMulti}
            {...factorSelectconfig}
            value={
              record?.isMulti
                ? Array.isArray(record.factor)
                  ? record.factor.map((v) => ({
                    value: v,
                    label: vals[v]?.title,
                  }))
                  : []
                : typeof record?.factor === "string"
                  ? { value: record?.factor, label: vals[record.factor]?.title }
                  : record.factor?.map?.((e) => {
                    if (e)
                      return {
                        value: e,
                        label: vals[e]?.title,
                      };
                    return e;
                  })
            }
            className="min-w-[100px]"
            options={Object.keys(vals).map((key) => {
              return {
                value: key,
                label: vals[key]?.title,
              };
            })}
            onChange={(value) => {
              setter((prevFactors) =>
                prevFactors.map((factor, i) => {
                  return i === index
                    ? {
                      ...factor,
                      initialScore:
                        record?.isMulti && Array.isArray(value)
                          ? Math.max(
                            0,
                            ...value.map((v) => Number(vals[v.value].score))
                          )
                          : Array.isArray(value)
                            ? average(
                              value.map((x) => vals[x.value].score)
                            ).toFixed(1)
                            : Number(vals[value.value].score),
                      factor: Array.isArray(value)
                        ? value.map((v) => v.value)
                        : value.value,
                      value: Array.isArray(value)
                        ? value.map((v) => v)
                        : value,
                    }
                    : factor;
                })
              );
            }}
            style={{ width: "200px" }}
          ></Select>
        ) : null,
    },
    comments
      ? {
        title: "Score",
        dataIndex: "initialScore",
        key: "initialScore",
      }
      : {},
  ];

  const columnJurisdiction = [
    {
      title: "Factors",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Factor",
      dataIndex: "country",
      render: (__, _, index) => {
        return (
          <Select
            isMulti={_?.isMulti}
            isDisabled
            className="w-full"
            options={Object.keys(scoresjurisdiction).map((key) => {
              return {
                value: key,
                label: scoresjurisdiction?.[key]?.title ?? key,
              };
            })}
            value={
              Array.isArray(__)
                ? __.map((v) => ({
                  value: v,
                  label: scoresjurisdiction?.[v]?.title ?? v,
                }))
                : { value: __, label: scoresjurisdiction?.[__]?.title ?? __ }
            }
            onChange={(value) => {
              setJurisdictionTouchPoints((prevFactors) =>
                prevFactors.map((factor, i) => {
                  return i === index
                    ? {
                      ...factor,
                      initialScore: Array.isArray(value)
                        ? Math.max(
                          0,
                          ...value.map((v) =>
                            Number(scoresjurisdiction[v.value].score)
                          )
                        )
                        : Number(scoresjurisdiction[value.value].score),
                      country: Array.isArray(value)
                        ? value.map((v) => v.value)
                        : value.value,
                    }
                    : factor;
                })
              );
            }}
            style={{ width: "200px" }}
          ></Select>
        );
      },
    },
    {
      title: "Score",
      dataIndex: "initialScore",
      key: "initialScore",
    },
  ];

  const data = (factors) => {
    return factors.map((factor, index) => ({
      key: `DeliveryChannel__${index}`,
      ...factor,
    }));
  };
  const [expectedTransactionValue, setexpectedTransactionValue] = useState(0);
  const [expectedTransactionVolume, setexpectedTransactionVolume] = useState(0);
  const [assessmentDate, setAssessmentDate] = useState(new Date());
  if ([scores, scoresjurisdiction].some((s) => !s)) return <Skeleton active />;

  if (!scores || !scoresjurisdiction) {
    return <Skeleton active />;
  }
  const Weighingtablecolumns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Weighting (%)",
      dataIndex: "score",
      render: (_) => (_ * 100).toFixed(0),
    },
    {
      title: "Final Score",
      dataIndex: "finalScore",
    },
  ];
  return (
    <div>
      <Button
        className="mb-4"
        type="primary"
        onClick={() =>
          navigate(`/app/editriskassessment/${riskAssessmentId}/2`)
        }
      >
        <div className="flex items-center gap-2">
          <AiOutlineDoubleLeft />
          <div>Back to Results</div>
        </div>
      </Button>

      <div ref={componentRef} className="p-1">
        <h4 className="text-2xl">
          {clients?.name ?? <Skeleton />}
        </h4>
        <h6>{moment(createdDate).format("DD.MM.YY")}</h6>
        <div className="my-10">
          <h5 className="">Products / Services / Transactions</h5>
          <Table
            columns={columns(
              scores["EntityProductServiceOffered"],
              setFactorsEntityProduct,
              true,
              { isMulti: true, hideComments: true }
            )}
            dataSource={data(factorsEntityProduct)}
            pagination={false}
          />
        </div>
        <div className="my-10">
          <h5 className="">Client Characteristics</h5>
          <Table
            columns={columns(scores["ClientCharacteristics"], setFactors)}
            dataSource={data(factors)}
            pagination={false}
          />
        </div>

        <div className="my-10">
          <h5 className=""> Delivery Channel Risk</h5>
          <Table
            columns={columns(
              scores["DeliveryChannelsRisk"],
              setDeliveryChannel
            )}
            dataSource={data(DeliveryChannel)}
            pagination={false}
          />
        </div>

        <div className="my-10">
          <h5 className="">Jurisdiction TouchPoint</h5>
          <Table
            columns={columnJurisdiction}
            dataSource={data(JurisdictionTouchPoints)}
            pagination={false}
          />
        </div>

        <div className="my-5">
          <h5 className="">Source of Funds / Transactions</h5>
          <Table
            columns={columns(
              scores["SourceofFundsTransaction"],
              setSourceOfFunds
            )}
            dataSource={data(SourceOfFunds)}
            pagination={false}
          />
        </div>

        <div className=" my-10 flex justify-between font-bold">
          <div>Overall Score</div>
          <div>
            {finalScore} {getScoreText(finalScore)}
          </div>
        </div>

        <div className="  ">
          <label className="!font-bold">Manual Override</label>
          <Input
            disabled
            value={manualOverride}
            onChange={(e) => {
              console.log(typeof e.target.value);
              if (e.target.value <= 0 || e.target.value === "") {
                setmanualOverrideRationale("");
              }
              setmanualoveride(parseInt(e.target.value));
            }}
            type="number"
            className="w-[100px]"
            placeholder="Manual Overide"
          />
        </div>
        {manualOverride !== 0 && (
          <div className="  ">
            <label className="!font-bold">Manual Override Rationale</label>
            <Input
              disabled
              value={manualOverrideRationale}
              onChange={(e) => setmanualOverrideRationale(e.target.value)}
              type="text"
              placeholder="Manual Override Rationale"
            />
          </div>
        )}

        <div className=" w-[100%]  flex flex-col gap-2 my-10">
          {clients?.transactionType && (
            <div className="  ">
              <label className="!font-bold" htmlFor="periodicDate">
                Next Periodic Date
              </label>
              <Input
                disabled
                value={period}
                onChange={(e) => {
                  if (!e.target.value) return;
                  setperiod(e.target.value);
                }}
                id="periodicDate"
                type="date"
              />
            </div>
          )}

          <div className="  ">
            <label className="!font-bold" htmlFor="periodicDate">
              Assessment Date
            </label>
            <Input
              value={assessmentDate}
              disabled
              onChange={(e) => {
                if (!e.target.value) return;
                setAssessmentDate(e.target.value);
              }}
              id="periodicDate"
              type="date"
            />
          </div>
        </div>

        <div className="my-10">
          <h4 className="!my-10">Result Overview</h4>
          <Table
            rowKey={"uid"}
            columns={Weighingtablecolumns}
            dataSource={weightingtable}
            pagination={false}
            footer={() => (
              <>
                {manualOverride !== 0 && (
                  <div className="flex justify-between mb-4">
                    <div className="!font-bold">
                      Manual Override: {manualOverride}
                    </div>
                  </div>
                )}
                <div className="flex justify-between">
                  <div className="!font-bold">Overall Score: {finalScore}</div>
                  <div>{getScoreText(finalScore)}</div>
                </div>
              </>
            )}
          />
        </div>
      </div>
      <Button
        type="primary"
        className="my-5"
        onClick={handleExportRiskAssessment}
      >
        Print / PDF
      </Button>
    </div>
  );
};

export default Index;
