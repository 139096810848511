import {
  Alert,
  Button,
  Descriptions,
  Dropdown,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from "antd";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { BsCheck2All, BsFillPatchExclamationFill } from "react-icons/bs";
import { GrNote } from "react-icons/gr";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SampeBulkAddRelatedParty from "../../../assets/Samples/SampeBulkAddRelatedParty.xlsx";
import { selectLoading } from "../../../redux/auth/selectors";
import {
  default as Client,
  default as ClientService,
} from "../../../service/Client";
import RiskAssessment from "../../../service/RiskAssessment";
import User from "../../../service/User";
import { handleXLSXTOJSON } from "../../../utils/bulkUpload";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { getScoreText } from "../CountryRiskScores";
import DynamicDescription from "../SearchEntity/DynamicDescription";
import { KYCLevelAppliedOptions } from "../results/Result";
import { exportToExcel } from "./clientByUser";
import pdfMake from "pdfmake/build/pdfmake";

const { Option } = Select;
const ClientGroup = () => {
  const fields = [
    { name: "name", placeholder: "Name" },
    { name: "address", placeholder: "Address" },
    {
      name: "country",
      placeholder: "Country of Birth/Incorporation",
    },
    { name: "clientRole", placeholder: "Client Relation Type" },
    { name: "Shareholding", placeholder: "%Shareholding" },
    { name: "complianceNote", placeholder: "Compliance Note" },
    { name: "IDNumber", placeholder: "ID Number" },
    { name: "IDType", placeholder: "ID Type" },
    { name: "IDExpiration", placeholder: "ID Expiration" },
  ];
  const [Clients, setClients] = useState([]);
  const [screeningResult, setScreeningResult] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [note, setNote] = useState(null);
  const [currentScreening, setCurrentScreening] = useState(null);
  const [propertiesModal, setPropertiesModal] = useState(null);
  const [oldResult, setOldResult] = useState(null);
  const [riskAssessment, setRiskAssessments] = useState([]);
  const [selectedScreening, setSelectedScreening] = useState([]);
  const loading = useSelector(selectLoading);
  const { clientId } = useParams();
  const navigate = useNavigate();

  const canEdit = (() => {
    const accessToken = Cookies.get("accessToken");
    if (!accessToken) return false;
    const decoded = jwt_decode(accessToken);
    return decoded.hasFullClientAccess;
  })();

  const loadClients = useCallback(async () => {
    const { data } = await RiskAssessment.getRiskAssessments({ clientId });
    setRiskAssessments(data.map((e) => e.assessment).reverse());
    const response = await Client.getRelatedParties({ clientId });
    Client.getClient(clientId).then(({ data }) => {
      setClientData(data);
    });
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
  }, [clientId]);
  const loadScreening = useCallback(async () => {
    const res = await ClientService.getScreening({ clientId });
    if (res.data) setScreeningResult(res.data);
  }, [clientId]);

  const handleDeleteRiskAssessment = async (assessmentId) => {
    try {
      const response = await RiskAssessment.deleteRiskAssessment({
        assessmentId,
      });

      console.log("========", assessmentId);

      if (response.status === 200) {
        setRiskAssessments((prevRiskAssessments) =>
          prevRiskAssessments.filter(
            (assessment) => assessment._id !== assessmentId
          )
        );
      } else {
        message.error("Failed to delete risk assessment");
      }
    } catch (error) {
      console.error("Error deleting risk assessment:", error);
      message.error("An error occurred while deleting risk assessment");
    }
  };

  useEffect(() => {
    loadClients();
    loadScreening();

    User.getLimit().then(({ data }) => {
      setCurrentScreening(data.screening);
    });
  }, [loadClients, loadScreening]);
  const [clientData, setClientData] = useState(null);
  const columns = [
    { title: "Name", key: "name", dataIndex: "name" },
    { title: "Address", key: "address", dataIndex: "address" },
    {
      title: "Country of Birth/Incorporation ",
      key: "country",
      dataIndex: "country",
    },
    {
      title: "Client Relation Type",
      key: "clientRole",
      dataIndex: "clientRole",
    },
    {
      title: "% Shareholding",
      key: "Shareholding",
      dataIndex: "Shareholding",
    },
    {
      title: "ID Number",
      key: "IDNumber",
      dataIndex: "IDNumber",
    },
    {
      title: "ID Type",
      key: "IDType",
      dataIndex: "IDType",
    },
    {
      title: "ID Expiration",
      key: "IDExpiration",
      dataIndex: "IDExpiration",
    },
    {
      title: "Compliance Note",
      key: "complianceNote",
      dataIndex: "complianceNote",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space>
          {canEdit && (
            <>
              <Button onClick={() => handleEdit(text)} type="primary">
                Edit
              </Button>
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                onConfirm={() => handleDelete(text)}
              >
                <Button danger>Delete</Button>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];
  const [open, setOpen] = useState(false);
  const params = useParams();
  const [EditData, setEditData] = useState({
    name: "",
    address: "",
    country: "",
    clientRole: "",
    complianceNote: "",
  });
  const [AddData, setAddData] = useState({
    name: "",
    address: "",
    country: "",
    clientRole: "",
    complianceNote: "",
  });
  const handleEdit = (data) => {
    setSelectedMember(data);
    setEditData((prev) => ({ ...prev, ...data, clientId: data._id }));
    setOpen(true);
  };

  const handleDelete = async (data) => {
    await Client.deleteClient({
      clientId: data._id,
    }).then(() => setClients((prev) => prev.filter((t) => t._id !== data._id)));
  };
  const Edit = async () => {
    await Client.editClient({
      ...EditData,
      relatedParties: undefined,
    });
    setOpen(false);
    const response = await Client.getRelatedParties({ clientId });
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
    if (selectedMember)
      setSelectedMember(
        response.data.find((m) => m?._id === selectedMember?._id) ?? null
      );
  };
  const Add = async () => {
    await Client.createRelatedParty({
      clientId,
      relatedClient: { ...AddData },
    });
    setOpenAdd(false);
    const response = await Client.getRelatedParties({ clientId });
    setClients(response.data.map((t) => ({ ...t, key: t?._id })));
    if (selectedMember)
      setSelectedMember(
        response.data.find((m) => m?._id === selectedMember?._id) ?? null
      );

    setAddData({
      group: params.groupId,
      name: "",
      address: "",
      country: "",
      clientRole: "",
      complianceNote: "",
    });
  };
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEditData((prev) => ({ ...prev, [name]: value }));
  };
  const handleChangeAdd = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddData((prev) => ({ ...prev, [name]: value }));
  };
  const EditClient = async (data) => {
    if (!canEdit) return message.error("You have read only access");
    await Client.editClient({
      clientId: clientId,
      ...data,
      relatedParties: undefined,
    });
  };
  const [openAdd, setOpenAdd] = useState(false);
  const riskAssessmentColumn = [
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (a) => (
        <div className="whitespace-nowrap">
          {moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    {
      title: "Score",
      dataIndex: "riskRating",
      render: (_, record) =>
        record?.riskAssessmentResults?.[
          record?.riskAssessmentResults?.length - 1
        ]?.result?.finalOverallScore ?? 0,
    },
    {
      title: "Risk Rating",
      dataIndex: "rating",
      render: (_, record) =>
        getScoreText(
          record?.riskAssessmentResults?.[
            record?.riskAssessmentResults?.length - 1
          ]?.result?.finalOverallScore ?? 0
        ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Space>
          <Link to={`/app/editriskassessment/${record?._id}`}>
            <Button type="primary">{canEdit ? "Edit" : "Check"}</Button>
          </Link>
          {record?.pendingApproval && (
            <Tooltip title="MLRO approval required">
              <BsFillPatchExclamationFill color="red" />{" "}
            </Tooltip>
          )}
          {record?.isApproved && (
            <Tooltip title="MLRO Approved">
              <BsCheck2All color="green" />{" "}
            </Tooltip>
          )}
          {record?.isDisapproved && (
            <Tooltip title="MLRO Declined">
              <BsCheck2All color="red" />{" "}
            </Tooltip>
          )}
          {canEdit && (
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => handleDeleteRiskAssessment(record._id)}
              okText="Yes"
              cancelText="Cancel"
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  const screeningColumn = [
    {
      title: "Name",
      dataIndex: "caption",
      render: (a, record) => <div className="whitespace-nowrap">{a}</div>,
    },
    {
      title: "Details",
      dataIndex: "properties",
      key: "properties",
      render: (a, record) => (
        <>
          <Button
            onClick={() => {
              setPropertiesModal(a);
              if (record.oldResults && record.oldResults.length > 0)
                setOldResult(record.oldResults[record.oldResults.length - 1]);
            }}
            type="link"
          >
            Details
          </Button>
        </>
      ),
    },
    {
      title: "Last Change",
      dataIndex: "last_change",
      render: (a) => (
        <div className="whitespace-nowrap">
          {moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    {
      title: "Match Status",
      dataIndex: "matchStatus",
      render: (a) => (
        <Tag
          color={
            a === "Match" ? "green" : a === "False Positive" ? "red" : null
          }
        >
          {a}
        </Tag>
      ),
    },
    {
      title: "Resolve Date",
      dataIndex: "resolveDate",
      render: (a) => (
        <div className="whitespace-nowrap">
          {!a ? "" : moment(a).format("DD.MM.YY HH:mm")}
        </div>
      ),
    },
    {
      display: canEdit,
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Space>
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: "download",
                  label: "Download Report",
                  onClick: () => handleDownload(record),
                },
                {
                  key: "match",
                  label: "Mark as Match",
                  onClick: async () => {
                    await ClientService.changeScreeningResultStatus({
                      clientId,
                      screeningId: [record.screeningId],
                      entityId: [record.id],
                      matchStatus: "Match",
                    });
                    await loadScreening();
                  },
                  style: { color: "green" },
                },
                {
                  key: "false-positive",
                  label: "Mark as False Positive",
                  onClick: async () => {
                    await ClientService.changeScreeningResultStatus({
                      clientId,
                      screeningId: [record.screeningId],
                      entityId: [record.id],
                      matchStatus: "False Positive",
                    });
                    await loadScreening();
                  },
                  danger: true,
                },
              ],
            }}
          >
            Actions
          </Dropdown.Button>
        </Space>
      ),
    },
    {
      display: canEdit,
      title: "Notes",
      dataIndex: "userNotes",
      render: (a, record) => (
        <div
          className="whitespace-nowrap cursor-pointer"
          onClick={() =>
            setNote({
              caption: record.caption,
              userNotes: record.userNotes,
              screeningId: record.screeningId,
              entityId: record.id,
            })
          }
        >
          <GrNote />
        </div>
      ),
    },
  ].filter((e) => e.display !== false);
  const DropDownMenu = [
    {
      key: "export-excel",
      label: <Button onClick={() => exportToExcel(Clients)}>Excel</Button>,
    },
    {
      key: "export-pdf",
      label: (
        <Button
          onClick={() =>
            handleGeneratePDF({
              header: "Related Parties",
              ContentColumn: columns.filter((e) => e.title !== "Action"),
              DataSource: Clients,
            })
          }
        >
          PDF
        </Button>
      ),
    },
  ];

  const handleCloseNote = async () => {
    await ClientService.changeScreeningResultStatus({
      clientId,
      screeningId: [note.screeningId],
      entityId: [note.entityId],
      userNotes: note.userNotes,
    });
    await loadScreening();
    setNote(null);
  };

  if (!clientData) {
    return <Skeleton active />;
  }

  const handleDownload = async (record) => {
    try {
      console.log("handeleDownload", record);

      const content = [
        { text: "Entity Details:", style: "header" },

        `Name: ${record.caption}`,
        "_____________________________________________________________________________________",
        `Last name: ${record.properties.lastName}`,
        "_____________________________________________________________________________________",

        `Father Name: ${record.properties.fatherName}`,

        "_____________________________________________________________________________________",
        `Entity Type: ${record.schema}`,
        "_____________________________________________________________________________________",
        `Target: ${record.target ? "Yes" : "No"}`,
        "_____________________________________________________________________________________",
        record.hitDate
          ? `Hit Date: ${moment(record.hitDate).format("YYYY-MM-DD HH:mm")}`
          : ``,
        record.hitDate
          ? "_____________________________________________________________________________________"
          : "",
        `First Seen: ${moment(record.first_seen).format("YYYY-MM-DD HH:mm")}`,
        "_____________________________________________________________________________________",
        `Last Seen: ${moment(record.last_seen).format("YYYY-MM-DD HH:mm")}`,
        "_____________________________________________________________________________________",
        `Last Change: ${moment(record.last_change).format("YYYY-MM-DD HH:mm")}`,
        "_____________________________________________________________________________________",
        `Date of Report: ${moment(new Date().toISOString()).format(
          "YYYY-MM-DD HH:mm"
        )}`,
        "_____________________________________________________________________________________",
      ];
      if (record.properties.position)
        content.push(
          ...[
            `position: ${record.properties.position}`,
            "_____________________________________________________________________________________",
          ]
        );

      if (record.properties.matchStatus)
        content.push(...[`matchStatus : ${record.matchStatus}`]);
      if (record.properties.gender)
        content.push(
          ...[
            `gender: ${record.properties.gender}`,
            "_____________________________________________________________________________________",
          ]
        );
      if (record.properties.address)
        content.push(
          ...[
            `address: ${record.properties.address}`,
            "_____________________________________________________________________________________",
          ]
        );

      if (record.properties.country)
        content.push(
          ...[
            `country: ${record.properties.country}`,
            "_____________________________________________________________________________________",
          ]
        );
      if (record.matchStatus)
        content.push(
          ...[
            `Match Status: ${record.matchStatus}`,
            "_____________________________________________________________________________________",
          ]
        );
      if (record.resolveDate)
        content.push(
          ...[
            `Resolve Date: ${moment(record.resolveDate).format(
              "YYYY-MM-DD HH:mm"
            )}`,
            "_____________________________________________________________________________________",
          ]
        );
      const docDefinition = {
        content,
        styles: {
          header: {
            fontSize: 14,
            bold: true,
            decoration: "underline",
            margin: [0, 0, 0, 10],
          },
        },
      };

      // Generate the PDF
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      console.log("888888888888888888888");
      // Download the PDF
      pdfDocGenerator.download("record.pdf");
    } catch (error) {
      console.error("Error on pdf download", error);
    }
  };

  return (
    <div className="flex flex-col  ">
      <Button
        className="mb-4 flex-shrink w-52"
        type="primary"
        onClick={() => navigate(`/app/edit-client-group/${clientData?.group}`)}
      >
        <div className="flex items-center gap-2">
          <AiOutlineDoubleLeft />
          <div>Back to Clients</div>
        </div>
      </Button>

      <h3 className="capitalize">{clientData.name}</h3>
      <Descriptions
        layout="horizontal"
        colon={false}
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        bordered
        className="mt-10"
      >
        <Descriptions.Item
          label="Name"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.name) return prev;
                        else {
                          EditClient({ ...prev, name: e });
                          return { ...prev, name: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.name}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Entity or Individual"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Switch
            checkedChildren="Entity"
            unCheckedChildren="Individual"
            value={clientData.isBusiness}
            checked={clientData.isBusiness}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.isBusiness) return prev;
                else {
                  EditClient({ ...prev, isBusiness: e });
                  return { ...prev, isBusiness: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Transaction Type"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Switch
            checked={clientData.transactionType}
            checkedChildren="Ongoing"
            unCheckedChildren="One-off"
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.transactionType) return prev;
                else {
                  EditClient({ ...prev, transactionType: e });
                  return { ...prev, transactionType: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Transaction or Business Activity"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.businessType) return prev;
                        else {
                          EditClient({ ...prev, businessType: e });
                          return { ...prev, businessType: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.businessType}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Source of Funds"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.sourceOfFunds) return prev;
                        else {
                          EditClient({ ...prev, sourceOfFunds: e });
                          return { ...prev, sourceOfFunds: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.sourceOfFunds}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Source of Wealth"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.sourceOfWealth) return prev;
                        else {
                          EditClient({ ...prev, sourceOfWealth: e });
                          return { ...prev, sourceOfWealth: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.sourceOfWealth}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Industry / Occupation"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.industryOccupation) return prev;
                        else {
                          EditClient({ ...prev, industryOccupation: e });
                          return { ...prev, industryOccupation: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.industryOccupation}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Expected Transaction Volume"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <InputNumber
            value={clientData.expectedTransactionVolume}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.expectedTransactionVolume) return prev;
                else {
                  EditClient({ ...prev, expectedTransactionVolume: e });
                  return { ...prev, expectedTransactionVolume: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Expected Transaction Value"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <InputNumber
            value={clientData.expectedTransactionValue}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.expectedTransactionValue) return prev;
                else {
                  EditClient({ ...prev, expectedTransactionValue: e });
                  return { ...prev, expectedTransactionValue: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        {clientData.isBusiness && (
          <Descriptions.Item
            span={1}
            label="Business Website"
            labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
          >
            <Typography.Text
              editable={
                !canEdit
                  ? null
                  : {
                      onChange: (e) => {
                        if (!canEdit)
                          return message.error("You have read only access");
                        setClientData((prev) => {
                          if (e === prev.businessWebsite) return prev;
                          else {
                            EditClient({ ...prev, businessWebsite: e });
                            return { ...prev, businessWebsite: e };
                          }
                        });
                      },
                    }
              }
            >
              {clientData.businessWebsite}
            </Typography.Text>
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label="Place of Birth / Incorporation"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.placeOfBirth) return prev;
                        else {
                          EditClient({ ...prev, placeOfBirth: e });
                          return { ...prev, placeOfBirth: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.placeOfBirth}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Status"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.status) return prev;
                        else {
                          EditClient({ ...prev, status: e });
                          return { ...prev, status: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.status}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="High Net Worth Client"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Switch
            checked={clientData.isHighNetWorth}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.isHighNetWorth) return prev;
                else {
                  EditClient({ ...prev, isHighNetWorth: e });
                  return { ...prev, isHighNetWorth: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Regulated Client"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Switch
            checked={clientData.isRegulated}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.isRegulated) return prev;
                else {
                  EditClient({ ...prev, isRegulated: e });
                  return { ...prev, isRegulated: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Regulator Name"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.regulatorName) return prev;
                        else {
                          EditClient({ ...prev, regulatorName: e });
                          return { ...prev, regulatorName: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.regulatorName}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Regulation Type"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.regulatorType) return prev;
                        else {
                          EditClient({ ...prev, regulatorType: e });
                          return { ...prev, regulatorType: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.regulatorType}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Eligible Introducer"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Switch
            checked={clientData.eligibleIntroducer}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.eligibleIntroducer) return prev;
                else {
                  EditClient({ ...prev, eligibleIntroducer: e });
                  return { ...prev, eligibleIntroducer: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Introducer Name"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.introducerName) return prev;
                        else {
                          EditClient({ ...prev, introducerName: e });
                          return { ...prev, introducerName: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.introducerName}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Ongoing Screening"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Switch
            checked={clientData.ongoingScreening}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.ongoingScreening) return prev;
                else {
                  EditClient({ ...prev, ongoingScreening: e });
                  return { ...prev, ongoingScreening: e };
                }
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Referred/Transferred"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.referredTransferred) return prev;
                        else {
                          EditClient({ ...prev, referredTransferred: e });
                          return { ...prev, referredTransferred: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.referredTransferred}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="Regulatory Filing"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Select
            mode="multiple"
            placeholder="Select regulatory filings"
            defaultValue={clientData.regulatoryFiling}
            style={{ width: "15rem" }}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.regulatoryFiling) return prev;
                else {
                  EditClient({ ...prev, regulatoryFiling: e });
                  return { ...prev, regulatoryFiling: e };
                }
              });
            }}
          >
            <Option value="BO">BO</Option>
            <Option value="FATCA/CRS">FATCA/CRS</Option>
            <Option value="Economic Substance">Economic Substance</Option>
          </Select>
        </Descriptions.Item>
      </Descriptions>

      <h4 className="!my-10">ID Information</h4>
      <Descriptions
        layout="horizontal"
        colon={false}
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
        bordered
        className="mt-10"
      >
        <Descriptions.Item
          label="ID Number"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.IDNumber) return prev;
                        else {
                          EditClient({ ...prev, IDNumber: e });
                          return { ...prev, IDNumber: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.IDNumber}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="ID Type"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Typography.Text
            editable={
              !canEdit
                ? null
                : {
                    onChange: (e) => {
                      if (!canEdit)
                        return message.error("You have read only access");
                      setClientData((prev) => {
                        if (e === prev.IDType) return prev;
                        else {
                          EditClient({ ...prev, IDType: e });
                          return { ...prev, IDType: e };
                        }
                      });
                    },
                  }
            }
          >
            {clientData.IDType}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label="ID Expiration"
          labelStyle={{ fontWeight: 700, marginRight: "1rem" }}
        >
          <Input
            value={clientData.IDExpiration}
            onChange={(e) => {
              if (!e.target.value) return;
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e.target.value === prev.IDExpiration) return prev;
                else {
                  EditClient({ ...prev, IDExpiration: e.target.value });
                  return { ...prev, IDExpiration: e.target.value };
                }
              });
            }}
            type="date"
          />
        </Descriptions.Item>
      </Descriptions>

      <h4 className="!my-10">Related Parties</h4>
      <Table
        className="w-full max-w-2xl"
        dataSource={Clients}
        columns={columns}
        loading={loading}
        footer={() => (
          <>
            <div className="flex gap-3 items-center">
              {canEdit && (
                <Button type="primary" onClick={() => setOpenAdd(true)}>
                  Add Client Party
                </Button>
              )}
              <Dropdown.Button
                type="primary"
                menu={{
                  items: DropDownMenu,
                }}
                arrow={{
                  pointAtCenter: true,
                }}
              >
                Export
              </Dropdown.Button>
            </div>

            {canEdit && (
              <div className="mt-2">
                <Button type="primary">
                  <label htmlFor="bulk-upload">Bulk Add</label>
                  <Input
                    className="hidden"
                    type="file"
                    id="bulk-upload"
                    onChange={(e) =>
                      handleXLSXTOJSON(
                        { sheet: e.target.files[0] },
                        async (json) => {
                          json.shift();

                          await Client.createRelatedPartyBulk({
                            relatedClients: json,
                            clientId,
                          });

                          const response = await Client.getRelatedParties({
                            clientId,
                          });
                          setClients(
                            response.data.map((t) => ({ ...t, key: t?._id }))
                          );
                        }
                      )
                    }
                  />
                </Button>
                <a
                  className="mx-3"
                  href={SampeBulkAddRelatedParty}
                  download={true}
                >
                  Sample Excel Sheet
                </a>
              </div>
            )}
          </>
        )}
      />

      <div className="my-2 flex gap-2 flex-col">
        <div className="w-full  flex gap-3 justify-between">
          <div className="font-bold">PEP</div>
          <Switch
            checked={clientData?.PEP}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.PEP) return prev;
                else {
                  EditClient({ ...prev, PEP: e });
                  return { ...prev, PEP: e };
                }
              });
            }}
            title="PEP"
          />
        </div>
        <div className="w-full  flex gap-3 justify-between">
          <div className="font-bold">Adverse Media</div>
          <Switch
            checked={clientData?.adverseMedia}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.adverseMedia) return prev;
                else {
                  EditClient({ ...prev, adverseMedia: e });
                  return { ...prev, adverseMedia: e };
                }
              });
            }}
            title="Adverse Media"
          />
        </div>
        <div className="w-full  flex gap-3 justify-between">
          <div className="font-bold">SAR</div>
          <Switch
            checked={clientData?.SAR}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.SAR) return prev;
                else {
                  EditClient({ ...prev, SAR: e });
                  return { ...prev, SAR: e };
                }
              });
            }}
            title="SAR"
          />
        </div>
        <div className="w-full  flex gap-3 justify-between">
          <div className="font-bold">Declined</div>
          <Switch
            title="Declined"
            checked={clientData?.declined}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.declined) return prev;
                else {
                  EditClient({ ...prev, declined: e });
                  return { ...prev, declined: e };
                }
              });
            }}
          />
        </div>
        <div className="w-full flex gap-3 justify-between">
          <div className="font-bold">Sanctions</div>
          <Switch
            title="Sactions"
            checked={clientData?.sanctions}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.sanctions) return prev;
                else {
                  EditClient({ ...prev, sanctions: e });
                  return { ...prev, sanctions: e };
                }
              });
            }}
          />
        </div>
        <div className="w-full flex gap-3 justify-between">
          <div className="font-bold">Incomplete KYC</div>
          <Switch
            title="KYC"
            checked={clientData?.incompleteKYC}
            onChange={(e) => {
              if (!canEdit) return message.error("You have read only access");
              setClientData((prev) => {
                if (e === prev.incompleteKYC) return prev;
                else {
                  EditClient({ ...prev, incompleteKYC: e });
                  return { ...prev, incompleteKYC: e };
                }
              });
            }}
          />
        </div>
      </div>

      <div className="  ">
        <label className="!font-bold" htmlFor="onboardingDate">
          Onboarding Date
        </label>
        <Input
          value={clientData?.onboardingDate}
          onChange={(e) => {
            if (!e.target.value) return;
            if (!canEdit) return message.error("You have read only access");
            setClientData((prev) => {
              if (e === prev.onboardingDate) return prev;
              else {
                EditClient({ ...prev, onboardingDate: e.target.value });
                return { ...prev, onboardingDate: e.target.value };
              }
            });
          }}
          id="onboardingDate"
          type="date"
        />
      </div>

      <div className="w-full  flex gap-3 justify-between mt-5">
        <div className="font-bold">KYC Level Applied</div>
        <Select
          value={KYCLevelAppliedOptions.find(
            (e) => e.value === clientData?.KYCLevelApplied
          )}
          onChange={(e) => {
            if (!canEdit) return message.error("You have read only access");
            setClientData((prev) => {
              if (e === prev.KYCLevelApplied) return prev;
              else {
                EditClient({ ...prev, KYCLevelApplied: e });
                return { ...prev, KYCLevelApplied: e };
              }
            });
          }}
          options={KYCLevelAppliedOptions}
        />
      </div>

      <div className="  ">
        <label className="!font-bold">Compliance Notes</label>

        <Typography.Text
          editable={
            !canEdit
              ? null
              : {
                  onChange: (e) => {
                    if (!canEdit)
                      return message.error("You have read only access");
                    setClientData((prev) => {
                      if (e === prev.complianceNotes) return prev;
                      else {
                        EditClient({ ...prev, complianceNotes: e });
                        return { ...prev, complianceNotes: e };
                      }
                    });
                  },
                }
          }
        >
          {clientData.complianceNotes}
        </Typography.Text>
      </div>

      <h4 className="!my-10">Risk Assessments</h4>
      <Table
        className="w-full max-w-2xl"
        dataSource={riskAssessment}
        rowKey="uid"
        columns={riskAssessmentColumn}
        loading={loading}
        pagination={true}
        footer={() =>
          !canEdit ? null : (
            <Link to={"/app/riskassessment/" + clientId}>
              <Button type="primary">Create Risk Asessment</Button>
            </Link>
          )
        }
      />

      {clientData?.ongoingScreening && !currentScreening && (
        <>
          <h4 className="!my-10">Screening</h4>
          <Alert
            type="warning"
            message="You need to purchase a separate license to access the screening feature. Please visit the plan page."
          />
        </>
      )}
      {clientData?.ongoingScreening && currentScreening && (
        <>
          <h4 className="!my-10">Screening</h4>
          <Table
            className="w-full max-w-2xl"
            dataSource={screeningResult}
            rowKey="id"
            columns={screeningColumn}
            loading={loading}
            pagination={true}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) => {
                setSelectedScreening(selectedRows);
              },
            }}
            footer={() =>
              selectedScreening && canEdit
                ? [
                    <Dropdown.Button
                      type="primary"
                      menu={{
                        items: [
                          {
                            key: "match",
                            label: "Mark as Match",
                            onClick: async () => {
                              await ClientService.changeScreeningResultStatus({
                                clientId,
                                screeningId: selectedScreening.map(
                                  (record) => record.screeningId
                                ),
                                entityId: selectedScreening.map(
                                  (record) => record.id
                                ),
                                matchStatus: "Match",
                              });
                              await loadScreening();
                            },
                            style: { color: "green" },
                          },
                          {
                            key: "false-positive",
                            label: "Mark as False Positive",
                            onClick: async () => {
                              await ClientService.changeScreeningResultStatus({
                                clientId,
                                screeningId: selectedScreening.map(
                                  (record) => record.screeningId
                                ),
                                entityId: selectedScreening.map(
                                  (record) => record.id
                                ),
                                matchStatus: "False Positive",
                              });
                              await loadScreening();
                            },
                            danger: true,
                          },
                        ],
                      }}
                    >
                      Bulk Actions
                    </Dropdown.Button>,
                  ]
                : []
            }
          />
        </>
      )}

      <Modal
        open={propertiesModal}
        onCancel={() => setPropertiesModal(null)}
        title="Details"
        footer={[
          <Button onClick={() => setPropertiesModal(null)}>Close</Button>,
        ]}
        destroyOnClose
      >
        {propertiesModal && (
          <DynamicDescription
            data={propertiesModal}
            oldResult={oldResult?.properties}
          />
        )}
      </Modal>

      <Modal
        open={openAdd}
        onCancel={() => setOpenAdd(false)}
        title="Add Client Related Parties"
        onOk={Add}
        loading={loading}
        destroyOnClose
        footer={[
          <Button onClick={() => setOpenAdd(false)} danger>
            Cancel
          </Button>,
          <Button type="primary" onClick={Add} loading={loading}>
            Add Client Party
          </Button>,
        ]}
      >
        {fields.map((e, i) => (
          <div className="mb-4" key={i}>
            <label className="captalize">{e.placeholder}</label>
            {e.name === "IDExpiration" ? (
              <Input
                value={AddData[e.name] || ""}
                onChange={handleChangeAdd}
                name={`${e.name}`}
                placeholder={e.placeholder}
                type="date"
              />
            ) : e.name === "address" ? (
              <Input.TextArea
                value={AddData[e.name] || ""}
                onChange={handleChangeAdd}
                name={`${e.name}`}
                placeholder={e.placeholder}
                required
              />
            ) : (
              <Input
                value={AddData[e.name] || ""}
                onPressEnter={Add}
                onChange={handleChangeAdd}
                name={`${e.name}`}
                placeholder={e.placeholder}
                required
              />
            )}
          </div>
        ))}
      </Modal>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        title="Edit Client Group"
        onOk={Edit}
        loading={loading}
        destroyOnClose
        footer={[
          <Button onClick={() => setOpen(false)} danger>
            Cancel
          </Button>,
          <Button type="primary" onClick={Edit} loading={loading}>
            Edit Client Party
          </Button>,
        ]}
      >
        {fields.map((e, i) => (
          <div className="mb-4" key={i}>
            <label className="capitalize">{e.placeholder}</label>
            {e.name === "IDExpiration" ? (
              <Input
                value={EditData[e.name] || ""}
                onChange={handleChange}
                name={`${e.name}`}
                placeholder={e.placeholder}
                type="date"
              />
            ) : e.name === "address" ? (
              <Input.TextArea
                value={EditData[e.name] || ""}
                onChange={handleChange}
                name={`${e.name}`}
                placeholder={e.placeholder}
                required
              />
            ) : (
              <Input
                value={EditData[e.name] || ""}
                onPressEnter={Edit}
                onChange={handleChange}
                name={`${e.name}`}
                placeholder={e.placeholder}
                required
              />
            )}
          </div>
        ))}
      </Modal>

      <Modal
        open={note !== null}
        onCancel={handleCloseNote}
        onOk={handleCloseNote}
        title={`Notes on ${note?.caption}`}
        loading={loading}
        footer={[]}
      >
        <Input.TextArea
          rows={4}
          value={note?.userNotes}
          onChange={(e) =>
            setNote((note) => ({ ...note, userNotes: e.target.value }))
          }
        />
      </Modal>
    </div>
  );
};

export default ClientGroup;
