import React, { useState } from "react";
import View from "./View";
import Edit from "./Edit";
const RiskAssessmentView = () => {
  const [edit, setedit] = useState({ isEdit: false, assessment: {} });
  return (
    <>
      {!edit.isEdit ? (
        <View setedit={setedit} />
      ) : (
        <Edit
          edit={edit}
          setedit={setedit}
          data={edit.assessment.riskAssessmentForm}
        />
      )}
    </>
  );
};
export default RiskAssessmentView;
