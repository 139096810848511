export const notificationDetail = {
  createClientGroup: "Receive notifications when a team creates client group",
  createClient: "Receive notifications when a team creates client",
  editClientGroup: "Receive notifications when a team edits client group",
  editClient: "Receive notifications when a team edits client",
  deleteClientGroup: "Receive notifications when a team deletes client group",
  deleteClient: "Receive notifications when a team deletes client",
  changeWeightingTable:
    "Receive notifications when a team changes weighting table",
  changeRiskFactorScores:
    "Receive notifications when a team changes inherent risk scores",
  changeCountryRiskScores:
    "Receive notifications when a team changes country risk scores",
  createRiskAssessment:
    "Receive notifications when a team creates risk assessment",
  editRiskAssessment: "Receive notifications when a team edits risk assessment",
};
