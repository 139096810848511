import { Button, Skeleton, Space, Tabs, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  AiFillNotification,
  AiOutlineStop,
  AiOutlineTeam,
} from "react-icons/ai";
import { GoCodeReview } from "react-icons/go";
import { MdAdminPanelSettings, MdPublishedWithChanges } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import User from "../../../service/User";
import { handleGeneratePDF } from "../../../utils/htmltopdf";
import { exportToExcel } from "../Clients/clientByUser";

function transpose(array) {
  // Check if the input is a 2D array
  if (!Array.isArray(array) || !Array.isArray(array[0])) {
    throw new Error("Input should be a 2D array.");
  }

  // Get the number of rows and columns
  const rows = array.length;
  const cols = array[0].length;

  // Initialize the transposed array with empty arrays
  const transposed = Array.from({ length: cols }, () => []);

  // Fill the transposed array
  for (let row = 0; row < rows; row++) {
    for (let col = 0; col < cols; col++) {
      transposed[col][row] = array[row][col];
    }
  }

  return transposed;
}

const Notifications = ({
  data,
  markAsRead,
  setNotificationPage,
  notificationPage,
  totalPages,
  settotalPages,
  setNotifications,
}) => {
  const [activeTabKey, setActiveTabKey] = useState("normal");
  const [peridicReviewsDueCount, setPeridicReviewsDueCount] = useState(0);
  const [periodicReviewsPastDueCount, setPeriodicReviewsPastDueCount] =
    useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    for (let values of data) {
      if (values.status !== "read") {
        markAsRead(values._id);
      }
    }
  }, [notificationPage, markAsRead, data]);
  const NotificationIcon = ({ type, properties = {} }) => {
    switch (type) {
      case "team_notification":
        return <AiOutlineTeam {...properties} style={{ color: "orange" }} />;
      case "changes_detected":
        return (
          <MdPublishedWithChanges {...properties} style={{ color: "blue" }} />
        );
      case "review":
        return <GoCodeReview {...properties} style={{ color: "green" }} />;
      case "admin_notification":
        return (
          <MdAdminPanelSettings {...properties} style={{ color: "red" }} />
        );
      case "screening":
        return <AiOutlineStop {...properties} style={{ color: "red" }} />;
      default:
        return (
          <AiFillNotification {...properties} style={{ color: "purple" }} />
        );
    }
  };

  useEffect(() => {
    User.dashboardDetails().then((res) => {
      setPeridicReviewsDueCount(res?.data?.peridicReviewsDueCount);
      setPeriodicReviewsPastDueCount(res?.data?.peridicReviewsPastDueCount);
    });
  }, []);

  const items = [
    {
      key: "normal",
      label: `Standard`,
    },
    {
      key: "only-reviews",
      label: `Periodic Reviews`,
    },
    {
      key: "changes_detected",
      label: `Risk Assessment Changes`,
    },
    {
      key: "screening",
      label: `Screening`,
    },
  ];

  return (
    <InfiniteScroll
      dataLength={data.length} //This is important field to render the next data
      next={() => setNotificationPage((prev) => prev + 1)}
      hasMore={notificationPage !== totalPages}
      loader={<Skeleton active />}
    >
      <div>
        <h3 className="my-10 text-2xl font-bold">Notifications</h3>
        <Tabs
          activeTabKey={activeTabKey}
          onChange={(e) => setActiveTabKey(e)}
          items={items}
        />

        {activeTabKey === "only-reviews" &&
          (peridicReviewsDueCount > 0 || periodicReviewsPastDueCount > 0) && (
            <>
              <div>
                <Space>
                  <strong>Due</strong>
                  <Tag color="orange">{peridicReviewsDueCount}</Tag>
                </Space>
              </div>
              <div>
                <Space>
                  <strong>Past Due</strong>
                  <Tag color="red">{periodicReviewsPastDueCount}</Tag>
                </Space>
              </div>
            </>
          )}

        {activeTabKey === "changes_detected" && (
          <Space>
            <Button
              type="primary"
              onClick={async () => {
                handleGeneratePDF({
                  docDef: {
                    content: [
                      {
                        text: `Notifications`,
                        style: "header",
                      },
                      {
                        table: {
                          headerRows: 1,
                          body: [
                            ["Notification", "Time"],
                            transpose(
                              data
                                ?.filter?.((notification) =>
                                  activeTabKey === "only-reviews"
                                    ? notification.type === "review"
                                    : activeTabKey === "changes_detected"
                                    ? notification.type === "changes_detected"
                                    : activeTabKey === "screening"
                                    ? notification.type === "screening"
                                    : !"only-reviews__review__changes_detected__screening"
                                        .split("__")
                                        .includes(notification.type)
                                )
                                .map((d) => [d.message, d.createdAt])
                            ),
                          ],
                        },
                        style: "table",
                      },
                    ],
                    styles: {
                      header: {
                        fontSize: 18,
                        bold: true,
                        margin: [0, 0, 0, 10],
                      },
                      table: {
                        fontSize: 8,
                      },
                    },
                  },
                });
              }}
            >
              Export PDF
            </Button>

            <Button
              type="primary"
              onClick={() =>
                exportToExcel(
                  data
                    ?.filter?.((notification) =>
                      activeTabKey === "only-reviews"
                        ? notification.type === "review"
                        : activeTabKey === "changes_detected"
                        ? notification.type === "changes_detected"
                        : activeTabKey === "screening"
                        ? notification.type === "screening"
                        : !"only-reviews__review__changes_detected__screening"
                            .split("__")
                            .includes(notification.type)
                    )
                    .map((d) => ({
                      type: d.type,
                      message: d.message,
                      read: d.read,
                      timestamp: d.createdAt,
                    }))
                )
              }
            >
              Export Excel
            </Button>
          </Space>
        )}

        {data
          ?.filter?.((notification) =>
            activeTabKey === "only-reviews"
              ? notification.type === "review"
              : activeTabKey === "changes_detected"
              ? notification.type === "changes_detected"
              : activeTabKey === "screening"
              ? notification.type === "screening"
              : !"only-reviews__review__changes_detected__screening"
                  .split("__")
                  .includes(notification.type)
          )
          ?.map((notification, i) => (
            <div
              key={i}
              className={`p-4 rounded-lg shadow-md flex items-center space-x-4 my-4 cursor-pointer ${
                notification.status !== "read" ? "bg-gray-100" : "bg-white "
              }`}
              onClick={() => {
                if (notification.status === "unread") {
                  markAsRead(notification._id);
                }
                if (notification.type === "review") {
                  navigate(
                    "/app/periodicreview/" +
                      notification?.riskAssessmentId +
                      "/" +
                      notification?.associatedUser
                  );
                }

                if (notification.type === "admin_subscription_reminder") {
                  navigate(
                    "/app/userdetails/" + notification?.riskAssessmentId
                  );
                }

                if (
                  notification.type === "assessment_approval_pending" &&
                  notification?.riskAssessmentId
                ) {
                  navigate(
                    "/app/editriskassessment/" + notification?.riskAssessmentId
                  );
                }

                if (
                  notification.type === "screening" &&
                  notification?.riskAssessmentId
                ) {
                  navigate(
                    "/app/clients/related-parties/" +
                      notification?.riskAssessmentId
                  );
                }
              }}
            >
              <div className="text-blue-500">
                <NotificationIcon
                  type={notification.type}
                  properties={{ className: "text-2xl" }}
                />
              </div>
              <div className="flex-grow">
                <p className="font-semibold">{notification.message}</p>
                <p className="text-gray-500 text-sm capitalize">
                  {moment(notification.createdAt).fromNow()}
                </p>
              </div>
            </div>
          ))}
      </div>
    </InfiniteScroll>
  );
};

export default Notifications;
