import { Button } from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../redux/auth/actions";

const getPersistentSetting = () => {
  const setting = localStorage.getItem("html-text-editor-setting");
  if (setting === "1") return true;
  else return false;
};
const HTMLTextEditor = ({ quillattributes, update, defaultValue }) => {
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();

  const [ownValue, setOwnValue] = useState(defaultValue ?? "");

  const handleSave = async () => {
    dispatch(setLoading(true));
    await update(ownValue);
    dispatch(setLoading(false));
  };
  return (
    <div {...quillattributes}>
      <ReactQuill
        value={ownValue}
        onChange={(e) => {
          setOwnValue(e);
        }}
        className="h-[150px] relative"
      />

      <div className="flex relative justify-end border mt-2 border-[#fafafa] bg-[#fafafa]">
        <Button
          loading={loading}
          type="primary"
          className=" btn-primary"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default HTMLTextEditor;
