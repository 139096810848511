import React, { useCallback, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CAPTCHA_DEACTIVATED, sitekey } from "../../config";
import { setLoading } from "../../redux/auth/actions";
import { selectLoading } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import Public from "../../service/Public";

const Contact = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [captchaFilled, setCaptchaFilled] = useState(CAPTCHA_DEACTIVATED);
  const loading = useSelector(selectLoading);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (loading) return;

      if (!captchaFilled) {
        return message.error("Please fill the CAPTCHA first.");
      }

      const name = e.target[0].value;
      const email = e.target[1].value;
      const businessName = e.target[2].value;
      const subject = e.target[3].value;
      const message = e.target[4].value;
      store.dispatch(setLoading(true));

      Public.contact({
        name,
        email,
        subject,
        businessName,
        message,
      })
        .then(() => {
          message.success("Your message has been received");
          navigate("/");
        })
        .catch(() => {
          message.error(
            "There has been an error while sending the contact request"
          );
        })
        .finally(() => {
          store.dispatch(setLoading(false));
        });
    },
    [captchaFilled, loading, navigate]
  );
  return (
    <>
      <div className="landing-wrapper">
        <div>
          <div className="navbar wf-section">
            <div className="navbar-space" />
            <div
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-out"
              data-easing2="ease-out"
              role="banner"
              className="navbar-inner w-nav"
            >
              <div className="container">
                <div className="navbar-wrapper">
                  <nav
                    role="navigation"
                    className="nav-menu-wrapper w-nav-menu"
                  >
                    <div className="nav-menu-flex">
                      <Link to="/" className="w-nav-brand">
                        <img
                          src="/images/Logo_horizontal.png"
                          loading="lazy"
                          height
                          alt=""
                          width={357}
                          srcSet="/images/Logo_horizontal-p-500.png 500w, images/Logo_horizontal-p-800.png 800w, images/Logo_horizontal-p-1080.png 1080w, images/Logo_horizontal-p-1600.png 1600w, images/Logo_horizontal-p-2000.png 2000w, images/Logo_horizontal-p-2600.png 2600w, images/Logo_horizontal.png 3056w"
                          sizes="(max-width: 767px) 100vw, (max-width: 991px) 203.8715362548828px, (max-width: 1279px) 21vw, 18vw"
                          className="logo"
                        />
                      </Link>
                      <ul role="list" className="nav-menu w-list-unstyled">
                        <li>
                          <Link to="/" className="nav-link">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/legal/privacy" className="nav-link">
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="/legal/terms" className="nav-link">
                            Terms of Service
                          </Link>
                        </li>
                        <li>
                          <Link to="/legal/copyright" className="nav-link">
                            Copyright
                          </Link>
                        </li>
                        <li>
                          <Link to="/legal/other" className="nav-link">
                            Other Legal Information
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact" className="nav-link selected">
                            Contact
                          </Link>
                        </li>
                      </ul>
                      <Link to="/auth/login" style={{ marginRight: 20 }}>
                        <img
                          src="/images/Frame-10.png"
                          loading="lazy"
                          width={45}
                          alt=""
                          className="image-2"
                        />
                      </Link>
                      <div className="navbar-button-wrapper">
                        <Link
                          to="/contact"
                          className="button cc-small w-button"
                        >
                          Say Hello
                        </Link>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div
              data-collapse="medium"
              data-animation="default"
              data-duration={400}
              data-easing="ease-out"
              data-easing2="ease-out"
              data-w-id="577246c2-be80-9a0d-4fe9-018c566815d9"
              role="banner"
              className="navbar-inner-mobile w-nav"
            >
              <div className="container">
                <div className="navbar-wrapper-mobile">
                  <Link
                    to="/"
                    aria-current="page"
                    className="navbar-brand-mobile w-nav-brand w--current"
                  >
                    <img
                      src="/images/Logo_horizontal.png"
                      loading="lazy"
                      height
                      width="119.5"
                      alt="flux"
                      className="logo"
                    />
                  </Link>
                  <div
                    data-w-id="577246c2-be80-9a0d-4fe9-018c566815de"
                    className="menu-button w-nav-button"
                    onClick={() => setMobileMenu((e) => !e)}
                  >
                    <div className="burger">
                      <div className="burger-line cc-top" />
                      <div className="burger-line cc-middle" />
                      <div className="burger-line cc-bottom" />
                    </div>
                  </div>
                </div>
                <nav
                  role="navigation"
                  className="nav-menu-wrapper-mobile w-nav-menu"
                  style={{ display: mobileMenu ? "unset" : "none" }}
                >
                  <div className="nav-menu-flex-mobile">
                    <ul role="list" className="nav-menu w-list-unstyled">
                      <li>
                        <Link
                          to="/"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/legal/terms"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Legals
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact"
                          className="nav-link-mobile"
                          onClick={() => setMobileMenu(false)}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
            <div className="navbar-shadow" />
            <div className="navbar-space" />
          </div>

          <div className="container">
            <section className="bg-white dark:bg-gray-900">
              <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                  Contact Us
                </h2>
                <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                  Need details about our solution?
                </p>
                <form
                  action="#"
                  className="space-y-8"
                  onSubmit={(e) => {
                    if (!loading) handleSubmit(e);
                  }}
                >
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="John Doe"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="name@example.com"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="business"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Business Name
                    </label>
                    <input
                      type="text"
                      id="business"
                      className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="Travel Exploration LLC"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="subject"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      id="subject"
                      className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                      placeholder="Demo Request"
                      required
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="message"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      rows={6}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Let us know how we may help you"
                      defaultValue={""}
                    />
                  </div>

                  {/* <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop your attachement
                        </p>
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                      />
                    </label>
                  </div> */}

                  <button type="submit" className="button cc-small w-button">
                    Send Message
                  </button>
                  {!CAPTCHA_DEACTIVATED && (
                    <div className="form-field my-10">
                      <ReCAPTCHA
                        sitekey={sitekey}
                        onExpired={() => setCaptchaFilled(false)}
                        onChange={() => setCaptchaFilled(true)}
                      />
                    </div>
                  )}
                </form>
              </div>
            </section>
          </div>

          <div className="footer background-dark">
            <div className="container">
              <div className="footer-cta">
                <div className="join">
                  <h2 className="display-3 text-white">
                    Start your AML journey today!
                  </h2>
                </div>
                <div className="div-block">
                  <Link to="/contact" className="button footerbutton w-button">
                    Say Hello
                  </Link>
                </div>
              </div>
              <div className="divider" />
              <div className="footer-grid">
                <div>
                  <img
                    src="/images/Logo_stacked_white.png"
                    loading="lazy"
                    height
                    alt=""
                    width="119.5"
                    srcSet="/images/Logo_stacked_white-p-500.png 500w, images/Logo_stacked_white-p-800.png 800w, images/Logo_stacked_white-p-1080.png 1080w, images/Logo_stacked_white-p-1600.png 1600w, images/Logo_stacked_white.png 1877w"
                    sizes="(max-width: 479px) 59vw, 119.49653625488281px"
                    className="logo margin-bottom-20"
                  />
                  <div className="margin-bottom-20">
                    © {new Date().getFullYear()} Synrgy Solutions.
                  </div>
                  <div className="social-links-wrapper">
                    <ul role="list" className="social-links w-list-unstyled">
                      <li className="social-link-item">
                        <a
                          href="https://www.linkedin.com/company/synrgyaml/"
                          target="_blank"
                          className="social-link w-inline-block"
                        >
                          <div className="unicon"></div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbcf-cd8efba7">
                  <div className="address margin-bottom-20">Get In Touch</div>
                  <a
                    href="mailto:info@synrgy.solutions"
                    className="link-hover link-white"
                  >
                    <strong>info@synrgy.solutions</strong>
                  </a>
                </div>
                <div id="w-node-_11e2dc3d-d1e1-458c-44c2-5749cd8efbd8-cd8efba7">
                  <ul role="list" className="unordered-list margin-bottom-none">
                    <li>
                      <Link to="/" className="link-hover link-white">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/legal/privacy"
                        className="link-hover link-white"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/legal/terms" className="link-hover link-white">
                        Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/legal/copyright"
                        className="link-hover link-white"
                      >
                        Copyright
                      </Link>
                    </li>
                    <li>
                      <Link to="/legal/other" className="link-hover link-white">
                        Other Legal Information
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" className="link-hover link-white">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link to="/auth/login" className="link-hover link-white">
                        Platform
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
